import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    DEACTIVATE_AUDIT_DATA_STARTING,
    DEACTIVATE_AUDIT_DATA_SUCCESS,
    DEACTIVATE_AUDIT_DATA_ERROR,
} from '../../../constants/actionTypes';

export default function deactivateAuditReducer(state = initialState.deactivateAudit, action) {
    switch (action.type) {
        case DEACTIVATE_AUDIT_DATA_STARTING: {
            const { auditId } = action;
            const newState = clone(state);

            if (!newState[auditId]) {
                newState[auditId] = clone(initialState.deactivateAudit.auditId);
            }

            const auditData = newState[auditId];
            auditData.saving = true;
            auditData.saved = false;
            auditData.error = null;

            return newState;
        }
        case DEACTIVATE_AUDIT_DATA_SUCCESS: {
            const { auditId } = action;
            const newState = clone(state);

            if (!newState[auditId]) {
                newState[auditId] = clone(initialState.deactivateAudit.auditId);
            }
            const auditData = newState[auditId];
            auditData.saving = false;
            auditData.saved = true;
            auditData.error = null;

            return newState;
        }
        case DEACTIVATE_AUDIT_DATA_ERROR: {
            const { auditId } = action;
            const newState = clone(state);

            if (!newState[auditId]) {
                newState[auditId] = clone(initialState.deactivateAudit.auditId);
            }
            const auditData = newState[auditId];
            auditData.saving = false;
            auditData.saved = false;
            auditData.error = action.err;

            return newState;
        }
        default: {
            return state;
        }
    }
}