import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Link, useLocation } from "react-router-dom";
import * as loginActions from "../../../actions/loginActions";
import { FaMapPin, FaUsersCog } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { TbFile3D } from "react-icons/tb";
import { CiImport } from "react-icons/ci";
import classNames from "classnames";

function AdminMenu({ currentUser }) {
  const location = useLocation();
  // TODO from role permissions
  const canManageUsers = true;

  return (
    <div className="px-2 py-2">
      <div>
        <p className="text-sm ml-2 mb-1">Administration</p>
      </div>

      {canManageUsers && (
        <div className="grid grid-cols-1 gap-0">
          <Link to={"/manage/users"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/manage/users"),
              "bg-slate-200" : (location.pathname === "/manage/users"),
            })}>
              <div>
                <FaUsersCog className="w-4 h-4 inline mr-2" />
            </div>
            <div className="mt-1 text-sm">
                  Manage Users
                </div>
              </div>
            </Link>

          <Link to={"/manage/places"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/manage/places"),
              "bg-slate-200" : (location.pathname === "/manage/places"),
            })}>
              <div>
                <FaMapPin className="w-4 h-4 inline mr-2" />
              </div>
            <div className="mt-1 text-sm">
                  Manage Places
                </div>
              </div>
            </Link>

          <Link to={"/manage/customers"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/manage/customers"),
              "bg-slate-200" : (location.pathname === "/manage/customers"),
            })}>
              <div>
                <HiBuildingOffice2 className="w-4 h-4 inline mr-2" />
              </div>
            <div className="mt-1 text-sm">
                  Manage Customers
                </div>
              </div>
            </Link>

          <Link to={"/towers/bulk_import"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/towers/bulk_import"),
              "bg-slate-200" : (location.pathname === "/towers/bulk_import"),
            })}>
              <div>
                <CiImport className="w-4 h-4 inline mr-2"/>
              </div>
              <div className="mt-1 text-sm">
                  Bulk Import
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}

AdminMenu.defaultProps = {
  actions: {},
  currentUser: {},
};

AdminMenu.propTypes = {
  actions: PropTypes.object,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, loginActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
