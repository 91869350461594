import initialState from "../../../store/initialState";
import {
    UPDATE_POLE_CABLE_LADDER_ERROR,
    UPDATE_POLE_CABLE_LADDER_STARTING,
    UPDATE_POLE_CABLE_LADDER_SUCCESS
} from "../../../constants/actionTypes";
import cloneDeep from "lodash/cloneDeep";

export default function updatePoleCableLadders(state = initialState.updatePoleCableLadder, action) {
    switch (action.type) {
        case UPDATE_POLE_CABLE_LADDER_STARTING:{
            const {poleCableId} = action;
            const newState = cloneDeep(state);

            if(!newState[poleCableId]){
                newState[poleCableId] = cloneDeep(initialState.updatePoleCableLadder.poleCableId);
            }

            const updateData = newState[poleCableId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }

        case UPDATE_POLE_CABLE_LADDER_SUCCESS:{
            const {poleCableId} = action;

            const newState = cloneDeep(state);

            if(!newState[poleCableId]){
                newState[poleCableId]=cloneDeep(initialState.updatePoleCableLadder.poleCableId)
            }
            const updateData = newState[poleCableId];

            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;

            return newState;
        }

        case UPDATE_POLE_CABLE_LADDER_ERROR:{
            const {poleCableId} = action;

            const newState = cloneDeep(state);

            if(!newState[poleCableId]){
                newState[poleCableId] = cloneDeep(initialState.updatePoleCableLadder.poleCableId);
            }
            const updateData = newState[poleCableId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.error;
            return newState;
        }
        default:{
            return state;
        }
    }
}