import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    ACTIVATE_RECOMMENDATION_STARTING,
    ACTIVATE_RECOMMENDATION_SUCCESS,
    ACTIVATE_RECOMMENDATION_ERROR,
} from '../../../constants/actionTypes';

export default function activateRecommendationReducer(state = initialState.activateRecommendation, action) {
    switch (action.type) {
        case ACTIVATE_RECOMMENDATION_STARTING: {
            const { recommendationId } = action;
            const newState = clone(state);

            if (!newState[recommendationId]) {
                newState[recommendationId] = clone(initialState.activateRecommendation.recommendationId);
            }

            const recommendationData = newState[recommendationId];
            recommendationData.saving = true;
            recommendationData.saved = false;
            recommendationData.error = null;

            return newState;
        }

        case ACTIVATE_RECOMMENDATION_SUCCESS: {
            const { recommendationId } = action;
            const newState = clone(state);

            if (!newState[recommendationId]) {
                newState[recommendationId] = clone(initialState.activateRecommendation.recommendationId);
            }

            const recommendationData = newState[recommendationId];
            recommendationData.saving = false;
            recommendationData.saved = true;
            recommendationData.error = null;
            return newState;
        }

        case ACTIVATE_RECOMMENDATION_ERROR: {
            const { recommendationId } = action;
            const newState = clone(state);

            if (!newState[recommendationId]) {
                newState[recommendationId] = clone(initialState.activateRecommendation.recommendationId);
            }

            const recommendationData = newState[recommendationId];
            recommendationData.saving = false;
            recommendationData.saved = false;
            recommendationData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
