import initialState from "../../../store/initialState";
import {
    ADD_SERVICE_REQUEST_SUCCESS,
    DEACTIVATE_SERVICE_REQUEST_SUCCESS, GET_ALL_SERVICE_REQUESTS_STARTING,
    GET_ALL_SERVICE_REQUESTS_SUCCESS,
    GET_INACTIVE_SERVICE_REQUEST_SUCCESS,
    GET_SERVICE_REQUESTS_ERROR,
    GET_SERVICE_REQUESTS_STARTING,
    GET_SERVICE_REQUESTS_SUCCESS,
    UPDATE_SERVICE_REQUEST_SUCCESS,
} from "../../../constants/actionTypes";
import {cloneDeep} from "lodash";

const sortByOrder = (newState) => {
};

export default function serviceRequestsReducer(
    state = initialState.serviceRequests,
    action
) {
    switch (action.type) {
        case GET_SERVICE_REQUESTS_STARTING:
        case GET_ALL_SERVICE_REQUESTS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_SERVICE_REQUESTS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            action.data.serviceRequests.forEach((serviceRequest) => {
                const serviceRequestId = serviceRequest.id;
                ids.push(serviceRequestId);
                newState.map[serviceRequestId] = serviceRequest;
            });
            newState.ids = ids;
            newState.loading = false;
            newState.loaded = true;
            sortByOrder(newState);
            return newState;
        }

        case GET_ALL_SERVICE_REQUESTS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            action.data.serviceRequests.forEach((serviceData) => {
                const serviceId = serviceData.id;
                ids.push(serviceId);
                newState.map[serviceId] = serviceData;
            });
            newState.ids = ids;
            newState.loading = false;
            newState.loaded = true;
            sortByOrder(newState);

            newState.pages = action.data.pages;
            newState.total = action.data.total;
            newState.limit = action.data.limit;
            newState.page = action.data.page;
            return newState;
        }

        case GET_INACTIVE_SERVICE_REQUEST_SUCCESS: {
            const newState = cloneDeep(state);
            const inactiveIds = [];

            action.data.serviceRequests.forEach((serviceData) => {
                const serviceId = serviceData.id;
                inactiveIds.push(serviceId);
                newState.map[serviceId] = serviceData;
            });

            newState.inactiveIds = inactiveIds;

            sortByOrder(newState);
            return newState;
        }

        case ADD_SERVICE_REQUEST_SUCCESS: {
            const newState = cloneDeep(state);
            const {serviceRequest} = action.data;
            const serviceId = serviceRequest.id;

            newState.map[serviceId] = serviceRequest;
            newState.ids.push(serviceId);

            newState.ids.sort((ten1Id, ten2Id) => {
                const ten1 = newState.map[ten1Id];
                const ten2 = newState.map[ten2Id];
                return ten1.id - ten2.id;
            });
            return newState;
        }

        case UPDATE_SERVICE_REQUEST_SUCCESS: {
            const newState = cloneDeep(state);
            const {serviceRequest} = action.data;
            const serviceId = serviceRequest.id;
            newState.map[serviceId] = serviceRequest;

            sortByOrder(newState);
            return newState;
        }


        case DEACTIVATE_SERVICE_REQUEST_SUCCESS: {
            const {serviceId} = action;
            const newState = cloneDeep(state);
            newState.inactiveIds.push(serviceId);

            newState.ids = newState.ids.filter((reqId) => {
                return serviceId !== reqId;
            });

            sortByOrder(newState)
            return newState;
        }

        case GET_SERVICE_REQUESTS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            return newState;
        }
        default: {
            return state;
        }
    }
}
