import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    UPDATE_FLANGE_JOINT_CONNECTION_STARTING,
    UPDATE_FLANGE_JOINT_CONNECTION_SUCCESS,
    UPDATE_FLANGE_JOINT_CONNECTION_ERROR,
} from '../../../constants/actionTypes';

export default function updateFlangeJointReducer(state = initialState.updateFlangeJoint, action) {
    switch (action.type) {
        case UPDATE_FLANGE_JOINT_CONNECTION_STARTING: {

            const { flangeJointId } = action;
            const newState = clone(state);

            if (!newState[flangeJointId]) {
                newState[flangeJointId] = clone(initialState.updateFlangeJoint.flangeJointId);
            }

            const updateData = newState[flangeJointId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }

        case UPDATE_FLANGE_JOINT_CONNECTION_SUCCESS: {
            const { flangeJointId } = action;

            const newState = clone(state);

            if (!newState[flangeJointId]) {
                newState[flangeJointId] = clone(initialState.updateFlangeJoint.flangeJointId);
            }
            const updateData = newState[flangeJointId];

            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;

            return newState;
        }

        case UPDATE_FLANGE_JOINT_CONNECTION_ERROR: {
            const { flangeJointId } = action;

            const newState = clone(state);

            if (!newState[flangeJointId]) {
                newState[flangeJointId] = clone(initialState.updateFlangeJoint.flangeJointId);
            }
            const updateData = newState[flangeJointId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
