import { cloneDeep } from "lodash";
import {
  ADD_UTILIZATION_SUCCESS,
  DEACTIVATE_UTILIZATION_SUCCESS,
  GET_UTILIZATIONS_ERROR,
  GET_UTILIZATIONS_STARTING,
  GET_UTILIZATIONS_SUCCESS,
  UPDATE_UTILIZATION_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function utilizationsReducer(state = initialState.utilizationDetails,action){

    
    const sortByOrder = (newState) => {};
    switch(action.type) {
        case GET_UTILIZATIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }
        case GET_UTILIZATIONS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            action.data.utilizations.forEach((utilization)=>{
                const utilizationId = utilization.id;
                ids.push(utilizationId);
                newState.map[utilizationId] = utilization;

                const {poleNum, strtNum, horizontalNum, poleNumStrg, strtNumStrg, horizontalNumStrg} = utilization;
                const maxExisting = Math.max(poleNum, strtNum, horizontalNum);
                const maxStr = Math.max(poleNumStrg, strtNumStrg, horizontalNumStrg);

                let remark, strRemark;
                if (maxExisting <= 1) {
                    remark = 'SAFE';
                } else {
                    remark = 'UNSAFE';
                };

                if (maxStr <= 1) {
                    strRemark = 'SAFE';
                } else {
                    strRemark = "UNSAFE";
                }

                utilization.remark = remark;
                utilization.strRemark = strRemark;
            });
            newState.ids = ids;
            newState.loading = false;
            newState.loaded = true;
            sortByOrder(newState);
            return newState;
        }

        case ADD_UTILIZATION_SUCCESS:{
            const newState = cloneDeep(state);
            const {utilizations} = action.data;
            const utilizationId = utilizations.id;

            newState.map[utilizationId] = utilizations;
            newState.ids.push(utilizationId);

            newState.ids.sort((util1Id,util2Id)=>{
                const util1 = newState.map[util1Id];
                const util2 = newState.map[util2Id];

                return util1.id - util2.id;
            });

            return newState;
        }

        case UPDATE_UTILIZATION_SUCCESS:{
            const newState = cloneDeep(state);
            const {utilizations} = action.data;
            const utilizationId = utilizations.id;
            newState.map[utilizationId] = utilizations;

            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_UTILIZATION_SUCCESS:{

            const newState = cloneDeep(state);
            const {utilizationId} = action
            newState.ids =  newState.ids.filter((utilId)=>{
                return utilizationId !== utilId;
            });
            return newState;
        }

        case GET_UTILIZATIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            return newState;
        }
        default:{
            return state;
        }
    }
}
