import { cloneDeep } from "lodash";
import {
    ADD_RECOMMENDATION_ERROR,
    ADD_RECOMMENDATION_STARTING,
    ADD_RECOMMENDATION_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";


export default function addRecommendationReducer(state = initialState.addRecommendation, action) {
    switch (action.type) {
        case ADD_RECOMMENDATION_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_RECOMMENDATION_SUCCESS: {
            const { data } = action;
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;
            newState.newRecommendation = data.recommendation;

            return newState;
        }

        case ADD_RECOMMENDATION_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.error;
            return newState;
        }

        default: {
            return state;
        }
    }
}