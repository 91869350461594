import {
    LOGIN_CHECK_ERROR,
    LOGIN_CHECK_STARTING,
    LOGIN_CHECK_SUCCESS,

    LOGOUT_STARTING,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,

    FORCE_USER_LOGIN,
    SAVE_PREV_ROUTE,

    RESET_PASSWORD_STARTING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,

    AUTO_LOGIN_STARTING,
    AUTO_LOGIN_SUCCESS,
    AUTO_LOGIN_ERROR,

    FORGOT_PASSWORD_STARTING,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
} from '../constants/actionTypes';
import axios from '../axios';
import {
    CHECK_USER_LOGIN,
    LOGOUT_USER,
    USER_AUTO_LOGIN,
    SEND_HEARTBEAT,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
} from '../constants/apiUrls';

export function savePrevRoute(prevRoute) {
    return {type: SAVE_PREV_ROUTE, prevRoute};
}

export function forceUserLogin() {
    return {type: FORCE_USER_LOGIN};
}

export function loginCheckStarting() {
    return {type: LOGIN_CHECK_STARTING};
}

export function loginCheckSuccess(data) {
    return {type: LOGIN_CHECK_SUCCESS, data};
}

export function loginCheckError(err, data) {
    return {type: LOGIN_CHECK_ERROR, err, data};
}

export function loginCheck(username, password, force) {
    return (dispatch) => {
        dispatch(loginCheckStarting());

        axios.post(CHECK_USER_LOGIN, {
            username,
            password,
            force,
        }).then((response) => {
            if (response.status === 200 && response.data.success && response.data.user) {
                dispatch(loginCheckSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to verify login credentials';
            dispatch(loginCheckError(err, response.data));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(loginCheckError(error, err.response.data));
        });
    };
}

export function autoLoginStarting() {
    return {type: AUTO_LOGIN_STARTING};
}

export function autoLoginSuccess(data) {
    return {type: AUTO_LOGIN_SUCCESS, data};
}

export function autoLoginError(err) {
    return {type: AUTO_LOGIN_ERROR, err};
}

export function autoLogin(userId) {
    return (dispatch) => {
        dispatch(autoLoginStarting());

        const url = USER_AUTO_LOGIN.replace('{userId}', userId);
        axios.get(url).then((response) => {
            if (response.status === 200 && response.data.success && response.data.user) {
                dispatch(autoLoginSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to auto login user';
            dispatch(autoLoginError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(autoLoginError(error));
        });
    };
}

export function logoutUserStarting() {
    return {type: LOGOUT_STARTING};
}

export function logoutUserSuccess(data) {
    return {type: LOGOUT_SUCCESS, data};
}

export function logoutUserError(err) {
    return {type: LOGOUT_ERROR, err};
}

export function logoutUser(autoLogout=0) {
    return (dispatch) => {
        dispatch(logoutUserStarting());

        axios.post(LOGOUT_USER, {
            autoLogout,
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(logoutUserSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to logout user';
            dispatch(logoutUserError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(logoutUserError(error));
        });
    };
}


export function resetPasswordStarting() {
    return { type: RESET_PASSWORD_STARTING };
}

export function resetPasswordSuccess(data) {
    return { type: RESET_PASSWORD_SUCCESS, data };
}

export function resetPasswordError(err) {
    return { type: RESET_PASSWORD_ERROR, err };
}

export function resetPassword(postData) {
    return (dispatch) => {
        dispatch(resetPasswordStarting());

        axios.post(RESET_PASSWORD, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(resetPasswordSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed the Reset Password';
            dispatch(resetPasswordError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.messsage;
            dispatch(resetPasswordError(error));
        });
    };
}

export function forgotPasswordStarting() {
    return { type: FORGOT_PASSWORD_STARTING };
}

export function forgotPasswordSuccess(data) {
    return { type: FORGOT_PASSWORD_SUCCESS, data };
}

export function forgotPasswordError(err) {
    return { type: FORGOT_PASSWORD_ERROR, err };
}

export function forgotPassword(postData) {
    return (dispatch) => {
        dispatch(forgotPasswordStarting());

        axios.post(FORGOT_PASSWORD, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(forgotPasswordSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get password';
            dispatch(forgotPasswordError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(forgotPasswordError(error));
        });
    };
}

export function sendHeartbeat() {
    return (dispatch) => {
        axios.get(SEND_HEARTBEAT).then((response) => {
            if (response.status === 200 && response.data.success) {
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to send heartbeat';
            // console.log(err);
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            // console.log(err);
        });
    };
}
