import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DEACTIVATE_COMPANY_STARTING,
  DEACTIVATE_COMPANY_SUCCESS,
  DEACTIVATE_COMPANY_ERROR,
} from '../../constants/actionTypes';

export default function deactivateCompanyReducer(state = initialState.deactivateCompany, action) {
  switch (action.type) {
    case DEACTIVATE_COMPANY_STARTING: {
      const { companyId } = action;
      const newState = clone(state);

      if (!newState[companyId]) {
        newState[companyId] = clone(initialState.deactivateCompany.companyId);
      }

      const companyData = newState[companyId];
      companyData.saving = true;
      companyData.saved = false;
      companyData.error = null;

      return newState;
    }

    case DEACTIVATE_COMPANY_SUCCESS: {
      const { companyId } = action;
      const newState = clone(state);

      if (!newState[companyId]) {
        newState[companyId] = clone(initialState.deactivateCompany.companyId);
      }

      const companyData = newState[companyId];
      companyData.saving = false;
      companyData.saved = true;
      companyData.error = null;
      return newState;
    }

    case DEACTIVATE_COMPANY_ERROR: {
      const { companyId } = action;
      const newState = clone(state);

      if (!newState[companyId]) {
        newState[companyId] = clone(initialState.deactivateCompany.companyId);
      }

      const companyData = newState[companyId];
      companyData.saving = false;
      companyData.saved = false;
      companyData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
