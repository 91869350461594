import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    ADD_FLANGE_JOINT_CONNECTION_STARTING,
    ADD_FLANGE_JOINT_CONNECTION_SUCCESS,
    ADD_FLANGE_JOINT_CONNECTION_ERROR,
} from '../../../constants/actionTypes';

export default function addFlangeJointReducer(state = initialState.addFlangeJoint, action) {
    switch (action.type) {
        case ADD_FLANGE_JOINT_CONNECTION_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_FLANGE_JOINT_CONNECTION_SUCCESS: {
            const { data } = action;

            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newFlangeJoint = data.buttJoint;
            return newState;
        }

        case ADD_FLANGE_JOINT_CONNECTION_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
