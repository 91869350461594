import { cloneDeep } from "lodash";
import { ADD_UTILIZATION_ERROR, ADD_UTILIZATION_STARTING, ADD_UTILIZATION_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function addutilizationReducer(state = initialState.addUtilizationDetail,action){
    switch(action.type) {
        case ADD_UTILIZATION_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            newState.error = null;
            return newState;
        }
        case ADD_UTILIZATION_SUCCESS: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.newUtilizationDetail = action.data;
            newState.error = null;
            return newState;
        }

        case ADD_UTILIZATION_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.error ? action.error : "Failed to add Comparison Utilization data";

            return newState;
        }

        default:{
            return state;
        }
    }
}