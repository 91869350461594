import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    ADD_IMAGE_LABEL_STARTING,
    ADD_IMAGE_LABEL_SUCCESS,
    ADD_IMAGE_LABEL_ERROR,
} from '../../../constants/actionTypes';

export default function addImageLabelReducer(state = initialState.addImageLabel, action) {
    switch (action.type) {
        case ADD_IMAGE_LABEL_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_IMAGE_LABEL_SUCCESS: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            return newState;
        }

        case ADD_IMAGE_LABEL_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}