import { cloneDeep } from "lodash";
import initialState from "../../../store/initialState";
import {
    ADD_WORK_COMPLETION_REPORT_STARTING,
    ADD_WORK_COMPLETION_REPORT_SUCCESS,
    ADD_WORK_COMPLETION_REPORT_ERROR,
} from '../../../constants/actionTypes';

export default function addWCCReportReducer(state = initialState.addWCCReport, action) {
    switch (action.type) {
        case ADD_WORK_COMPLETION_REPORT_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_WORK_COMPLETION_REPORT_SUCCESS: {
            const { data } = action;

            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newMobileTower = data.mobileTower;
            return newState;
        }

        case ADD_WORK_COMPLETION_REPORT_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}