import initialState from "../../../store/initialState";
import {
    GET_POLE_DETAILS_STARTING,
    GET_POLE_DETAILS_SUCCESS,
    GET_POLE_DETAILS_ERROR,
    ADD_POLE_DETAILS_SUCCESS,
    UPDATE_POLE_DETAILS_SUCCESS,
    DEACTIVATE_POLE_DETAILS_SUCCESS,
} from "../../../constants/actionTypes";
import { cloneDeep } from "lodash";

export default function polesDetailsReducer(state = initialState.poleDetails, action) {


    const sortByOrder = (newState) => {};
    switch (action.type) {
        case GET_POLE_DETAILS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_POLE_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            action.data.poles.forEach((poleDetail) => {
                const poleId = poleDetail.id;
                ids.push(poleId);
                newState.map[poleId] = poleDetail
            });
            newState.ids = ids;
            newState.loading = false
            newState.loaded = true
            sortByOrder(newState);
            return newState;
        }

        case ADD_POLE_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            const {pole} = action.data
            const poleId = pole.id;

            newState.map[poleId] = pole;
            newState.ids.push(poleId);

            newState.ids.sort((ten1Id, ten2Id) => {
                const ten1 = newState.map[ten1Id];
                const ten2 = newState.map[ten2Id];
                return ten1.id - ten2.id;
            });
            return newState;
        }

        case UPDATE_POLE_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            const {pole} = action.data;
            const poleId = pole.id;
            newState.map[poleId] = pole;

            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_POLE_DETAILS_SUCCESS: {
            const {poleId} = action;
            const newState = cloneDeep(state);

            newState.ids = newState.ids.filter((polId)=>{
                return poleId!==polId;
            });
            
            return newState;
        }

        case GET_POLE_DETAILS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            return newState;
          }
          default: {
            return state;
          }
    }

}