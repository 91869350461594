import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  ACTIVATE_TENANT_STARTING,
  ACTIVATE_TENANT_SUCCESS,
  ACTIVATE_TENANT_ERROR,
} from '../../../constants/actionTypes';

export default function activateTenantReducer(state = initialState.activateTenant, action) {
  switch (action.type) {
    case ACTIVATE_TENANT_STARTING: {
      const { tenantId } = action;
      const newState = clone(state);

      if (!newState[tenantId]) {
        newState[tenantId] = clone(initialState.activateTenant.tenantId);
      }

      const tenantData = newState[tenantId];
      tenantData.saving = true;
      tenantData.saved = false;
      tenantData.error = null;

      return newState;
    }

    case ACTIVATE_TENANT_SUCCESS: {
      const { tenantId } = action;
      const newState = clone(state);

      if (!newState[tenantId]) {
        newState[tenantId] = clone(initialState.activateTenant.tenantId);
      }

      const tenantData = newState[tenantId];
      tenantData.saving = false;
      tenantData.saved = true;
      tenantData.error = null;
      return newState;
    }

    case ACTIVATE_TENANT_ERROR: {
      const { tenantId } = action;
      const newState = clone(state);

      if (!newState[tenantId]) {
        newState[tenantId] = clone(initialState.activateTenant.tenantId);
      }

      const tenantData = newState[tenantId];
      tenantData.saving = false;
      tenantData.saved = false;
      tenantData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
