import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ACTIVATE_MOBILE_TOWER_DESIGN_STARTING,
    ACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS,
    ACTIVATE_MOBILE_TOWER_DESIGN_ERROR,
} from '../../constants/actionTypes';

export default function activateTowerDesignReducer(state = initialState.activateTowerDesigns, action) {
    switch (action.type) {
        case ACTIVATE_MOBILE_TOWER_DESIGN_STARTING: {
            const { designId } = action;
            const newState = clone(state);

            if (!newState[designId]) {
                newState[designId] = clone(initialState.activateTowerDesigns.designId);
            }

            const designData = newState[designId];
            designData.saving = true;
            designData.saved = false;
            designData.error = null;

            return newState;
        }

        case ACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS: {
            const { designId } = action;
            const newState = clone(state);

            if (!newState[designId]) {
                newState[designId] = clone(initialState.activateTowerDesigns.designId);
            }

            const designData = newState[designId];
            designData.saving = false;
            designData.saved = true;
            designData.error = null;
            return newState;
        }

        case ACTIVATE_MOBILE_TOWER_DESIGN_ERROR: {
            const { designId } = action;
            const newState = clone(state);

            if (!newState[designId]) {
                newState[designId] = clone(initialState.activateTowerDesigns.designId);
            }

            const designData = newState[designId];
            designData.saving = false;
            designData.saved = false;
            designData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
