import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    ADD_TOWER_CHECKLIST_STARTING,
    ADD_TOWER_CHECKLIST_SUCCESS,
    ADD_TOWER_CHECKLIST_ERROR,
} from '../../../constants/actionTypes';

export default function addTowerChecklistReducer(state = initialState.addTowerChecklist, action) {
    switch (action.type) {
        case ADD_TOWER_CHECKLIST_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_TOWER_CHECKLIST_SUCCESS: {
            const { data } = action;

            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newMobileTower = data.mobileTower;
            return newState;
        }

        case ADD_TOWER_CHECKLIST_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
