import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  UPDATE_RECOMMENDATION_STARTING,
  UPDATE_RECOMMENDATION_SUCCESS,
  UPDATE_RECOMMENDATION_ERROR,
} from '../../../constants/actionTypes';

export default function updateRecommendationReducer(state = initialState.updateRecommendation, action) {
  switch (action.type) {
    case UPDATE_RECOMMENDATION_STARTING: {
      const { recommendationId } = action;
      const newState = clone(state);

      if (!newState[recommendationId]) {
        newState[recommendationId] = clone(initialState.updateRecommendation.recommendationId);
      }

      const updateData = newState[recommendationId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_RECOMMENDATION_SUCCESS: {
      const { recommendationId } = action;
      const newState = clone(state);

      if (!newState[recommendationId]) {
        newState[recommendationId] = clone(initialState.updateRecommendation.recommendationId);
      }

      const updateData = newState[recommendationId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_RECOMMENDATION_ERROR: {
      const { recommendationId } = action;
      const newState = clone(state);

      if (!newState[recommendationId]) {
        newState[recommendationId] = clone(initialState.updateRecommendation.recommendationId);
      }

      const updateData = newState[recommendationId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
