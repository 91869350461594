import clone from "lodash/cloneDeep";
import initialState from "../../../store/initialState";
import {
  DEACTIVATE_MEMBER_ERROR,
  DEACTIVATE_MEMBER_STARTING,
  DEACTIVATE_MEMBER_SUCCESS,
} from "../../../constants/actionTypes";

export default function deactivateMemberReducer(
  state = initialState.deactivateMember,
  action
) {
  switch (action.type) {
    case DEACTIVATE_MEMBER_STARTING: {
      const { memberId } = action;
      const newState = clone(state);

      if (!newState[memberId]) {
        newState[memberId] = clone(initialState.deactivateMember.memberId);
      }

      const memberData = newState[memberId];
      memberData.saving = true;
      memberData.saved = false;
      memberData.error = null;
      
      console.log("newState1", newState)
      return newState;
    }

    case DEACTIVATE_MEMBER_SUCCESS: {
      console.log("check1")
      const { memberId } = action;
      console.log("memberId",memberId)
      const newState = clone(state);
      console.log("newState2", newState)


      if (!newState[memberId]) {
        newState[memberId] = clone(initialState.deactivateMember.memberId);
      }

      const memberData = newState[memberId];
      console.log("memberData",memberData)
      memberData.saving = false;
      memberData.saved = true;
      memberData.error = null;
      return newState;
    }

    case DEACTIVATE_MEMBER_ERROR: {
      console.log("error")
      const { memberId } = action;
      const newState = clone(state);

      if (!newState[memberId]) {
        newState[memberId] = clone(initialState.deactivateMember.memberId);
      }

      const memberData = newState[memberId];
      memberData.saving = false;
      memberData.saved = false;
      memberData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
