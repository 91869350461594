import { cloneDeep } from "lodash";
import initialState from "../../../store/initialState";
import {
    GET_MATERIAL_SPECIFICATIONS_STARTING,
    GET_MATERIAL_SPECIFICATIONS_SUCCESS,
    GET_MATERIAL_SPECIFICATIONS_ERROR,
    UPDATE_MATERIAL_SPECIFICATION_SUCCESS,
} from '../../../constants/actionTypes';


export default function materialSpecificationsReducer(state = initialState.materialSpecifications, action) {
    switch (action.type) {
        case GET_MATERIAL_SPECIFICATIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MATERIAL_SPECIFICATIONS_SUCCESS: {
            const newState = cloneDeep(state);

            const ids = [];
            const map = {};
            action.data.materialSpecifications.forEach((materialSpecification) => {
                // console.log('ac', action.data)
                const materialSpecificationId = materialSpecification.id;
                ids.push(materialSpecificationId);
                map[materialSpecificationId] = materialSpecification;
            });

            newState.ids = ids;
            newState.map = map;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case UPDATE_MATERIAL_SPECIFICATION_SUCCESS: {
            const newState = cloneDeep(state);
            const { materialSpecification } = action.data;
            const materialSpecificationId = materialSpecification.id;
            newState.map[materialSpecificationId] = materialSpecification;
            return newState;
        }

        case GET_MATERIAL_SPECIFICATIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive material specifications.';

            return newState;
        }

        default: {
            return state;
        }
    }
}