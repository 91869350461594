import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_ROLES_ERROR,
  GET_ROLES_STARTING,
  GET_ROLES_SUCCESS,
  ADD_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS,
} from '../../constants/actionTypes';

export default function rolesReducer(state = initialState.roles, action) {
  switch (action.type) {
    case GET_ROLES_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_ROLES_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.roles.forEach((role) => {
        const roleId = role.id;
        ids.push(roleId);
        map[roleId] = role;
      });

      newState.ids = ids;
      newState.map = map;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_ROLES_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve roles.';
      return newState;
    }

    case ADD_ROLE_SUCCESS: {
      const newState = cloneDeep(state);
      const { role } = action.data;
      const roleId = role.id;

      // Push new role data
      newState.map[roleId] = role;
      newState.ids.push(roleId);

      // Sort by display order
      newState.ids.sort((loc1Id, loc2Id) => {
        const loc1 = newState.map[loc1Id];
        const loc2 = newState.map[loc2Id];
        return loc1.id - loc2.id;
      });

      return newState;
    }

    case UPDATE_ROLE_SUCCESS: {
      const newState = cloneDeep(state);
      const { role } = action.data;
      const roleId = role.id;
      newState.map[roleId] = role;
      return newState;
    }

    default: {
      return state;
    }
  }
}
