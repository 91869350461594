import { cloneDeep } from "lodash";
import {
  DEACTIVATE_CALCULATED_FORCE_ERROR,
  DEACTIVATE_CALCULATED_FORCE_STARTING,
  DEACTIVATE_CALCULATED_FORCE_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function deactivateCalculatedForceReducer(
  state = initialState.deactivateCalculatedForces,
  action
) {
  switch (action.type) {
    case DEACTIVATE_CALCULATED_FORCE_STARTING: {
        console.log("action", action)
      const { calId } = action;
      const newState = cloneDeep(state);

      if (!newState[calId]) {
        newState[calId] = cloneDeep(initialState.deactivateCalculatedForces.calId);
      }

      const calculatedData = newState[calId];
      calculatedData.saving = true;
      calculatedData.saved = false;
      calculatedData.error = null;

      return newState;
    }

    case DEACTIVATE_CALCULATED_FORCE_SUCCESS: {
      const { calId } = action;
      const newState = cloneDeep(state);

      if (!newState[calId]) {
        newState[calId] = cloneDeep(initialState.deactivateCalculatedForces.calId);
      }

      const calculatedData = newState[calId];
      calculatedData.saving = false;
      calculatedData.saved = true;
      calculatedData.error = null;
      return newState;
    }

    case DEACTIVATE_CALCULATED_FORCE_ERROR: {
      const { calId } = action;
      const newState = cloneDeep(state);

      if (!newState[calId]) {
        newState[calId] = cloneDeep(initialState.deactivateCalculatedForces.calId);
      }

      const calculatedData = newState[calId];
      calculatedData.saving = false;
      calculatedData.saved = false;
      calculatedData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
