import { cloneDeep } from 'lodash';
import {
    GET_AUDIT_DATA_STARTING,
    GET_AUDIT_DATA_SUCCESS,
    GET_AUDIT_DATA_ERROR,
    ADD_AUDIT_DATA_SUCCESS,
    UPDATE_AUDIT_DATA_SUCCESS,
    DEACTIVATE_AUDIT_DATA_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function auditsDataReducer(state = initialState.auditsData, action) {
    switch (action.type) {
        case GET_AUDIT_DATA_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_AUDIT_DATA_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.auditsData.forEach((audit) => {
                const auditId = audit.id;
                ids.push(auditId);
                map[auditId] = audit;
            });

            newState.ids = ids;
            newState.map = map;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_AUDIT_DATA_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve audit history.';
            return newState;
        }

        case ADD_AUDIT_DATA_SUCCESS: {
            const newState = cloneDeep(state);
            const {audit} = action.data;
            // console.log('au', action.data)
            const auditId = audit.id;

            newState.map[auditId] = audit;
            newState.ids.push(auditId);

            newState.ids.sort((au1Id, au2Id) => {
                const au1 = newState.map[au1Id];
                const au2 = newState.map[au2Id];
                return au1.id - au2.id;
            });

            return newState;
        }

        case UPDATE_AUDIT_DATA_SUCCESS: {
            const newState = cloneDeep(state);
            const { audit } = action.data;
            const auditId = audit.id;
            newState.map[auditId] = audit;
            return newState;
        }

        case DEACTIVATE_AUDIT_DATA_SUCCESS: {
            const newState = cloneDeep(state);
            const { auditId } = action;
            newState.inactiveIds.push(auditId);

            newState.ids = newState.ids.filter((pId) => {
                return auditId !== pId;
            });

            if (newState.map[auditId]) {
                newState.map[auditId].isActive = 0;
            }

            // sortByOrder(newState);
            return newState;
        }

        default: {
            return state;
        }
    }
}