import initialState from "../../../store/initialState";
import {
    ADD_POLE_CABLE_LADDER_SUCCESS,
    DEACTIVATE_POLE_CABLE_LADDER_SUCCESS,
    GET_POLE_CABLE_LADDERS_ERROR,
    GET_POLE_CABLE_LADDERS_STARTING,
    GET_POLE_CABLE_LADDERS_SUCCESS,
    UPDATE_POLE_CABLE_LADDER_SUCCESS
} from "../../../constants/actionTypes";
import cloneDeep from "lodash/cloneDeep";



export default function poleCableLadderReducer (state = initialState.poleCableLadderDetails, action) {
    const sortByOrder = (newState) => {};
    switch (action.type) {
        case GET_POLE_CABLE_LADDERS_STARTING:{
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_POLE_CABLE_LADDERS_SUCCESS:{
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};
            
            action.data.poleCableLadders.forEach((poleCableLadder) => {
                // console.log('poleCableLadder', poleCableLadder.pole);

                const poleId = poleCableLadder.id;
                ids.push(poleId);
                map[poleId] = poleCableLadder;

               const poleHeight = poleCableLadder && poleCableLadder.pole ? poleCableLadder.pole && poleCableLadder.pole.height : '-';
                
                const {ladderNumber, ladderWidth, ladderLength, ladderForceCoeff, gsmNumber, gsmDiameter, mwNumber, mwDiameter, rruNumber, rruDiameter, feederForceCoeff} = poleCableLadder;
                let exposedArea = ((ladderNumber * ladderWidth * ladderLength * ladderForceCoeff) + ((gsmDiameter * gsmNumber) + (mwNumber * mwDiameter) + (rruNumber * rruDiameter)) * feederForceCoeff) / poleHeight;
                poleCableLadder.exposedArea = exposedArea;
            });

            newState.ids = ids;
            newState.map = map;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case ADD_POLE_CABLE_LADDER_SUCCESS : {
            const newState = cloneDeep(state);
            const {poleCableLadder} = action.data;
            const poleCableLadderId = poleCableLadder.id;

            newState.map[poleCableLadderId] = poleCableLadder
            newState.ids.push(poleCableLadderId);

            newState.ids.sort((cabl1Id,cabl2Id)=>{
                const cabl1 = newState.map[cabl1Id];
                const cabl2 = newState.map[cabl2Id];

                return cabl1.id - cabl2.id;
            });
            return newState;
        }

        case UPDATE_POLE_CABLE_LADDER_SUCCESS : {
            const newState = cloneDeep(state);
            const {poleCableLadder} = action.data;
            const poleCableLadderId = poleCableLadder.id;

            newState.map[poleCableLadderId] = poleCableLadder;
            
            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_POLE_CABLE_LADDER_SUCCESS : {
            const newState = cloneDeep(state);
            const {poleCableId} = action;

            newState.ids = newState.ids.filter((cablId)=>{
                return poleCableId !== cablId;
            });
            return newState;
        }

        case GET_POLE_CABLE_LADDERS_ERROR:{
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve modules.';
            return newState;
        }
        default:{
            return state
        }
    }
}