import React, {useState, useEffect} from 'react';
import PropTypes, {oneOfType} from 'prop-types';

function TextInput({
                       className, inputClassName, inputStyle,
                       label, labelTopRight, helperText, hasError, type,
                       value, onChange,
                       placeholder, ...moreProps
                   }) {
    const classes = `form-control w-full ${className}`;
    const inputClasses = `input input-bordered w-full ${inputClassName}`;

    let labelTopRight1 = null, helperLabel = null;
    if (labelTopRight) {
        labelTopRight1 = (
            <span className="label-text-alt">{labelTopRight}</span>
        );
    }

    if (helperText) {
        helperLabel = (
            <label className="label">
                <span className="label-text-alt">{helperText}</span>
            </label>
        );
    }

    let inputField = null;
    if (type === 'textarea') {
        inputField = (
            <textarea
                placeholder={placeholder}
                className={inputClasses}
                style={inputStyle}
                value={value}
                onChange={onChange}
                {...moreProps}
            />
        );
    } else {
        inputField = (
            <input
                type={type}
                placeholder={placeholder}
                className={inputClasses}
                style={inputStyle}
                value={value}
                onChange={onChange}
                {...moreProps}
            />
        );
    }

    return (
        <div className={classes}>
            {label && (
                <label className="label">
                    <span className="label-text">{label}</span>
                    {labelTopRight1}
                </label>
            )}

            {inputField}

            {helperLabel}
        </div>
    );
}

TextInput.defaultProps = {
    className: '',
    helperText: '',
    hasError: false,
    inputClassName: '',
    inputStyle: {},
    label: 'Label',
    labelTopRight: '',
    placeholder: '',
    type: 'text',
    value: '',
};

TextInput.propTypes = {
    className: PropTypes.string,
    helperText: PropTypes.string,
    hasError: PropTypes.bool,
    inputClassName: PropTypes.string,
    inputStyle: PropTypes.object,
    label: PropTypes.string,
    labelTopRight: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
};

export default TextInput;
