import clone from 'lodash/clone';
import initialState from '../store/initialState';
import {
    RESET_PASSWORD_STARTING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
} from '../constants/actionTypes';

export default function resetPasswordReducer(state = initialState.resetPassword, action) {
    switch (action.type) {
        case RESET_PASSWORD_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case RESET_PASSWORD_SUCCESS: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;

            newState.err = '';
            return newState;
        }

        case RESET_PASSWORD_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;

            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}