import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ACTIVATE_PLACE_STARTING,
    ACTIVATE_PLACE_SUCCESS,
    ACTIVATE_PLACE_ERROR,
} from '../../constants/actionTypes';

export default function activatePlaceReducer(state = initialState.activatePlace, action) {
    switch (action.type) {
        case ACTIVATE_PLACE_STARTING: {
            const { placeId } = action;
            const newState = clone(state);

            if (!newState[placeId]) {
                newState[placeId] = clone(initialState.activatePlace.placeId);
            }

            const placeData = newState[placeId];
            placeData.saving = true;
            placeData.saved = false;
            placeData.error = null;

            return newState;
        }

        case ACTIVATE_PLACE_SUCCESS: {
            const { placeId } = action;
            const newState = clone(state);

            if (!newState[placeId]) {
                newState[placeId] = clone(initialState.activatePlace.placeId);
            }

            const placeData = newState[placeId];
            placeData.saving = false;
            placeData.saved = true;
            placeData.error = null;
            return newState;
        }

        case ACTIVATE_PLACE_ERROR: {
            const { placeId } = action;
            const newState = clone(state);

            if (!newState[placeId]) {
                newState[placeId] = clone(initialState.activatePlace.placeId);
            }

            const placeData = newState[placeId];
            placeData.saving = false;
            placeData.saved = false;
            placeData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
