import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {goToTowerPage, goToPlacePage, openSearchResult} from '../../../utils/navigation';
import AutoSuggest from 'react-autosuggest';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as searchActions from '../../../actions/searchActions';
import {toHumanFormat} from "../../../utils/numbers";
import {
    FaAlignLeft,
    FaBook,
    FaBuilding,
    FaCalculator, FaCamera,
    FaExternalLinkAlt,
    FaFileAlt, FaFileWord, FaFilm,
    FaMapMarker, FaQuestionCircle,
    FaSearch,
    FaUserAlt, FaVideo
} from "react-icons/fa";
import {FaFileLines, FaFileWaveform, FaTowerCell} from "react-icons/fa6";

function PACSSearchHeader({ actions, searchSuggestions }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestTimer, setSuggestTimer] = useState(0);
    const navigate = useNavigate();

    const onSearchClicked = () => {
        openSearchResult(navigate, searchQuery);
    };

    const onSuggestionSelected = (event, { suggestionValue }) => {
        setSearchQuery(suggestionValue);
        const result = searchSuggestions[searchQuery].resultsData[suggestionValue];

        if (result.resultType === 'mobileTower') {
            goToTowerPage(navigate, {
                id: result.mobileTowerId,
                siteIdStr: suggestionValue,
            });
        } else if (result.resultType === 'place') {
            goToPlacePage(navigate, {
                id: result.placeId,
                name: suggestionValue,
            });
            // TODO
            console.log('Suggestion selected', suggestionValue, result);
            // openSearchResult(navigate, suggestionValue);
        } else {
            console.log('Suggestion selected', suggestionValue, result);
            // openSearchResult(navigate, suggestionValue);
        }
    };

    const inputProps = {
        value: searchQuery,
        placeholder: 'Search for a Tower',
        onChange: (e) => {
            setSearchQuery(e.target.value);
        },
    };

    const onSuggestionsFetchRequested = (data) => {
        if (suggestTimer) {
            clearTimeout(suggestTimer);
        }

        const t = setTimeout(() => {
            actions.getSearchSuggestions(data.value);
        }, 400);

        setSuggestTimer(t);
    };

    const onSuggestionsClearRequested = () => { };
    const getSuggestionValue = (suggestion) => suggestion;
    const suggestions = searchSuggestions[searchQuery] ? searchSuggestions[searchQuery].suggestions : [];
    // console.log(searchSuggestions);

    const renderSuggestion = (suggestion) => {
        const result = searchSuggestions[searchQuery].resultsData[suggestion];
        const searchCountText = toHumanFormat(result.searchCount);

        let suggestionIcon = null;
        switch (result.resultType) {
            case 'alias': {
                suggestionIcon = (<FaSearch className="w-3 h-3" />);
                break;
            }

            case 'mobileTower': {
                suggestionIcon = (<FaTowerCell className="w-3 h-3" />);
                break;
            }

            case 'expression': {
                suggestionIcon = (<FaCalculator className="w-3 h-3" />);
                break;
            }

            case 'meta_search': {
                suggestionIcon = (<FaSearch className="w-3 h-3" />);
                break;
            }

            case 'redirect': {
                suggestionIcon = (<FaExternalLinkAlt className="w-3 h-3" />)
                break;
            }

            case 'person': {
                suggestionIcon = (<FaUserAlt className="w-3 h-3" />);
                break;
            }

            case 'files': {
                suggestionIcon = (<FaFileAlt className="w-3 h-3" />);
                break;
            }

            case 'company': {
                suggestionIcon = (<FaBuilding className="w-3 h-3" />);
                break;
            }

            case 'place': {
                suggestionIcon = (<FaMapMarker className="w-3 h-3" />);
                break;
            }

            case 'book': {
                suggestionIcon = (<FaBook className="w-3 h-3" />);
                break;
            }

            case 'movie': {
                suggestionIcon = (<FaFilm className="w-3 h-3" />);
                break;
            }

            case 'dictionary_prefix': {
                suggestionIcon = (<FaFileWord className="w-3 h-3" />);
                break;
            }

            case 'dictionary_word': {
                suggestionIcon = (<FaFileWord className="w-3 h-3" />);
                break;
            }

            case 'video': {
                suggestionIcon = (<FaVideo className="w-3 h-3" />);
                break;
            }
            case 'fixed_text': {
                suggestionIcon = (<FaAlignLeft className="w-3 h-3" />);
                break;
            }

            case 'photos_timeline': {
                suggestionIcon = (<FaCamera className="w-3 h-3" />);
                break;
            }

            case 'blog_article': {
                suggestionIcon = (<FaFileLines className="w-3 h-3" />);
                break;
            }

            default: {
                suggestionIcon = (<FaQuestionCircle className="w-3 h-3" />);
                break;
            }
        }
        
        return (
            <div className="suggestion-row flex flex-row items-center">
                <div className="basis-auto mr-2">
                    {suggestionIcon}
                </div>
                
                <div className="grow">{suggestion}</div>
                {(result.searchCount > 0) && (
                    <div className="basis-auto ml-auto ml-2">
                        {searchCountText}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="p-2 text-black hidden md:flex md:flex-row md:w-72 xl:w-full">
            {/* <TextInput
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className=""
                label=""             
                helperText=""
                placeholder="Enter search query"
            /> */}

            <AutoSuggest
                id="searchBox"
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
            />
        </div>
    );
}

PACSSearchHeader.defaultProps = {
    actions: {},
    searchSuggestions: {},
};

PACSSearchHeader.propTypes = {
    actions: PropTypes.object,
    searchSuggestions: PropTypes.object,
};

const mapStateToProps = state => ({
    searchSuggestions: state.searchSuggestions,

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, searchActions), dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(PACSSearchHeader);

