import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    UPDATE_COMPANY_STARTING,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_ERROR,
} from '../../constants/actionTypes';

export default function updateCompanyReducer(state = initialState.updateCompany, action) {
    switch (action.type) {
        case UPDATE_COMPANY_STARTING: {
            const { companyId } = action;
            const newState = clone(state);
            if (!newState[companyId]) {
                newState[companyId] = clone(initialState.updateCompany.companyId);

            }
            const updateData = newState[companyId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }
        case UPDATE_COMPANY_SUCCESS: {
            const { companyId } = action;
            const newState = clone(state);
            if (!newState[companyId]) {
                newState[companyId] = clone(initialState.updateCompany.companyId);

            }

            const updateData = newState[companyId];
            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;
            return newState;
        }

        case UPDATE_COMPANY_ERROR: {
            const { companyId } = action;
            const newState = clone(state);

            if (!newState[companyId]) {
                newState[companyId] = clone(initialState.updateCompany.companyId);

            }

            const updateData = newState[companyId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.err;
            return newState;
        }
        default: {
            return state;
        }
    }
}