import {cloneDeep, sortBy} from "lodash";
import {
    GET_CALCULATED_FORCE_STARTING,
    GET_CALCULATED_FORCE_SUCCESS,
    GET_CALCULATED_FORCE_ERROR,
    DEACTIVATE_CALCULATED_FORCE_SUCCESS,
    UPDATE_CALCULATED_FORCE_SUCCESS,
    ADD_CALCULATED_FORCE_SUCCESS,
} from "../../../constants/actionTypes";

import initialState from "../../../store/initialState";

const sortByOrder = (newState) => {
}
export default function calculatedForceReducer(state = initialState.calculatedForces, action) {
    switch (action.type) {
        case GET_CALCULATED_FORCE_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            newState.error = null;
            return newState;
        }

        case GET_CALCULATED_FORCE_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.forcesData.forEach((forceData) => {
                const forceDataId = forceData.id;
                ids.push(forceDataId);
                map[forceDataId] = forceData;
            });

            newState.ids = ids;
            newState.map = map;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_CALCULATED_FORCE_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve calculated Force data.';

            return newState;
        }

        case ADD_CALCULATED_FORCE_SUCCESS: {
            const {calculatedForce: calData} = action.data;
            const newState = cloneDeep(state);
            const newId = calData.id;

            newState.map[newId] = calData;
            newState.ids.push(newId);

            sortByOrder(newState);

            return newState;
        }

        case UPDATE_CALCULATED_FORCE_SUCCESS : {
            const newState = cloneDeep(state);
            const {calculatedForce: calData} = action.data;
            const calDataId = calData.id;
            newState.map[calDataId] = calData

            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_CALCULATED_FORCE_SUCCESS: {
            const {calId} = action;
            const newState = cloneDeep(state);
            newState.inactiveIds.push(calId);

            newState.ids = newState.ids.filter((clId) => {
                return calId !== clId;
            })

            if (newState.map[calId]) {
                newState.map[calId].isActive = 0;
            }

            sortByOrder(newState);
            return newState;
        }

        default: {
            return state;
        }
    }
}
