import { cloneDeep } from "lodash";
import { DEACTIVATE_PEDESTAL_DESIGN_ERROR, 
    DEACTIVATE_PEDESTAL_DESIGN_STARTING, 
    DEACTIVATE_PEDESTAL_DESIGN_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function deactivatePedestalDesignReducer(state = initialState.deactivatePedestalDetail,action){
    switch(action.type){
        case DEACTIVATE_PEDESTAL_DESIGN_STARTING:{
            const {pedestalId} = action;
            const newState = cloneDeep(state)

            if(!newState[pedestalId]){
                newState[pedestalId] = cloneDeep(initialState.deactivatePedestalDetail.pedestalId)
            }

            const deactivateData = newState[pedestalId]
            deactivateData.saving = true;
            deactivateData.saved = false;
            deactivateData.error = null;

            return newState;
        }

        case DEACTIVATE_PEDESTAL_DESIGN_SUCCESS:{
            const {pedestalId} = action;
            const newState = cloneDeep(state);

            if(!newState[pedestalId]){
                newState[pedestalId] = cloneDeep(initialState.deactivatePedestalDetail.pedestalId)
            }

            const deactivateData = newState[pedestalId];
            deactivateData.saving = false;
            deactivateData.saved = true;
            deactivateData.error = null;

            return newState;
        }

        case DEACTIVATE_PEDESTAL_DESIGN_ERROR:{
            const {pedestalId} = action;
            const newState = cloneDeep(state);

            if(!newState[pedestalId]){
                newState[pedestalId] = cloneDeep(initialState.deactivatePedestalDetail.pedestalId)
            }

            const deactivateData = newState[pedestalId];
            deactivateData.saving = false;
            deactivateData.saved = false;
            deactivateData.error = action.error ? action.error : "Failed to deactivate pedestal data"

            return newState;
        }

        default:{
            return state;
        }
    }
}