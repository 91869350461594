import { cloneDeep } from 'lodash';
import {
    GET_MEMBERS_STARTING,
    GET_MEMBERS_SUCCESS,
    GET_MEMBERS_ERROR,
    ADD_MEMBER_SUCCESS,
    UPDATE_MEMBER_SUCCESS,
    DEACTIVATE_MEMBER_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

const sortByOrder = (newState) => { }   

export default function membersReducer(state = initialState.members, action) {
    switch (action.type) {
        case GET_MEMBERS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MEMBERS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.members.forEach((member) => {
                const memberId = member.id;
                ids.push(memberId);
                map[memberId] = member;
            });

            newState.ids = ids;
            newState.map = map;


            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_MEMBERS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve members.';
            return newState;
        }

        case ADD_MEMBER_SUCCESS: {
            const newState = cloneDeep(state);
            const { member } = action.data;
            const memberId = member.id;


            newState.map[memberId] = member;
            newState.ids.push(memberId);

            newState.ids.sort((ten1Id, ten2Id) => {
                const ten1 = newState.map[ten1Id];
                const ten2 = newState.map[ten2Id];
                return ten1.id - ten2.id;
            });

            return newState;
        }

        case UPDATE_MEMBER_SUCCESS: {
            const newState = cloneDeep(state);
            const { member } = action.data;
            const memberId = member.id;
            newState.map[memberId] = member;
            return newState;
        }

        case DEACTIVATE_MEMBER_SUCCESS: {
            const {memberId} = action;
            const newState = cloneDeep(state);
            newState.inactiveIds.push(memberId);

            newState.ids = newState.ids.filter((mbid) => {
                return memberId !== mbid;
            });

            if (newState.map[memberId]) {
                newState.map[memberId].isActive = 0;
            }
            sortByOrder(newState)
            return newState;
          }


        default: {
            return state;
        }
    }
}