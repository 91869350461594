import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoadingIcon from "./Utilities/LoadingIcon";

const EmptyLoader = ({ color, className, message, paddingTop }) => (
  <div className={className}>
    <div
      className={classNames({
        'pt-0': (paddingTop === 0),
        'pt-12': (paddingTop === 12),
      })}
    >
      <div className={`flex flex-col justify-center align-center text-center text-${color}`}>
        <div className="">
          <LoadingIcon color={color} />
        </div>

        <p className="mt-5 animate-pulse">{message}</p>
      </div>
    </div>
  </div>
);

EmptyLoader.defaultProps = {
  className: '',
  color: 'neutral',
  message: 'Please wait',
  paddingTop: 12,
};

EmptyLoader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  message: PropTypes.string,
  paddingTop: PropTypes.number,
};

export default EmptyLoader;
