export function isDesignerUser(userRole) {
    if (!userRole) {
        return false;
    }

    const { shortCode } = userRole;
    return (
        shortCode === 'senior_designer' ||
        shortCode === 'junior_designer' ||
        shortCode === 'designer'
    );
}

export function isAdminUser(userRole) {
    if (!userRole) {
        return false;
    }

    const { shortCode } = userRole;
    return (
        shortCode === 'admin' ||
        shortCode === 'sadmin' ||
        shortCode === 'vendor'
    );
}

export function isCustomerUser(userRole) {
    if (!userRole) {
        return false;
    }

    const { shortCode } = userRole;
    return (
        shortCode === 'customer'
    );
}

export function isCircleLeadUser(userRole) {
    if (!userRole) {
        return false;
    }

    const { shortCode } = userRole;
    return (
        shortCode === 'circle_lead'
    );
}

export function isMisUser(userRole) {
    if (!userRole) {
        return false;
    }

    const { shortCode } = userRole;
    return (
        shortCode === 'mis'
    );
}

export function isFinanceUser(userRole) {
    if (!userRole) {
        return false;
    }

    const { shortCode } = userRole;
    return (
        shortCode === 'finance'
    );
}

export function isDetailerUser(userRole) {
    if (!userRole) {
        return false;
    }

    const { shortCode } = userRole;
    return (
        shortCode === 'detailer'
    );
}
