import axios from "axios";
import {
  ADD_SERVICE_REQUEST_ERROR,
  ADD_SERVICE_REQUEST_STARTING,
  ADD_SERVICE_REQUEST_SUCCESS,
  DEACTIVATE_SERVICE_REQUEST_ERROR,
  DEACTIVATE_SERVICE_REQUEST_STARTING,
  DEACTIVATE_SERVICE_REQUEST_SUCCESS,
  GET_ALL_SERVICE_REQUESTS_ERROR,
  GET_ALL_SERVICE_REQUESTS_STARTING,
  GET_ALL_SERVICE_REQUESTS_SUCCESS,
  GET_INACTIVE_SERVICE_REQUEST_ERROR,
  GET_INACTIVE_SERVICE_REQUEST_STARTING,
  GET_INACTIVE_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUESTS_ERROR,
  GET_SERVICE_REQUESTS_STARTING,
  GET_SERVICE_REQUESTS_SUCCESS,
  GET_SERVICE_REQUESTS_SUMMARY_ERROR,
  GET_SERVICE_REQUESTS_SUMMARY_STARTING,
  GET_SERVICE_REQUESTS_SUMMARY_SUCCESS,
  UPDATE_SERVICE_REQUEST_ERROR,
  UPDATE_SERVICE_REQUEST_STARTING, 
  UPDATE_SERVICE_REQUEST_SUCCESS,
} from "../../constants/actionTypes";
import {
  ADD_SERVICE_REQUEST,
  DEACTIVATE_SERVICE_REQUEST,
  GET_ALL_SERVICE_REQUESTS,
  GET_INACTIVE_SERVICE_REQUEST,
  GET_SERVICE_REQUESTS,
  GET_SERVICE_REQUESTS_SUMMARY,
  UPDATE_SERVICE_REQUEST,
} from "../../constants/apiUrls";

export function getAllServiceRequestsStarting() {
  return { type: GET_ALL_SERVICE_REQUESTS_STARTING }
}

export function getAllServiceRequestsSuccess(data) {
  return { type: GET_ALL_SERVICE_REQUESTS_SUCCESS, data }
}

export function getAllServiceRequestsError(err) {
  return { type: GET_ALL_SERVICE_REQUESTS_ERROR, err }
}

export function getAllServiceRequests(queryParams) {
  return (dispatch) => {
      dispatch(getAllServiceRequestsStarting());
      
      axios.get(GET_ALL_SERVICE_REQUESTS, {
          params: queryParams,
      }).then((response) => {
          if (response.status === 200 && response.data.success) {
              dispatch(getAllServiceRequestsSuccess(response.data));
              return;
          }
          const err = (response && response.data && response.data.error) ? response.data.error : 'Failed to Get Service Request';
          dispatch(getAllServiceRequestsError(err));
      }).catch((err) => {
          const error = (err.response && err.response.data && err.response.data.error) ?
              err.response.data.error : err.message;
          dispatch(getAllServiceRequestsError(error));
      });
  };
}


export function getServiceRequestsSummaryStarting() {
  return { type: GET_SERVICE_REQUESTS_SUMMARY_STARTING }
}

export function getServiceRequestsSummarySuccess(data) {
  return { type: GET_SERVICE_REQUESTS_SUMMARY_SUCCESS, data }
}

export function getServiceRequestsSummaryError(err) {
  return { type: GET_SERVICE_REQUESTS_SUMMARY_ERROR, err }
}

export function getServiceRequestsSummary() {
  return (dispatch) => {
      dispatch(getServiceRequestsSummaryStarting());
      
      axios.get(GET_SERVICE_REQUESTS_SUMMARY, {
          params: {
              page: 1,
              limit: 200,
          },
      }).then((response) => {
        console.log("response",response)
          if (response.status === 200 && response.data.success) {
              dispatch(getServiceRequestsSummarySuccess(response.data));
              return;
          }
          const err = (response && response.data && response.data.error) ? response.data.error : 'Failed to Get Summary Data';
          dispatch(getServiceRequestsSummaryError(err));
      }).catch((err) => {
          const error = (err.response && err.response.data && err.response.data.error) ?
              err.response.data.error : err.message;
          dispatch(getServiceRequestsSummaryError(error));
      });
  };
}


export function getServiceRequestsStarting(mobileTowerId) {
  return { type: GET_SERVICE_REQUESTS_STARTING, mobileTowerId };
}

export function getServiceRequestsSuccess(mobileTowerId, data) {
  return { type: GET_SERVICE_REQUESTS_SUCCESS, mobileTowerId, data };
}

export function getServiceRequestsError(mobileTowerId, err) {
  return { type: GET_SERVICE_REQUESTS_ERROR, mobileTowerId, err };
}

export function getServiceRequests(mobileTowerId) {
  return (dispatch) => {
    dispatch(getServiceRequestsStarting(mobileTowerId));

    const url = GET_SERVICE_REQUESTS.replace("{mobileTowerId}", mobileTowerId);
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(getServiceRequestsSuccess(mobileTowerId, response.data));
          return;
        }

        const err =
          response && response.data && response.data.error
            ? response.data.error
            : "Failed to get Service Data";
        dispatch(getServiceRequestsError(mobileTowerId, err));
      })
      .catch((err) => {
        const error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err.message;
        dispatch(getServiceRequestsError(mobileTowerId, error));
      });
  };
}

export function addServiceRequestStarting(mobileTowerId) {
  return { type: ADD_SERVICE_REQUEST_STARTING, mobileTowerId };
}

export function addServiceRequestSuccess(mobileTowerId, data) {
  return { type: ADD_SERVICE_REQUEST_SUCCESS, mobileTowerId, data };
}

export function addServiceRequestError(mobileTowerId, err) {
  return { type: ADD_SERVICE_REQUEST_ERROR, mobileTowerId, err };
}

export function addServiceRequest(mobileTowerId, serviceData) {
  return (dispatch) => {
    dispatch(addServiceRequestStarting(mobileTowerId));

    const url = ADD_SERVICE_REQUEST.replace("{mobileTowerId}", mobileTowerId);
    axios
      .post(url, serviceData)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(addServiceRequestSuccess(mobileTowerId, response.data));
          return;
        }

        const err =
          response && response.data && response.data.error
            ? response.data.error
            : "Failed to add Service Data data.";
        dispatch(addServiceRequestError(mobileTowerId, err));
      })
      .catch((err) => {
        const error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err.message;
        dispatch(addServiceRequestError(mobileTowerId, error));
      });
  };
}

export function updateServiceRequestStarting(serviceId) {
  return { type: UPDATE_SERVICE_REQUEST_STARTING, serviceId };
}

export function updateServiceRequestSuccess(serviceId, data) {
  return { type: UPDATE_SERVICE_REQUEST_SUCCESS, serviceId, data };
}

export function updateServiceRequestError(serviceId, err) {
  return { type: UPDATE_SERVICE_REQUEST_ERROR, serviceId, err };
}

export function updateServiceRequest(mobileTowerId, serviceId, updateData) {  
  return (dispatch) => {
    dispatch(updateServiceRequestStarting(mobileTowerId, serviceId));

    const url = UPDATE_SERVICE_REQUEST.replace("{serviceId}", serviceId);
    axios
      .put(url, updateData)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(updateServiceRequestSuccess(serviceId, response.data));
          return;
        }

        const err =
          response && response.data && response.data.error
            ? response.data.error
            : "Failed to update service data.";
        dispatch(updateServiceRequestError(serviceId, err));
      })
      .catch((err) => {
        const error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err.message;
        dispatch(updateServiceRequestError(serviceId, error));
      });
  };
}

export function deactivateServiceRequestStarting(serviceId) {
  return { type: DEACTIVATE_SERVICE_REQUEST_STARTING, serviceId };
}

export function deactivateServiceRequestSuccess(serviceId, data) {
  return { type: DEACTIVATE_SERVICE_REQUEST_SUCCESS, serviceId, data };
}

export function deactivateServiceRequestError(serviceId, err) {
  return { type: DEACTIVATE_SERVICE_REQUEST_ERROR, serviceId, err };
}

export function deactivateServiceRequest(mobileTowerId, serviceId, updateData) {
  return (dispatch) => {
    dispatch(deactivateServiceRequestStarting(serviceId));

    const url = DEACTIVATE_SERVICE_REQUEST.replace("{mobileTowerId}", mobileTowerId).replace("{serviceId}",serviceId);
    axios
      .delete(url, { data: updateData })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(deactivateServiceRequestSuccess(serviceId, response.data));
          return;
        }

        const err =
          response && response.data && response.data.error
            ? response.data.error
            : "Failed to deactivate audit data";
        dispatch(deactivateServiceRequestError(serviceId, err));
      })
      .catch((err) => {
        const error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err.message;
        dispatch(deactivateServiceRequestError(serviceId, error));
      });
  };
}

export function getInactiveServiceRequestsStarting() {
  return { type: GET_INACTIVE_SERVICE_REQUEST_STARTING };
}

export function getInactiveServiceRequestsSuccess(data) {
  return { type: GET_INACTIVE_SERVICE_REQUEST_SUCCESS, data };
}

export function getInactiveServiceRequestsError(err) {
  return { type: GET_INACTIVE_SERVICE_REQUEST_ERROR, err };
}

export function getInactiveServiceRequests() {
  return (dispatch) => {
      dispatch(getInactiveServiceRequestsStarting());
      axios.get(GET_INACTIVE_SERVICE_REQUEST).then((response) => {
          if (response.status === 200 && response.data.success) {
              dispatch(getInactiveServiceRequestsSuccess(response.data));
              return;
          }
          const err = (response && response.data && response.data.error) ?
              response.data.error : 'Failed to get Service Request';
          dispatch(getInactiveServiceRequestsError(err));
      }).catch((err) => {
          const error = (err.response && err.response.data && err.response.data.error) ?
              err.response.data.error : err.message;
          dispatch(getInactiveServiceRequestsError(error));
      });
  };
}
