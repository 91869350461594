import { cloneDeep } from "lodash";
import {
  DEACTIVATE_POLE_DETAILS_ERROR,
  DEACTIVATE_POLE_DETAILS_STARTING,
  DEACTIVATE_POLE_DETAILS_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function deactivatePolesDetailsReducer(
  state = initialState.deactivatePoleDetail,
  action
) {
  switch (action.type) {
    case DEACTIVATE_POLE_DETAILS_STARTING: {
      const { poleId } = action;
      const newState = cloneDeep(state);

      if (!newState[poleId]) {
        newState[poleId] = cloneDeep(initialState.deactivatePoleDetail.poleId);
      }

      const updateData = newState[poleId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case DEACTIVATE_POLE_DETAILS_SUCCESS: {
      const { poleId } = action;
      const newState = cloneDeep(state);

      if (!newState[poleId]) {
        newState[poleId] = cloneDeep(initialState.deactivatePoleDetail.poleId);
      }

      const updateData = newState[poleId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case DEACTIVATE_POLE_DETAILS_ERROR: {
      const { poleId } = action;
      const newState = cloneDeep(state);

      if (!newState[poleId]) {
        newState[poleId] = cloneDeep(initialState.deactivatePoleDetail.poleId);
      }

      const updateData = newState[poleId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }
    default: {
      return state;
    }
  }
}
