import { cloneDeep } from "lodash";
import initialState from "../../../store/initialState";
import {
    ADD_TOWER_RETROFITTING_STARTING,
    ADD_TOWER_RETROFITTING_SUCCESS,
    ADD_TOWER_RETROFITTING_ERROR,
} from '../../../constants/actionTypes';

export default function addTowerRetrofittingReducer(state = initialState.addTowerRetrofittingReport, action) {
    switch (action.type) {
        case ADD_TOWER_RETROFITTING_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_TOWER_RETROFITTING_SUCCESS: {
            const { data } = action;
            
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newMobileTower = data.mobileTower;
            return newState;
        }

        case ADD_TOWER_RETROFITTING_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}