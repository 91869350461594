import {isNil} from "lodash";

export function roundNumber(numberToRound, numberOfDecimalPlaces, toFixed=true, ifNull='-', ifZero='') {
    if (numberToRound === 0) {
        if (!isNil(ifZero)) {
            return ifZero;
        } else {
            return (0.0).toFixed(numberOfDecimalPlaces);
        }
    }

    if (!numberToRound) {
        if (!isNil(ifNull)) {
            return ifNull;
        } else {
            return '';
        }
    }

    const scrubbedNumber = numberToRound.toString().replace('$', '').replace(',', '');
    const roundedNumber = Math.round(scrubbedNumber * Math.pow(10, numberOfDecimalPlaces)) / Math.pow(10, numberOfDecimalPlaces);

    if (toFixed) {
        return roundedNumber.toFixed(numberOfDecimalPlaces);
    } else {
        return roundedNumber;
    }
}

// adds array of values passed.
export function addArray(values) {
    const total = values.reduce((previousValue, currentValue) => {
        return previousValue + parseInt(convertToPennies(currentValue), 10); // do math in pennies to assure accuracy.
    }, 0);

    return total / 100; // convert back into dollars
}

export function convertToPennies(value) {
    if (value === 0) {
        return 0;
    }

    let dollarValue = parseFloat(value);
    dollarValue = roundNumber(dollarValue, 2); // round to 2 decimal places.
    const dollarValueContainsDecimal = (dollarValue.toString().indexOf('.') !== -1);
    return (dollarValueContainsDecimal) ? parseInt(dollarValue.toString().replace('.', ''), 10) : parseInt(dollarValue, 10) * 100;
}

export function toScientific(value, precision=4) {
    return value.toExponential(precision);
}
