import { cloneDeep } from "lodash";
import { ADD_POLE_DETAILS_ERROR, ADD_POLE_DETAILS_STARTING, ADD_POLE_DETAILS_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function addPolesDetailsReducer(state = initialState.addPoleDetail,action){
    switch(action.type){
        case ADD_POLE_DETAILS_STARTING : {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }
        case ADD_POLE_DETAILS_SUCCESS : {
            const {data} = action;
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;
            newState.newPoleData = data.pole;
            return newState;
        }
        case ADD_POLE_DETAILS_ERROR : {
            const newState = cloneDeep(state)
            newState.saving = false;
            newState.saved = false;
            newState.error = action.error
            return newState;
        }
        default : {
            return state;
        }
    }
}