import initialState from '../../store/initialState';
import {
    GET_REGION_TOWERS_ERROR,
    GET_REGION_TOWERS_STARTING,
    GET_REGION_TOWERS_SUCCESS,
} from '../../constants/actionTypes';
import clone from "lodash/cloneDeep";

export default function regionTowersReducer(state = initialState.regionTowers, action) {
    switch (action.type) {
        case GET_REGION_TOWERS_STARTING: {
            const { placeId } = action;

            const newState = clone(state);

            if (!newState[placeId]) {
                newState[placeId] = clone(initialState.regionTowers.placeId);
            }

            const updateData = newState[placeId];
            updateData.loading = true;
            updateData.loaded = false;
            updateData.allLoaded = false;
            updateData.error = null;

            return newState;
        }

        case GET_REGION_TOWERS_SUCCESS: {
            const { placeId } = action;

            const newState = clone(state);

            if (!newState[placeId]) {
                newState[placeId] = clone(initialState.regionTowers.placeId);
            }

            const updateData = newState[placeId];
            updateData.loading = false;
            updateData.loaded = true;
            updateData.allLoaded = action.data.allLoaded;
            updateData.error = null;

            return newState;
        }

        case GET_REGION_TOWERS_ERROR: {
            const { placeId } = action;
            const newState = clone(state);

            if (!newState[placeId]) {
                newState[placeId] = clone(initialState.regionTowers.placeId);
            }

            const updateData = newState[placeId];
            updateData.loading = false;
            updateData.loaded = false;
            updateData.allLoaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve regions.';

            return newState;
        }

        default: {
            return state;
        }
    }
}
