import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {FaHeartbeat} from "react-icons/fa";
import {IoDocumentLockSharp} from "react-icons/io5";
import {Link} from "react-router-dom";
import {CiImport} from "react-icons/ci";
import {AiOutlineDashboard} from "react-icons/ai";
import {TbFile3D} from "react-icons/tb";
import EmptyLoader from "../EmptyLoader";
import { isCustomerUser, isDesignerUser, isMisUser, isAdminUser, isCircleLeadUser } from "../../../utils/roles";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
function TowersMenuCommon({userRole}) {
    const location = useLocation();

    if (!userRole) {
        return (
            <div>
                <EmptyLoader />
            </div>
        );
    }

    return (
        <div className="px-2 py-2">
            <div>
                <p className="text-sm ml-2 mb-1">Main Menu</p>
            </div>
            <Link to={"/service-requests"}>
            <div className={classNames({
                "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
                "hover:bg-slate-300": (location.pathname.indexOf("/service-requests") === -1),
                "bg-slate-200" : (location.pathname.indexOf("/service-requests") !== -1),
            })}>
                <div>
                    <img
                        src="/img/tower-icons/CircleChart.png"
                        alt="icon png"
                        className="w-4 h-4 inline mr-2"
                    />
                </div>
                <div className="mt-1.5 text-sm">
                    Service Requests
                </div>
            </div>
            </Link>

            {(
                isDesignerUser(userRole) ||
                isMisUser(userRole) ||
                isAdminUser(userRole) ||
                isCustomerUser(userRole)
            ) && (
                <Link to={"/portfolio/analysis"}>
                    <div className={classNames({
                        "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
                        "hover:bg-slate-300": (location.pathname !== "/portfolio/analysis"),
                        "bg-slate-200" : (location.pathname === "/portfolio/analysis"),
                    })}>
                    <div>
                        <AiOutlineDashboard className="w-4 h-4 inline mr-2"/>
                    </div>
                    <div className="mt-1 text-sm">
                            Portfolio Analysis
                        </div>
                    </div>
                </Link>
            )}

            {(
                isCircleLeadUser(userRole) ||
                isMisUser(userRole) ||
                isAdminUser(userRole)
            ) && (
                <Link to={"/reports"}>
                    <div className={classNames({
                        "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
                        "hover:bg-slate-300": (location.pathname !== "/reports"),
                        "bg-slate-200" : (location.pathname === "/reports"),
            })}>
                <div>
                    <IoDocumentLockSharp className="w-4 h-4 inline mr-2"/>
                </div>
                <div className="mt-1 text-sm">
                        Reports
                    </div>
                </div>
                </Link>
            )}

            {(
                isCircleLeadUser(userRole) ||
                isDesignerUser(userRole) ||
                isAdminUser(userRole)
            ) && (
                <Link to={"/manage/designs"}>
                    <div className={classNames({
                        "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
                        "hover:bg-slate-300": (location.pathname !== "/manage/designs"),
                        "bg-slate-200" : (location.pathname === "/manage/designs"),
                })}>
                    <div>
                    <TbFile3D className="w-4 h-4 inline mr-2" />
                    </div>
                        <div className="mt-1 text-sm">
                            Known Designs Library
                        </div>
                    </div>
                </Link>
            )}
        </div>
    );
}

TowersMenuCommon.defaultProps = {
    actions: {},
    userRole: {},
};

TowersMenuCommon.propTypes = {
    actions: PropTypes.object,
    userRole: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TowersMenuCommon);
