import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    ACTIVATE_FLANGE_JOINT_CONNECTION_STARTING,
    ACTIVATE_FLANGE_JOINT_CONNECTION_SUCCESS,
    ACTIVATE_FLANGE_JOINT_CONNECTION_ERROR,
} from '../../../constants/actionTypes';

export default function activateFlangeJointReducer(state = initialState.activateFlangeJoint, action) {
    switch (action.type) {
        case ACTIVATE_FLANGE_JOINT_CONNECTION_STARTING: {
            const { flangeJointId } = action;
            const newState = clone(state);

            if (!newState[flangeJointId]) {
                newState[flangeJointId] = clone(initialState.activateFlangeJoint.flangeJointId);
            }

            const flangeJointData = newState[flangeJointId];
            flangeJointData.saving = true;
            flangeJointData.saved = false;
            flangeJointData.error = null;

            return newState;
        }

        case ACTIVATE_FLANGE_JOINT_CONNECTION_SUCCESS: {
            const { flangeJointId } = action;
            const newState = clone(state);

            if (!newState[flangeJointId]) {
                newState[flangeJointId] = clone(initialState.activateFlangeJoint.flangeJointId);
            }

            const flangeJointData = newState[flangeJointId];
            flangeJointData.saving = false;
            flangeJointData.saved = true;
            flangeJointData.error = null;
            return newState;
        }

        case ACTIVATE_FLANGE_JOINT_CONNECTION_ERROR: {
            const { flangeJointId } = action;
            const newState = clone(state);

            if (!newState[flangeJointId]) {
                newState[flangeJointId] = clone(initialState.activateFlangeJoint.flangeJointId);
            }

            const flangeJointData = newState[flangeJointId];
            flangeJointData.saving = false;
            flangeJointData.saved = false;
            flangeJointData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
