import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_MOBILE_TOWER_STARTING,
  UPDATE_MOBILE_TOWER_SUCCESS,
  UPDATE_MOBILE_TOWER_ERROR,
} from '../../constants/actionTypes';

export default function updateMobileTowerReducer(state = initialState.updateMobileTower, action) {
  switch (action.type) {
    case UPDATE_MOBILE_TOWER_STARTING: {
      const {mobileTowerId} = action;
      const newState = clone(state);

      if (!newState[mobileTowerId]) {
        newState[mobileTowerId] = clone(initialState.updateMobileTower.mobileTowerId);
      }

      const updateData = newState[mobileTowerId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_MOBILE_TOWER_SUCCESS: {
      const {mobileTowerId} = action;
      const newState = clone(state);

      if (!newState[mobileTowerId]) {
        newState[mobileTowerId] = clone(initialState.updateMobileTower.mobileTowerId);
      }

      const updateData = newState[mobileTowerId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_MOBILE_TOWER_ERROR: {
      const {mobileTowerId} = action;
      const newState = clone(state);

      if (!newState[mobileTowerId]) {
        newState[mobileTowerId] = clone(initialState.updateMobileTower.mobileTowerId);
      }

      const updateData = newState[mobileTowerId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
