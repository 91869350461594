import { cloneDeep } from "lodash";
import { ADD_CALCULATED_FORCE_ERROR, 
         ADD_CALCULATED_FORCE_STARTING,
        ADD_CALCULATED_FORCE_SUCCESS } 
        from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function addCalculatedForceReducer(state = initialState.addCalculatedForces, action) {
    switch (action.type) {
        case ADD_CALCULATED_FORCE_STARTING: {
            const newState = cloneDeep(state)
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_CALCULATED_FORCE_SUCCESS: {
            const {data} = action;
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newCalculatedForce= data
            return newState;
        }

        case ADD_CALCULATED_FORCE_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }

    }
}