import { apiUrl } from './appConfig';

export const CHECK_USER_LOGIN = `${apiUrl}/login/check`;
export const USER_AUTO_LOGIN = `${apiUrl}/login/auto?u={userId}`;
export const GET_USER_INFO = `${apiUrl}/login/verify`;
export const SEND_HEARTBEAT = `${apiUrl}/login/heartbeat`;
export const LOGOUT_USER = `${apiUrl}/login/off`;

export const GET_CLIENT_INFO = `${apiUrl}/client_info`;
export const GET_ALARMS = ``;
export const GET_ALERTS = ``;
export const GET_ALARM_NOTIFICATIONS = `${apiUrl}/alarms/alarm_notifications`;
export const GET_USERS = `${apiUrl}/users/search`;
export const GET_USERS_INFO = `${apiUrl}/users/getUsers`;
export const ADD_USER = `${apiUrl}/users`;
export const UPDATE_USER = `${apiUrl}/users/{userId}`;
export const GET_USER_ROLES = `${apiUrl}/roles`;
export const UPDATE_ROLE = `${apiUrl}/roles/{roleId}`;
export const ADD_ROLE = `${apiUrl}/roles`;

export const UPDATE_CLIENT_INFO = `${apiUrl}/client_info/update`;
export const GET_AUDIT_LOG_DATA = `${apiUrl}/audit_logs/get_between`;
export const GET_ALARM_DATA = `${apiUrl}/alarms/get_between`;
export const SAVE_REPORT_SETTINGS = ``;
export const GET_SAVED_REPORTS = ``;
export const REVIEW_REPORT = ``;
export const APPROVE_REPORT = ``;
export const PRINT_REPORT = ``;
export const SAVE_REPORT_COMMENTS = ``;
export const GET_DEVICE_DATA_REPORT = ``;

export const SEARCH_MOBILE_TOWERS = `${apiUrl}/mobile_towers/search`;
export const GET_MOBILE_TOWER_BY_ID = `${apiUrl}/mobile_towers/{towerId}`;
export const GET_ACTIVE_MOBILE_TOWERS = `${apiUrl}/mobile_towers`;
export const GET_INACTIVE_MOBILE_TOWERS = `${apiUrl}/mobile_towers/inactive`;
export const ADD_MOBILE_TOWER = `${apiUrl}/mobile_towers`;
export const UPDATE_MOBILE_TOWER = `${apiUrl}/mobile_towers/{towerId}`;

export const DEACTIVATE_MOBILE_TOWER = `${apiUrl}/mobile_towers/{towerId}`;
export const ACTIVATE_MOBILE_TOWER = `${apiUrl}/mobile_towers/{towerId}`;
export const GET_REGIONS = `${apiUrl}/regions`;
export const GET_REGION_TOWERS = `${apiUrl}/regions/towers/{placeId}`;

export const GET_SEARCH_SUGGESTIONS = `${apiUrl}/search/suggest`;
export const ADD_DEFECT = `${apiUrl}/users/defects`;
export const UPDATE_DEFECT = `${apiUrl}/users/defects`;
export const ACTIVATE_DEFECT = `${apiUrl}/users/defects`;
export const DEACTIVATE_DEFECT = `${apiUrl}/users/defects`;
export const ADD_TENANT = `${apiUrl}/mobile_towers/{towerId}/tenants`;
export const COPY_TENANT = `${apiUrl}/mobile_towers/{towerId}/tenants/{tenantId}/duplicate`;
export const UPDATE_TENANT = `${apiUrl}/mobile_towers/{towerId}/tenants/{tenantId}`;
export const ACTIVATE_TENANT = `${apiUrl}/mobile_towers/{towerId}/tenants/{tenantId}`;
export const DEACTIVATE_TENANT = `${apiUrl}/mobile_towers/{towerId}/tenants/{tenantId}`;

export const GET_MODELS = `${apiUrl}/users/tenants`;
export const GET_WEATHER_DETAILS = `${apiUrl}/users/weather`;
export const GET_TEMPERATURE = `${apiUrl}/weather/forecast`;

export const GET_MODULES = `${apiUrl}/roles/modules`;
export const FORGOT_PASSWORD = `${apiUrl}/login/forgot_password`;
export const RESET_PASSWORD = `${apiUrl}/login/reset_password`;

export const GET_TENANTS = `${apiUrl}/mobile_towers/{towerId}/tenants`;
export const GET_DEFECTS = `${apiUrl}/mobile_towers/defects`;

//TOWER SITENAMES
export const GET_TOWER_SITENAMES = `${apiUrl}/mobile_towers/
`;
export const ADD_TOWER_SITENAME = `${apiUrl}/mobile_towers/regions/create`;

//places
export const GET_PLACES = `${apiUrl}/places`;
export const GET_PLACES_ADDRESS = `${apiUrl}/places/address?lat={lat}&lon={lon}`
export const GET_PLACE_BY_ID = `${apiUrl}/places/{placeId}`;
export const ADD_PLACE = `${apiUrl}/places`;
export const UPDATE_PLACE = `${apiUrl}/places/{placeId}`;
export const ACTIVATE_PLACE = ``;
export const DEACTIVATE_PLACE = ``;
export const GET_INACTIVE_PLACES = `${apiUrl}/places/inactive`;

export const GET_COUNTRIES = `${apiUrl}/places/search_country`;
export const GET_CITIES = `${apiUrl}/places/search_city`;
export const GET_STATES = `${apiUrl}/places/search_state`;

export const GET_RECOMMENDATIONS = `${apiUrl}/mobile_towers/{towerId}/recommendations`;
export const ADD_RECOMMENDATION = `${apiUrl}/mobile_towers/{towerId}/recommendations`;
export const UPDATE_RECOMMENDATION = `${apiUrl}/mobile_towers/{towerId}/recommendations/{recommendationId}`;
export const GET_INACTIVE_RECOMMENDATIONS = `${apiUrl}/mobile_towers/{towerId}/recommendations/inactive`;
export const ACTIVATE_RECOMMENDATION = `${apiUrl}/mobile_towers/{towerId}/recommendations/{recommendationId}`;
export const DEACTIVATE_RECOMMENDATION = `${apiUrl}/mobile_towers/{towerId}/recommendations/{recommendationId}`;

//Upload images
export const ADD_MOBILE_TOWER_IMAGE = `${apiUrl}/mobile_towers/images`;
export const DELETE_MOBILE_TOWER_IMAGE = `${apiUrl}/mobile_towers/images`;
export const UPDATE_IMAGES_ORDER = `${apiUrl}/mobile_towers/images/reorder`;

export const ADD_IMAGE_LABEL = `${apiUrl}/mobile_towers/images`;

export const ADD_TOWER_CHECKLIST = `${apiUrl}/mobile_towers/uploadChecklist`;
export const ADD_TOWER_SITEREPORT = `${apiUrl}/mobile_towers/uploadSiteReport`;
export const ADD_TOWER_RETROFITTING = `${apiUrl}/mobile_towers/uploadRetrofitting`;
export const ADD_WORK_COMPLETION_REPORT = `${apiUrl}/mobile_towers/uploadWCCReport`;
export const ADD_CLIENT_LOGO = `${apiUrl}/mobile_towers/uploadClientLogo`;

//mobile tower members
export const GET_MEMBERS = `${apiUrl}/mobile_towers/{towerId}/members`;
export const ADD_MEMBER = `${apiUrl}/mobile_towers/{towerId}/members`;
export const UPDATE_MEMBER = `${apiUrl}/mobile_towers/{towerId}/members/{memberId}`;
export const ACTIVATE_MEMBER = `${apiUrl}/users/members`;
export const DEACTIVATE_MEMBER = `${apiUrl}/mobile_towers/delete_member/{memberId}`;
export const MEMBERS_CREATE_DEFAULTS = `${apiUrl}/mobile_towers/{towerId}/members/create_defaults/{memberType}`;

//weather trends
export const GET_WEATHER_FOR_DATE_RANGE = `${apiUrl}/weather/forecast/date_range`;
export const GET_WEATHER_FOR_DATE = `${apiUrl}/weather/forecast/date`;


export const SAVE_MOBILE_TOWER_SITE_DETAILS = `${apiUrl}/mobile_towers/{towerId}/site_details`;
export const SAVE_MOBILE_TOWER_AUDIT_HISTORY = `${apiUrl}/mobile_towers/{towerId}/audit_history`;
export const SAVE_MOBILE_TOWER_TOWER_DATA = `${apiUrl}/mobile_towers/{towerId}/tower_data`;
export const SAVE_MOBILE_TOWER_TLVA_SUMMARY = `${apiUrl}/mobile_towers/{towerId}/tlva_summary`;
export const SAVE_MOBILE_TOWER_SITE_ADDRESS_DETAILS = `${apiUrl}/mobile_towers/{towerId}/tower_site_details`;

export const SAVE_DEFECTS_LIST = `${apiUrl}/mobile_towers/{mobileTowerId}/defects`;
export const SAVE_MOBILE_TOWER_SAFETYRATINGS = `${apiUrl}/mobile_towers/{towerId}/tower_safety_ratings`;

export const GET_GUSSET_END_PLATES = `${apiUrl}/mobile_towers/{towerId}/gussetEndPlates`;
export const ADD_GUSSET_END_PLATE = `${apiUrl}/mobile_towers/{towerId}/addGussetEndPlates`;
export const UPDATE_GUSSET_END_PLATE = `${apiUrl}/mobile_towers/{towerId}/gussetEndPlate/{gussetEndPlateId}`;
export const ACTIVATE_GUSSET_END_PLATE = ``;
export const DEACTIVATE_GUSSET_END_PLATE = ``;

export const SAVE_TOWER_BASE_PLATE_DETAILS = `${apiUrl}/mobile_towers/{towerId}/base_plate`;
export const SAVE_MOBILE_TOWER_ANCHOR_BOLTS_DETAILS = `${apiUrl}/mobile_towers/{towerId}/anchor_bolts`;

export const ADD_BUTT_JOINT_CONNECTION = `${apiUrl}/mobile_towers/{towerId}/butt_joint`;
export const UPDATE_BUTT_JOINT_CONNECTION = `${apiUrl}/mobile_towers/{towerId}/butt_joint/{buttJointId}`;
export const ACTIVATE_BUTT_JOINT_CONNECTION = ``;
export const DEACTIVATE_BUTT_JOINT_CONNECTION = ``;
export const GET_BUTT_JOINT_CONNECTIONS = `${apiUrl}/mobile_towers/{towerId}/butt_joints`;

export const GET_FLANGE_JOINT_CONNECTIONS = `${apiUrl}/mobile_towers/{towerId}/flange_joints`;
export const ADD_FLANGE_JOINT_CONNECTION = `${apiUrl}/mobile_towers/{towerId}/flange_joint`;
export const UPDATE_FLANGE_JOINT_CONNECTION = `${apiUrl}/mobile_towers/{towerId}/flange_joint/{flangeJointId}`;
export const ACTIVATE_FLANGE_JOINT_CONNECTION = ``;
export const DEACTIVATE_FLANGE_JOINT_CONNECTION = ``;

export const GET_ENTITIES_ACCESS = `${apiUrl}/users/{userId}/entities`;
export const ADD_ENTITY_ACCESS = `${apiUrl}/users/{userId}/entities`;
export const DELETE_ENTITY_ACCESS = `${apiUrl}/users/{userId}/entities/{accessRecordId}`;

export const GET_TOWER_DATA_REPORT = `${apiUrl}/mobile_towers/reports`;

export const GET_COMPANIES = `${apiUrl}/companies`;
export const GET_COMPANY_BY_ID = `${apiUrl}/companies/{companyId}`;
export const ADD_COMPANY = `${apiUrl}/companies`;
export const UPDATE_COMPANY = `${apiUrl}/companies/{companyId}`;
export const ACTIVATE_COMPANY = `${apiUrl}/companies/{companyId}`;
export const DEACTIVATE_COMPANY = `${apiUrl}/companies/{companyId}`;
export const GET_INACTIVE_COMPANIES = `${apiUrl}/companies/inactive`;

export const GET_PANEL_DETAILS = `${apiUrl}/mobile_towers/{towerId}/panels`;
export const PANELS_CREATE_DEFAULTS = `${apiUrl}/mobile_towers/{towerId}/panels/create_defaults`;
export const ADD_PANEL = `${apiUrl}/mobile_towers/{towerId}/panels`;
export const UPDATE_PANEL = `${apiUrl}/mobile_towers/{towerId}/panels/{panelId}`;
export const DEACTIVATE_PANEL = `${apiUrl}/mobile_towers/{towerId}/panels/{panelId}`;
export const IMPORT_DATA_SHEET = `${apiUrl}/analysis/copy_data_sheet`;

export const GET_POLE_DETAILS = `${apiUrl}/mobile_towers/{towerId}/poles`;
export const CREATE_DEFAULTS_POLES = `${apiUrl}/mobile_towers/{towerId}/poles/create_defaults`;
export const ADD_POLE_DETAILS = `${apiUrl}/mobile_towers/{towerId}/poles/add_poles`;
export const UPDATE_POLE_DETAILS = `${apiUrl}/mobile_towers/{towerId}/{poleId}/poles/update_poles`;
export const DEACTIVATE_POLE_DETAILS = `${apiUrl}/mobile_towers/{towersId}/{poleId}/poles/deactivate_poles`;

export const GET_CONNECTION_BOLTS = '';

export const GET_TOWER_ANALYSIS = `${apiUrl}/analysis/{towerId}/{analysisType}`;
export const GET_TOWER_ANALYSIS_2 = `${apiUrl}/analysis/{towerId}/{analysisType}/{towerMode}`;

export const GET_TOWER_ANALYSIS_RTP = `${apiUrl}/analysis_rtp/{towerId}/{analysisTypeRtp}`;
export const GET_TOWER_ANALYSIS_RTP_2 = `${apiUrl}/analysis_rtp/{towerId}/{analysisTypeRtp}/{towerMode}`;

export const GET_TOWERS_COMPANY = ``;
export const ADD_TOWER_COMPANY = `${apiUrl}/mobile_towers/{towerId}/tower_company_add`;
export const DELETE_TOWER_COMPANY = ``;

export const SAVE_MATERIAL_SPECIFICATIONS = `${apiUrl}/mobile_towers/{towerId}/material_specification`;
export const SAVE_MATERIAL_SPECIFICATIONS_MEMBERS = `${apiUrl}/material_spec/{towerId}`;

export const UPDATE_DESIGN_PARAMETERS = `${apiUrl}/mobile_towers/{towerId}/design_parameters`;
export const UPDATE_CABLE_LADDER = `${apiUrl}/mobile_towers/{towerId}/cable_ladder`;

export const GET_TOWER_OPERATORS = `${apiUrl}/operators/search`;

export const GET_USER_PREFERENCES = `${apiUrl}/user_preferences/preferences`;
export const UPDATE_USER_PREFERENCE = `${apiUrl}/user_preferences/{userPreferenceId}/update_preferences`;
export const ADD_TOWER_DRAWING = `${apiUrl}/mobile_towers/uploadDrawing`;

export const GET_AUDIT_DATA = `${apiUrl}/mobile_towers/{mobileTowerId}/auditHistory`;
export const ADD_AUDIT_DATA = `${apiUrl}/mobile_towers/{mobileTowerId}/auditData`;
export const UPDATE_AUDIT_DATA = `${apiUrl}/mobile_towers/{mobileTowerId}/auditData/{auditId}`;
export const DEACTIVATE_AUDIT_DATA = `${apiUrl}/mobile_towers/{mobileTowerId}/auditData/{auditId}`;

export const GET_ALL_SERVICE_REQUESTS = `${apiUrl}/service_requests/all`;
export const GET_SERVICE_REQUESTS_SUMMARY = `${apiUrl}/service_requests/summary`;
export const GET_INACTIVE_SERVICE_REQUEST = `${apiUrl}/service_requests/inactive`;
export const GET_SERVICE_REQUESTS = `${apiUrl}/service_requests/tower/{mobileTowerId}`;
export const ADD_SERVICE_REQUEST = `${apiUrl}/service_requests/tower/{mobileTowerId}`;
export const UPDATE_SERVICE_REQUEST = `${apiUrl}/service_requests/{serviceId}`;
export const DEACTIVATE_SERVICE_REQUEST = `${apiUrl}/service_requests/{serviceId}`;
export const UPDATE_SR_STATUS = `${apiUrl}/service_requests/{serviceRequestId}/status`

export const GET_REACTIONS = `${apiUrl}/service_requests/{serviceRequestId}/reactions`;
export const ADD_REACTION = `${apiUrl}/service_requests/{serviceRequestId}/reactions`;
export const UPDATE_REACTION = `${apiUrl}/service_requests/{serviceRequestId}/reactions/{reactionId}`;
export const DELETE_REACTION = `${apiUrl}/service_requests/{serviceRequestId}/reactions/{reactionId}`;


export const ENTITY_AUDIT_LOGS = `${apiUrl}/service_requests/{serviceRequestId}/audit_logs`;

export const PO_DETAIL = `${apiUrl}/service_requests/{mobileTowerId}/{serviceRequestId}/updatePo`
export const INVOICE_DETAIL = `${apiUrl}/service_requests/{mobileTowerId}/{serviceRequestId}/updateInvoice`
export const CONTACT_DETAIL = `${apiUrl}/service_requests/{mobileTowerId}/{serviceRequestId}/updateContact`;

export const GET_MOBILE_TOWER_DESIGNS = `${apiUrl}/known_designs`;
export const GET_MOBILE_TOWER_DESIGN_BY_ID = `${apiUrl}/known_designs/{designId}`;
export const GET_TOWER_DESIGN_FILES = `${apiUrl}/known_designs/{designId}/files`;
export const DOWNLOAD_DESIGN_FILE = `${apiUrl}/known_designs/{designId}/download/{fileKey}?token={token}`;
export const ADD_MOBILE_TOWER_DESIGN = `${apiUrl}/known_designs/add_design`;
export const UPDATE_MOBILE_TOWER_DESIGN = `${apiUrl}/known_designs/{designId}/update_design`;
export const ACTIVATE_MOBILE_TOWER_DESIGN = `${apiUrl}/known_designs/{designId}/activate_design`;
export const DEACTIVATE_MOBILE_TOWER_DESIGN = `${apiUrl}/known_designs/{designId}/deactivate_design`;
export const GET_INACTIVE_MOBILE_TOWER_DESIGNS = `${apiUrl}/known_designs/inactive`;

export const GET_CALCULATED_FORCE = `${apiUrl}/calculate/{mobileTowerId}/`;
export const ADD_CALCULATED_FORCE = `${apiUrl}/calculate/{mobileTowerId}/add_calculated_force`;
export const UPDATE_CALCULATED_FORCE = `${apiUrl}/calculate/{calId}/update_calculated_force`;
export const DEACTIVATE_CALCULATED_FORCE = `${apiUrl}/calculate/{calId}/delete_calculated_force`;
export const FORCE_CREATE_DEFAULT = `${apiUrl}/calculate/{mobileTowerId}/{memberType}/createDefaults`;
export const UPDATE_BULK_FORCE_DATA = `${apiUrl}/calculate/{mobileTowerId}/bulk_update`

export const GET_CONNECTION_BOLT = `${apiUrl}/connection/{mobileTowerId}/`;
export const ADD_CONNECTION_BOLT = `${apiUrl}/connection/{mobileTowerId}/add_connection`;
export const UPDATE_CONNECTION_BOLT = `${apiUrl}/connection/{connectionId}/update_connection`;
export const DELETE_CONNECTION_BOLT = `${apiUrl}/connection/{connectionId}/delete_connection`;
export const BOLTS_CREATE_DEFAULTS = `${apiUrl}/connection/{mobileTowerId}/{memberType}/create_defaults`;

export const UPDATE_EXISTING_FOUNDATION_FORCE = `${apiUrl}/mobile_towers/{mobileTowerId}/foundation_forces`;
export const UPDATE_ORIGINAL_FOUNDATION_FORCE = `${apiUrl}/mobile_towers/{mobileTowerId}/foundation_forces`;
export const UPDATE_ADD_FOUNDATION_FORCE = `${apiUrl}/mobile_towers/{mobileTowerId}/foundation_forces`;
export const UPDATE_SPARE_FOUNDATION_FORCE = `${apiUrl}/mobile_towers/{mobileTowerId}/foundation_forces`;

export const UPDATE_STAAD_CONFIG = `${apiUrl}/mobile_towers/{mobileTowerId}/update_staad_input`;
export const UPDATE_STAAD_POLE_CONFIG = `${apiUrl}/mobile_towers/{mobileTowerId}/update_staad_input_pole`;

export const DOWNLOAD_EXCEL = `${apiUrl}/analysis/{mobileTowerId}/download_excel?token={token}`;
export const DOWNLOAD_EXCEL_RTP = `${apiUrl}/analysis_rtp/{mobileTowerId}/download_excel_rtp?token={token}`;

//mobile tower pole members
export const GET_POLE_MEMBERS = `${apiUrl}/mobile_towers/{towerId}/pole_members`;
export const ADD_POLE_MEMBER = `${apiUrl}/mobile_towers/{towerId}/addPole_members`;
export const UPDATE_POLE_MEMBER = `${apiUrl}/mobile_towers/{towerId}/updatePole_members/{memberId}`;
export const ACTIVATE_POLE_MEMBER = `${apiUrl}/users/activatePole_members`;
export const DEACTIVATE_POLE_MEMBER = `${apiUrl}/mobile_towers/deletePole_member/{memberId}`;
export const CREATE_DEFAULT_POLE_MEMBERS = `${apiUrl}/mobile_towers/{towerId}/{memberType}/pole_member/create_defaults`;

// foundation force pole details
export const GET_FOUNDATION_FORCE_POLE = `${apiUrl}/mobile_towers/{towerId}/foundation_force_pole`;
export const ADD_FOUNDATION_FORCE_POLE = `${apiUrl}/mobile_towers/{towerId}/add_foundation_force_pole`;
export const UPDATE_FOUNDATION_FORCE_POLE = `${apiUrl}/mobile_towers/{towerId}/{forcePoleId}/update_foundation_force_pole`;
export const DEACTIVATE_FOUNDATION_FORCE_POLE = `${apiUrl}/mobile_towers/{towerId}/{forcePoleId}/delete_foundation_force_pole`;
export const CREATE_DEFAULT_FOUNDATION_FORCE = `${apiUrl}/mobile_towers/{towerId}/pole_foundation/create_defaults`;

// pole cable ladder
export const GET_POLE_CABLE_LADDERS = `${apiUrl}/mobile_towers/{towerId}/pole_cable_ladder`;
export const ADD_POLE_CABLE_LADDER = `${apiUrl}/mobile_towers/{towerId}/add_pole_cable_ladder`;
export const UPDATE_POLE_CABLE_LADDER = `${apiUrl}/mobile_towers/{towerId}/{poleCableId}/update_pole_cable_ladder`;
export const DEACTIVATE_POLE_CABLE_LADDER = `${apiUrl}/mobile_towers/{towerId}/{poleCableId}/deactivate_pole_cable_ladder`;
export const CREATE_DEFAULT_POLE_CABLE_LADDER = `${apiUrl}/mobile_towers/{towerId}/pole_cable_ladder/create_defaults`;

// Pedestal Design

export const GET_PEDESTAL_DESIGNS = `${apiUrl}/mobile_towers/{towerId}/pedestal`;
export const ADD_PEDESTAL_DESIGN = `${apiUrl}/mobile_towers/{towerId}/add_pedestal`;
export const UPDATE_PEDESTAL_DESIGN = `${apiUrl}/mobile_towers/{towerId}/{pedestalId}/update_pedestal`;
export const DEACTIVATE_PEDESTAL_DESIGN = `${apiUrl}/mobile_towers/{towerId}/{pedestalId}/deactivate_pedestal`;
export const CREATE_DEFAULT_PEDESTAL_DESIGN = `${apiUrl}/mobile_towers/{towerId}/pedestal/create_defaults`;

// Comparison Utilization

export const GET_UTILIZATIONS = `${apiUrl}/mobile_towers/{towerId}/utilization`;
export const ADD_UTILIZATION = `${apiUrl}/mobile_towers/{towerId}/add_utilization`;
export const UPDATE_UTILIZATION = `${apiUrl}/mobile_towers/{towerId}/{utilizationId}/update_utilization`;
export const DEACTIVATE_UTILIZATION = `${apiUrl}/mobile_towers/{towerId}/{utilizationId}/deactivate_utilization`;
export const CREATE_DEFAULT_UTILIZATION = `${apiUrl}/mobile_towers/{towerId}/utilization/create_defaults`;

export const TOWER_DATA_BULK_IMPORT = `${apiUrl}/mobile_towers/bulk_import`;

export const PLACE_DATA_BULK_IMPORT = `${apiUrl}/mobile_towers/place_bulk_import`;

export const GET_RTP_SECTIONS = `${apiUrl}/analysis_rtp/sections`;

export const GET_TOWER_UTILIZATION = `${apiUrl}/analytics/getAll`
