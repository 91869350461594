import { cloneDeep } from "lodash";
import { UPDATE_FOUNDATION_FORCE_POLE_DETAILS_ERROR, 
         UPDATE_FOUNDATION_FORCE_POLE_DETAILS_STARTING, 
         UPDATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS 
        } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function updateFoundationForcePoleReducer(state = initialState.updateFoundationForcePoleDetail,action){
    switch(action.type){
        case UPDATE_FOUNDATION_FORCE_POLE_DETAILS_STARTING: {
            const newState = cloneDeep(state)
            const {forcePoleId} = action;
            if(!newState[forcePoleId]){
                newState[forcePoleId] = cloneDeep(initialState.updateFoundationForcePoleDetail.forcePoleId)
            }
            const poleData = newState[forcePoleId];
            poleData.saving = true;
            poleData.saved = false;
            poleData.error = null;

            return newState;
        }

        case UPDATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS: {
            const {forcePoleId} = action;
            const newState = cloneDeep(state);

            if(!newState[forcePoleId]){
                newState[forcePoleId] = cloneDeep(initialState.updateFoundationForcePoleDetail.forcePoleId);
            }

            const poleData = newState[forcePoleId];
            poleData.saving = false;
            poleData.saved = true;
            poleData.error = action.error;

            return newState;
        }

        case UPDATE_FOUNDATION_FORCE_POLE_DETAILS_ERROR: {
            const {forcePoleId} = action.forcePoleId
            const newState = cloneDeep(state)
            if(!newState[forcePoleId]){
                newState[forcePoleId] = cloneDeep(initialState.updateFoundationForcePoleDetail.forcePoleId);
            }
            const poleData = newState[forcePoleId]
            poleData.saving = false;
            poleData.saved =false;
            poleData.error = action.error;

            return newState;
        }
        default:{
            return state
        }
    }
}