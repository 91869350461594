import { cloneDeep } from "lodash";
import { DEACTIVATE_UTILIZATION_ERROR, DEACTIVATE_UTILIZATION_STARTING, DEACTIVATE_UTILIZATION_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function deactivateUtilizationReducer(state = initialState.deactivateUtilizationDetail,action){
    switch(action.type){
        case DEACTIVATE_UTILIZATION_STARTING:{
            const {utilizationId} = action;
            const newState = cloneDeep(state)

            if(!newState[utilizationId]){
                newState[utilizationId] = cloneDeep(initialState.deactivateUtilizationDetail.utilizationId)
            }

            const deactivateData = newState[utilizationId]
            deactivateData.saving = true;
            deactivateData.saved = false;
            deactivateData.error = null;

            return newState;
        }

        case DEACTIVATE_UTILIZATION_SUCCESS:{
            const {utilizationId} = action;
            const newState = cloneDeep(state)

            if(!newState[utilizationId]){
                newState[utilizationId] = cloneDeep(initialState.deactivateUtilizationDetail.utilizationId)
            }

            const deactivateData = newState[utilizationId]
            deactivateData.saving = false;
            deactivateData.saved = true;
            deactivateData.error = null;

            return newState;
        }

        case DEACTIVATE_UTILIZATION_ERROR:{
            const {utilizationId} = action;
            const newState = cloneDeep(state)

            if(!newState[utilizationId]){
                newState[utilizationId] = cloneDeep(initialState.deactivateUtilizationDetail.utilizationId)
            }

            const deactivateData = newState[utilizationId]
            deactivateData.saving = false;
            deactivateData.saved = false;
            deactivateData.error = action.error ? action.error : "Failed to deactivate Comparison Utilization data";

            return newState;
        }

        default:{
            return state;
        }
    }
}