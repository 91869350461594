import { clone } from "lodash";
import { ADD_POLE_MEMBER_ERROR, ADD_POLE_MEMBER_STARTING, ADD_POLE_MEMBER_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function addPoleMemberReducer(state = initialState.addPoleMember, action){
    switch (action.type) {
        case ADD_POLE_MEMBER_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_POLE_MEMBER_SUCCESS: {
            const { data } = action;

            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;
      
            newState.newPoleMember = data.poleMember;
            return newState;
        }

        case ADD_POLE_MEMBER_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}