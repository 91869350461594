import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_MODULES_ERROR,
    GET_MODULES_STARTING,
    GET_MODULES_SUCCESS,
} from '../../constants/actionTypes';

export default function modulesReducer(state = initialState.modules, action) {
    switch (action.type) {
        case GET_MODULES_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MODULES_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.modules.forEach((module) => {
                const moduleId = module.id;
                ids.push(moduleId);
                map[moduleId] = module;
            });

            newState.ids = ids;
            newState.map = map;
            //newState.page = action.data.page;
            //newState.pages = action.data.pages;
            //newState.limit = action.data.limit;
            //newState.total = action.data.total;
            //newState.isLastPage = newState.page >= newState.pages;
            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_MODULES_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve modules.';
            return newState;
        }
        
        default: {
            return state;
        }
    }
}
