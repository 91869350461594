import { cloneDeep } from "lodash";
import { DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_ERROR, 
    DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_STARTING, 
    DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS 
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";
export default function deactivateFoundationForcePoleReducer(state = initialState.deactivateFoundationForcePoleDetail,action){
    switch(action.type){
        case DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_STARTING: {
            const {forcePoleId} = action;
            const newState = cloneDeep(state);

            if(!newState[forcePoleId]){
                newState[forcePoleId] = cloneDeep(initialState.deactivateFoundationForcePoleDetail.forcePoleId);
            }
            const poleData = newState.forcePoleId;
            poleData.saving = true;
            poleData.saved = false;
            poleData.error = null;
            return newState;
        }
        case DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS:{
            const {forcePoleId} = action;
            const newState = cloneDeep(state);

            if(!newState[forcePoleId]){
                newState[forcePoleId]= cloneDeep(initialState.deactivateFoundationForcePoleDetail.forcePoleId)
            }
            const poleData = newState[forcePoleId]
            poleData.saving = false;
            poleData.saved = true;
            poleData.error = action.error;
            return newState;
        }
        case DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_ERROR: {
            const {forcePoleId} = action;
            const newState = cloneDeep(state);

            if(!newState[forcePoleId]){
                newState[forcePoleId]=cloneDeep(initialState.deactivateFoundationForcePoleDetail.forcePoleId);
            }
            const poleData = newState[forcePoleId];
            poleData.saving = false;
            poleData.saved = false;
            poleData.error = action.error;

            return newState;
        }
        default:{
            return state;
        }
    }
}