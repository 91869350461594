import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  DEACTIVATE_PLACE_STARTING,
  DEACTIVATE_PLACE_SUCCESS,
  DEACTIVATE_PLACE_ERROR,
} from '../../constants/actionTypes';

export default function deactivatePlaceReducer(state = initialState.deactivatePlace, action) {
  switch (action.type) {
    case   DEACTIVATE_PLACE_STARTING: {
      const {placeId} = action;
      const newState = clone(state);

      if (!newState[placeId]) {
        newState[placeId] = clone(initialState.deactivatePlace.placeId);
      }

      const placeData = newState[placeId];
      placeData.saving = true;
      placeData.saved = false;
      placeData.error = null;

      return newState;
    }

    case DEACTIVATE_PLACE_SUCCESS: {
      const {placeId} = action;
      const newState = clone(state);

      if (!newState[placeId]) {
        newState[placeId] = clone(initialState.deactivatePlace.placeId);
      }

      const placeData = newState[placeId];
      placeData.saving = false;
      placeData.saved = true;
      placeData.error = null;
      return newState;
    }

    case DEACTIVATE_PLACE_ERROR: {
      const {placeId} = action;
      const newState = clone(state);

      if (!newState[placeId]) {
        newState[placeId] = clone(initialState.deactivatePlace.placeId);
      }

      const placeData = newState[placeId];
      placeData.saving = false;
      placeData.saved = false;
      placeData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
