import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  ACTIVATE_ROLE_STARTING,
  ACTIVATE_ROLE_SUCCESS,
  ACTIVATE_ROLE_ERROR,
} from '../../constants/actionTypes';

export default function activateRoleReducer(state = initialState.activateRole, action) {
  switch (action.type) {
    case ACTIVATE_ROLE_STARTING: {
      const {roleId} = action;
      const newState = clone(state);

      if (!newState[roleId]) {
        newState[roleId] = clone(initialState.activateRole.roleId);
      }

      const locationData = newState[roleId];
      locationData.saving = true;
      locationData.saved = false;
      locationData.error = null;

      return newState;
    }

    case ACTIVATE_ROLE_SUCCESS: {
      const {roleId} = action;
      const newState = clone(state);

      if (!newState[roleId]) {
        newState[roleId] = clone(initialState.activateRole.roleId);
      }

      const locationData = newState[roleId];
      locationData.saving = false;
      locationData.saved = true;
      locationData.error = null;
      return newState;
    }

    case ACTIVATE_ROLE_ERROR: {
      const {roleId} = action;
      const newState = clone(state);

      if (!newState[roleId]) {
        newState[roleId] = clone(initialState.activateRole.roleId);
      }

      const locationData = newState[roleId];
      locationData.saving = false;
      locationData.saved = false;
      locationData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
