import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_TOWER_DATA_STARTING,
    GET_TOWER_DATA_SUCCESS,
    GET_TOWER_DATA_ERROR,
} from '../../constants/actionTypes';
import {chunk} from "lodash";

export default function towerDataReducer(state = initialState.towerDataReports, action) {
  switch (action.type) {
    case GET_TOWER_DATA_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_TOWER_DATA_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = action.data.towerIds;
      const map = action.data.towersMap;

      // Paginate IDs

      const pageLimit = 10;

      newState.ids = ids;
      newState.pagedIds = chunk(ids, pageLimit);
      newState.map = map;

      //newState.page = action.data.page;
      newState.pages = newState.pagedIds.length;
      newState.limit = pageLimit;
      newState.total = ids.length;
      //newState.isLastPage = newState.page >= newState.pages;

      newState.loading = false;
      newState.loaded = true;

      console.log('Setting new state in report data');
      console.log(newState);

      return newState;
    }

    case GET_TOWER_DATA_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve Towers Data.';
      return newState;
    }

    default: {
      return state;
    }
  }
}
