import { cloneDeep } from "lodash";
import { UPDATE_POLE_DETAILS_ERROR, UPDATE_POLE_DETAILS_STARTING, UPDATE_POLE_DETAILS_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function updatePolesDetailsReducer (state = initialState.updatePoleDetail,action){
    switch(action.type){
        case UPDATE_POLE_DETAILS_STARTING: {
            const {poleId} = action;
            const newState = cloneDeep(state);

            if(!newState[poleId]){
                newState[poleId] = cloneDeep(initialState.updatePoleDetail.poleId)
            }

            const updateData = newState[poleId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }

        case UPDATE_POLE_DETAILS_SUCCESS: {
            const {poleId} = action;
            const newState = cloneDeep(state);

            if(!newState[poleId]){
                newState[poleId] = cloneDeep(initialState.updatePoleDetail.poleId)
            }

            const updateData = newState[poleId];
            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;

            return newState;
        }

        case UPDATE_POLE_DETAILS_ERROR: {
            const {poleId} = action;
            const newState = cloneDeep(state);

            if(!newState[poleId]){
                newState[poleId] = cloneDeep(initialState.updatePoleDetail.poleId)
            }

            const updateData = newState[poleId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.err;
            return newState;
        }
        default: {
            return state;
        }
    }

}