import { cloneDeep } from 'lodash';
import {
    GET_COMPANIES_STARTING,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_ERROR,
    ADD_COMPANY_SUCCESS,
    UPDATE_COMPANY_SUCCESS,
    ACTIVATE_COMPANY_SUCCESS,
    DEACTIVATE_COMPANY_SUCCESS,
    GET_INACTIVE_COMPANIES_SUCCESS,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

const sortByOrder = (newState) => { }

export default function companiesReducer(state = initialState.companies, action) {
    switch (action.type) {
        case GET_COMPANIES_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            newState.error = null;

            return newState;
        }

        case GET_COMPANIES_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];

            action.data.companies.forEach((company) => {
                const companyId = company.id;
                ids.push(companyId);
                newState.map[companyId] = company;
            });

            newState.ids = ids;
            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_INACTIVE_COMPANIES_SUCCESS: {
            const newState = cloneDeep(state);
            const inactiveIds = [];

            action.data.companies.forEach((company) => {
                const companyId = company.id;
                inactiveIds.push(companyId);
                newState.map[companyId] = company;
            });

            newState.inactiveIds = inactiveIds;

            sortByOrder(newState);
            return newState;
        }

        case GET_COMPANIES_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrive companies';

            return newState;
        }

        case ADD_COMPANY_SUCCESS: {
            const newState = cloneDeep(state);
            const { company } = action.data;
            const companyId = company.id;

            //Push new Company Data
            newState.map[companyId] = company;
            newState.ids.push(companyId);

            sortByOrder(newState);

            return newState;
        }

        case UPDATE_COMPANY_SUCCESS: {
            const newState = cloneDeep(state);
            const { company } = action.data;
            const companyId = company.id;
            newState.map[companyId] = company;

            sortByOrder(newState);
            return newState;
        }

        case ACTIVATE_COMPANY_SUCCESS: {
            const newState = cloneDeep(state);
            const { companyId } = action;
            newState.ids.push(companyId);

            newState.inactiveIds = newState.inactiveIds.filter((cpId) => {
                return companyId !== cpId;
            });

            if (newState.map[companyId]) {
                newState.map[companyId].isActive = 1;
            }

            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_COMPANY_SUCCESS: {
            const newState = cloneDeep(state);
            const { companyId } = action;
            newState.inactiveIds.push(companyId);

            newState.ids = newState.ids.filter((cpId) => {
                return companyId !== cpId;
            });

            if (newState.map[companyId]) {
                newState.map[companyId].isActive = 0;
            }

            sortByOrder(newState);
            return newState;
        }

        default: {
            return state;
        }
    }
}