import { cloneDeep } from "lodash";
import { UPDATE_CALCULATED_FORCE_ERROR,
         UPDATE_CALCULATED_FORCE_STARTING, 
         UPDATE_CALCULATED_FORCE_SUCCESS 
        } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";
  
export default function updateCalculatedForceReducer(state = initialState.updateCalculatedForces, action) {
    switch(action.type) {
        case UPDATE_CALCULATED_FORCE_STARTING: {
            const {calId} = action;
            const newState = cloneDeep(state);
            if(!newState[calId]) {
                newState[calId] = cloneDeep(initialState.updateCalculatedForces.calId);
            }
            const calData = newState[calId];
            calData.saving = true;
            calData.saved = false;
            calData.error = null;

            return newState;
        }

        case UPDATE_CALCULATED_FORCE_SUCCESS : {
            console.log("check")
            const { calId } = action;
            const newState = cloneDeep(state);

            if (!newState[calId]) {
                newState[calId] = cloneDeep(initialState.updateCalculatedForces.calId)
            }

            const calData = newState[calId];
            calData.saving = false;
            calData.saved = true;
            calData.error = null;
            return newState;
        }

        case UPDATE_CALCULATED_FORCE_ERROR : {
            const {calId} = action;
            const newState = cloneDeep(state);

            if(!newState[calId]) {
                newState[calId] = cloneDeep(initialState.updateCalculatedForces.calId);
            }

            const calData = newState[calId];
            calData.saving = false;
            calData.saved = false;
            calData.error = action.err;
            return newState;
        }
        default: {
            return state;
        }
    }
}