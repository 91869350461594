import React from "react";
import {
    ADD_PANEL_DETAILS_STARTING,
    ADD_PANEL_DETAILS_SUCCESS,
    ADD_PANEL_DETAILS_ERROR,    
} from "../../../constants/actionTypes";
import { cloneDeep } from "lodash";
import initialState from "../../../store/initialState";

export default function addPanelDetailsReducer(state = initialState.addPanelDetail, action){
    switch(action.type){
        case ADD_PANEL_DETAILS_STARTING : {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }
        case ADD_PANEL_DETAILS_SUCCESS : {
            const {data} = action;

            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;
            newState.newPanelData = data.panel;
            return newState;
        }
        case ADD_PANEL_DETAILS_ERROR : {
            const newState = cloneDeep(state)
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err
            return newState;
        }
        default : {
            return state;
        }
    }
}