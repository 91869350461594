import { cloneDeep } from 'lodash';
import {
    GET_MOBILE_TOWER_DESIGN_STARTING,
    GET_MOBILE_TOWER_DESIGN_SUCCESS,
    GET_MOBILE_TOWER_DESIGN_ERROR,
    ADD_MOBILE_TOWER_DESIGN_SUCCESS,
    UPDATE_MOBILE_TOWER_DESIGN_SUCCESS,
    ACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS,
    DEACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS,
    GET_INACTIVE_MOBILE_TOWER_DESIGNS_SUCCESS,
} from '../../constants/actionTypes';
import initialState from '../../store/initialState';

export default function designsReducer(state = initialState.towerDesigns, action) {
    
    const sortByOrder = (newState) => { }    
    switch (action.type) {
        case GET_MOBILE_TOWER_DESIGN_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
      
            return newState;
        }

        case GET_MOBILE_TOWER_DESIGN_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
      
            action.data.towerDesigns.forEach((design) => {
              const designId = design.id;
              ids.push(designId);
              newState.map[designId] = design;
            });
      
            newState.ids = ids;
            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);
      
            return newState;
        }

        case GET_INACTIVE_MOBILE_TOWER_DESIGNS_SUCCESS: {
            const newState = cloneDeep(state);
            const inactiveIds = [];

            action.data.towerDesigns.forEach((design) => {
                const designId = design.id;
                inactiveIds.push(designId);
                newState.map[designId] = design;
            });

            newState.inactiveIds = inactiveIds;

            sortByOrder(newState);
            return newState;
        }

        case GET_MOBILE_TOWER_DESIGN_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve designs.';
      
            return newState;
        }

        case ADD_MOBILE_TOWER_DESIGN_SUCCESS: {
            const newState = cloneDeep(state);
            const { design } = action.data;
            const designId = design.id;

            //Push new design Data
            newState.map[designId] = design;
            newState.ids.push(designId);

            sortByOrder(newState);

            return newState;
        }

        case UPDATE_MOBILE_TOWER_DESIGN_SUCCESS: {
            const newState = cloneDeep(state);
            const { design } = action.data;
            const designId = design.id;
            newState.map[designId] = design;

            sortByOrder(newState);
            return newState;
        }

        case ACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS: {
            const newState = cloneDeep(state);
            const { designId } = action;
            newState.ids.push(designId);

            newState.inactiveIds = newState.inactiveIds.filter((dgId) => {
                return designId !== dgId;
            });

            if (newState.map[designId]) {
                newState.map[designId].isActive = 1;
            }

            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS: {
            const newState = cloneDeep(state);
            const { designId } = action;
            newState.inactiveIds.push(designId);

            newState.ids = newState.ids.filter((dgId) => {
                return designId !== dgId;
            });

            if (newState.map[designId]) {
                newState.map[designId].isActive = 0;
            }

            sortByOrder(newState);
            return newState;
        }


        default: {
            return state;
        }
    }
}