import cloneDeep from 'lodash/cloneDeep';
import initialState from '../store/initialState';
import {
  ENABLE_FULL_SCREEN,
  DISABLE_FULL_SCREEN,
} from '../constants/actionTypes';

export default function fullScreenStatusReducer(state = initialState.fullScreenStatus, action) {
  switch (action.type) {
    case ENABLE_FULL_SCREEN: {
      const newState = cloneDeep(state);
      newState.enabled = true;
      return newState;
    }

    case DISABLE_FULL_SCREEN: {
      const newState = cloneDeep(state);
      newState.enabled = false;
      return newState;
    }

    default: {
      return state;
    }
  }
}
