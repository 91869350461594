import cloneDeep from 'lodash/cloneDeep';
import initialState from '../store/initialState';
import {
    GET_CLIENT_INFO_ERROR,
    GET_CLIENT_INFO_STARTING,
    GET_CLIENT_INFO_SUCCESS,
    UPDATE_CLIENT_INFO_SUCCESS,
} from '../constants/actionTypes';

export default function clientInfoReducer(state = initialState.clientInfo, action) {
    switch (action.type) {
        case GET_CLIENT_INFO_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_CLIENT_INFO_SUCCESS:
        case UPDATE_CLIENT_INFO_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;
            newState.data = action.data.clientInfo;

            return newState;
        }

        case GET_CLIENT_INFO_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve client info.';
            return newState;
        }

        default: {
            return state;
        }
    }
}
