import {
  GET_ANALYSIS_STARTING,
  GET_ANALYSIS_SUCCESS,
  GET_ANALYSIS_ERROR,
} from "../../constants/actionTypes";
import { cloneDeep } from "lodash";
import initialState from "../../store/initialState";

export default function analysisReducer(
  state = initialState.towerAnalysis,
  action
) {
  switch (action.type) {
    case GET_ANALYSIS_STARTING: {
      const { analysisType, towerId } = action;
      const newState = cloneDeep(state);

      if (!newState[towerId]) {
        newState[towerId] = cloneDeep(initialState.towerAnalysis.towerId);
      }

      if (!newState[towerId][analysisType]) {
        newState[towerId][analysisType] = cloneDeep(
          initialState.towerAnalysis.towerId.analysisType
        );
      }

      const analysisData = newState[towerId][analysisType];
      analysisData.loading = true;
      analysisData.loaded = false;
      return newState;
    }

    case GET_ANALYSIS_SUCCESS: {
      const { analysisType, data, towerId } = action;
      const newState = cloneDeep(state);

      if (!newState[towerId]) {
        newState[towerId] = cloneDeep(initialState.towerAnalysis.towerId);
      }

      if (!newState[towerId][analysisType]) {
        newState[towerId][analysisType] = cloneDeep(
          initialState.towerAnalysis.towerId.analysisType
        );
      }

      const analysisData = newState[towerId][analysisType];
      analysisData.loading = false;
      analysisData.loaded = true;
      analysisData.data = data.outputData;
      return newState;
    }

    case GET_ANALYSIS_ERROR: {
      const { analysisType, towerId, err } = action;
      const newState = cloneDeep(state);

      if (!newState[towerId]) {
        newState[towerId] = cloneDeep(initialState.towerAnalysis.towerId);
      }

      if (!newState[towerId][analysisType]) {
        newState[towerId][analysisType] = cloneDeep(
          initialState.towerAnalysis.towerId.analysisType
        );
      }

      const analysisData = newState[towerId][analysisType];
      analysisData.loading = false;
      analysisData.loaded = false;
      analysisData.data = err;
      return newState;
    }
    default: {
      return state;
    }
  }
}
