import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  UPDATE_GUSSET_END_PLATE_STARTING,
  UPDATE_GUSSET_END_PLATE_SUCCESS,
  UPDATE_GUSSET_END_PLATE_ERROR,
} from '../../../constants/actionTypes';

export default function updatGussetEndPlateReducer(state = initialState.updateGussetEndPlate, action) {
  switch (action.type) {
    case UPDATE_GUSSET_END_PLATE_STARTING: {

      const { gussetEndPlateId } = action;
      const newState = clone(state);

      if (!newState[gussetEndPlateId]) {
        newState[gussetEndPlateId] = clone(initialState.updateGussetEndPlate.gussetEndPlateId);
      }

      const updateData = newState[gussetEndPlateId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_GUSSET_END_PLATE_SUCCESS: {
      const { gussetEndPlateId } = action;

      const newState = clone(state);

      if (!newState[gussetEndPlateId]) {
        newState[gussetEndPlateId] = clone(initialState.updateGussetEndPlate.gussetEndPlateId);
      }
      const updateData = newState[gussetEndPlateId];

      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;

      return newState;
    }

    case UPDATE_GUSSET_END_PLATE_ERROR: {
      const { gussetEndPlateId } = action;

      const newState = clone(state);

      if (!newState[gussetEndPlateId]) {
        newState[gussetEndPlateId] = clone(initialState.updateGussetEndPlate.gussetEndPlateId);
      }
      const updateData = newState[gussetEndPlateId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
