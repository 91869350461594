import { cloneDeep } from "lodash";
import {
  GET_ANALYSIS_RTP_ERROR,
  GET_ANALYSIS_RTP_STARTING,
  GET_ANALYSIS_RTP_SUCCESS,
} from "../../constants/actionTypes";
import initialState from "../../store/initialState";

export default function analysisRtpReducer(
  state = initialState.towerAnalysisRtp,
  action
) {
  switch (action.type) {
    case GET_ANALYSIS_RTP_STARTING: {
      const { analysisTypeRtp, towerId } = action;
      const newState = cloneDeep(state);

      if (!newState[towerId]) {
        newState[towerId] = cloneDeep(initialState.towerAnalysisRtp.towerId);
      }

      if (!newState[towerId][analysisTypeRtp]) {
        newState[towerId][analysisTypeRtp] = cloneDeep(
          initialState.towerAnalysisRtp.towerId.analysisTypeRtp
        );
      }

      const analysisData = newState[towerId][analysisTypeRtp];
      analysisData.loading = true;
      analysisData.loaded = false;
      return newState;
    }

    case GET_ANALYSIS_RTP_SUCCESS: {
      const { analysisTypeRtp, data, towerId } = action;
      const newState = cloneDeep(state);

      if (!newState[towerId]) {
        newState[towerId] = cloneDeep(initialState.towerAnalysisRtp.towerId);
      }

      if (!newState[towerId][analysisTypeRtp]) {
        newState[towerId][analysisTypeRtp] = cloneDeep(
          initialState.towerAnalysisRtp.towerId.analysisTypeRtp
        );
      }

      const analysisData = newState[towerId][analysisTypeRtp];
      analysisData.loading = false;
      analysisData.loaded = true;
      analysisData.data = data.outputData;
      return newState;
    }

    case GET_ANALYSIS_RTP_ERROR: {
      const { analysisTypeRtp, err, towerId } = action;
      const newState = cloneDeep(state);

      if (!newState[towerId]) {
        newState[towerId] = cloneDeep(initialState.towerAnalysisRtp.towerId);
      }

      if (!newState[towerId][analysisTypeRtp]) {
        newState[towerId][analysisTypeRtp] = cloneDeep(
          initialState.towerAnalysisRtp.towerId.analysisTypeRtp
        );
      }

      const analysisData = newState[towerId][analysisTypeRtp];
      analysisData.loading = true;
      analysisData.loaded = false;
      analysisData.data = err;
      return newState;
    }
    default: {
      return state;
    }
  }
}
