import { cloneDeep } from "lodash";
import {
    ADD_AUDIT_DATA_ERROR,
    ADD_AUDIT_DATA_STARTING,
    ADD_AUDIT_DATA_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";


export default function addAuditReducer(state = initialState.addAudit, action) {
    switch (action.type) {
        case ADD_AUDIT_DATA_STARTING: {
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_AUDIT_DATA_SUCCESS: {
            const { data } = action;
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;
            newState.newAudit = data.audit;

            return newState;
        }

        case ADD_AUDIT_DATA_ERROR: {
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.error;
            return newState;
        }

        default: {
            return state;
        }
    }
}