import { cloneDeep } from "lodash";
import { DEACTIVATE_CONNECTION_BOLTS_ERROR, DEACTIVATE_CONNECTION_BOLTS_STARTING, DEACTIVATE_CONNECTION_BOLTS_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function deactivateConnectionReducer(state = initialState.deactivateConnectionBolts, action) {
    switch (action.type) {
      case DEACTIVATE_CONNECTION_BOLTS_STARTING: {
        const { connectionId } = action;
        const newState = cloneDeep(state);
  
        if (!newState[connectionId]) {
          newState[connectionId] = cloneDeep(initialState.deactivateConnectionBolts.connectionId);
        }
  
        const connectionData = newState[connectionId];
        connectionData.saving = true;
        connectionData.saved = false;
        connectionData.error = null;
  
        return newState;
      }
  
      case DEACTIVATE_CONNECTION_BOLTS_SUCCESS: {
        const { connectionId } = action;
        const newState = cloneDeep(state);
  
        if (!newState[connectionId]) {
          newState[connectionId] = cloneDeep(initialState.deactivateConnectionBolts.connectionId);
        }
  
        const connectionData = newState[connectionId];
        connectionData.saving = false;
        connectionData.saved = true;
        connectionData.error = null;
        return newState;
      }
  
      case DEACTIVATE_CONNECTION_BOLTS_ERROR: {
        const { connectionId } = action;
        const newState = cloneDeep(state);
  
        if (!newState[connectionId]) {
          newState[connectionId] = cloneDeep(initialState.deactivateConnectionBolts.connectionId);
        }
  
        const connectionData = newState[connectionId];
        connectionData.saving = false;
        connectionData.saved = false;
        connectionData.error = action.err;
        return newState;
      }
  
      default: {
        return state;
      }
    }
  }
  