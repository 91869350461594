import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_ENTITIES_ACCESS_STARTING,
    GET_ENTITIES_ACCESS_SUCCESS,
    GET_ENTITIES_ACCESS_ERROR,
    ADD_ENTITY_ACCESS_SUCCESS, DELETE_ENTITY_ACCESS_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {

}

export default function entitiesAccessReducer(state = initialState.userAccess, action) {
    switch (action.type) {
        case GET_ENTITIES_ACCESS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            newState.error = null;
            return newState;
        }

        case GET_ENTITIES_ACCESS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];

            action.data.accessRecords.forEach((accessRecord) => {
                const recordId = accessRecord.id;
                ids.push(recordId);
                newState.map[recordId] = accessRecord;
            });

            console.log(ids);

            newState.ids = ids;
            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_ENTITIES_ACCESS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve places.';
            return newState;
        }

        case ADD_ENTITY_ACCESS_SUCCESS: {
            const newState = cloneDeep(state);
            const { accessRecord } = action.data;

            // Push new place data
            const recordId = accessRecord.id;
            newState.map[recordId] = accessRecord;
            newState.ids.push(recordId);

            // Sort by display order
            sortByOrder(newState);

            return newState;
        }

        case DELETE_ENTITY_ACCESS_SUCCESS: {
            const newState = cloneDeep(state);
            const { accessRecordId } = action;
            newState.ids = newState.ids.filter((id) => id !== accessRecordId);
            return newState;
        }

        default: {
            return state;
        }
    }
}
