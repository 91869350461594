import {EllipsisVerticalIcon, LockClosedIcon} from "@heroicons/react/24/solid";
import {Link} from "react-router-dom";
import {FaCircleUser} from "react-icons/fa6";
import React from "react";
import {getUserPicUrl} from "../../../utils/navigation";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as loginActions from "../../../actions/loginActions";
import {connect} from "react-redux";

function UserDetails({ actions, currentUser }) {
    const userFullName = currentUser.userData.fullname;
    const userEmail = currentUser.userData.email;

    const logoutUser = () => {
        actions.logoutUser(0);
    };

    if (!currentUser.loggedIn) {
        return null;
    }

    const userPic = getUserPicUrl(currentUser.userData.id);
    const userProfilePic = (
        <img
            src={userPic}
            alt={userFullName}
            className="w-10 h-10 inline rounded-full max-w-none"
        />
    );

    return (
        <div className="pr-2 pl-3 pt-3 pb-0 flex flex-row justify-between gap-2">
            <div>
                {userProfilePic}
            </div>

            <div className="grow" style={{maxWidth: '10rem', wordWrap: 'anywhere'}}>
                <p className="text-black">{userFullName}</p>
                <p className="text-xs w-full">{userEmail}</p>
            </div>

            <div className="user-details dropdown dropdown-hover dropdown-end">
                <div className="btn btn-ghost p-1">
                    <div>
                        <EllipsisVerticalIcon className="w-5 h-5 inline"/>
                    </div>
                </div>
                <ul className="p-0 text-black shadow menu dropdown-content bg-base-100 rounded-sm w-48">
                    <li className="text-sm">
                        <Link
                            to="/profile"
                            className="flex items-center p-2 text-xs font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                            <FaCircleUser className="w-4 h-4 inline mr-1"/>
                            Profile
                        </Link>
                    </li>

                    {/*<li className="text-sm">*/}
                    {/*  <a>Logout (Notifications)</a>*/}
                    {/*</li>*/}

                    <li className="text-sm">
                        <a
                            href={null}
                            onClick={logoutUser}
                            className="flex items-center p-2 text-xs font-normal rounded-lg hover:text-white hover:bg-rose-500 dark:hover:bg-rose-500"

                        >
                            <LockClosedIcon className="w-4 h-4 inline mr-1"/>
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

UserDetails.defaultProps = {
    actions: {},
    currentUser: {},
};

UserDetails.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, loginActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

