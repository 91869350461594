import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ADD_COMPANY_STARTING,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_ERROR,
} from '../../constants/actionTypes';

export default function addCompanyReducer(state = initialState.addCompany, action) {
    switch (action.type) {
        case ADD_COMPANY_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_COMPANY_SUCCESS: {
            const { data } = action;
            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newCompany = data.company;
            return newState;
        }

        case ADD_COMPANY_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}