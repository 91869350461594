import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  UPDATE_TENANT_STARTING,
  UPDATE_TENANT_SUCCESS,
  UPDATE_TENANT_ERROR,
} from '../../../constants/actionTypes';

export default function updatTenantReducer(state = initialState.updateTenant, action) {
  switch (action.type) {
    case UPDATE_TENANT_STARTING: {

      const { tenantId } = action;
      const newState = clone(state);

      if (!newState[tenantId]) {
        newState[tenantId] = clone(initialState.updateTenant.tenantId);
      }

      const updateData = newState[tenantId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;
       
      return newState;
    }

    case UPDATE_TENANT_SUCCESS: {
      const { tenantId } = action;

      const newState = clone(state);

      if (!newState[tenantId]) {
        newState[tenantId] = clone(initialState.updateTenant.tenantId);
      }
      const updateData = newState[tenantId];

      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      
      return newState;
    }

    case UPDATE_TENANT_ERROR: {
      const { tenantId } = action;

      const newState = clone(state);

      if (!newState[tenantId]) {
        newState[tenantId] = clone(initialState.updateTenant.tenantId);
      }
      const updateData = newState[tenantId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
