import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  ADD_GUSSET_END_PLATE_STARTING,
  ADD_GUSSET_END_PLATE_SUCCESS,
  ADD_GUSSET_END_PLATE_ERROR,
} from '../../../constants/actionTypes';

export default function addGussetEndPlateReducer(state = initialState.addGussetEndPlate, action) {
  switch (action.type) {
    case ADD_GUSSET_END_PLATE_STARTING: {
      const newState = clone(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case ADD_GUSSET_END_PLATE_SUCCESS: {
      const { data } = action;

      const newState = clone(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      newState.newGussetEndPlate = data.gussetEndPlate;
      return newState;
    }

    case ADD_GUSSET_END_PLATE_ERROR: {
      const newState = clone(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
