import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    DEACTIVATE_RECOMMENDATION_STARTING,
    DEACTIVATE_RECOMMENDATION_SUCCESS,
    DEACTIVATE_RECOMMENDATION_ERROR,
} from '../../../constants/actionTypes';

export default function deactivateRecommendationReducer(state = initialState.deactivateRecommendation, action) {
    switch (action.type) {
        case DEACTIVATE_RECOMMENDATION_STARTING: {
            const { recommendationId } = action;
            const newState = clone(state);

            if (!newState[recommendationId]) {
                newState[recommendationId] = clone(initialState.deactivateRecommendation.recommendationId);
            }

            const recommendationData = newState[recommendationId];
            recommendationData.saving = true;
            recommendationData.saved = false;
            recommendationData.error = null;

            return newState;
        }
        case DEACTIVATE_RECOMMENDATION_SUCCESS: {
            const { recommendationId } = action;
            const newState = clone(state);

            if (!newState[recommendationId]) {
                newState[recommendationId] = clone(initialState.deactivateRecommendation.recommendationId);
            }
            const recommendationData = newState[recommendationId];
            recommendationData.saving = false;
            recommendationData.saved = true;
            recommendationData.error = null;

            return newState;
        }
        case DEACTIVATE_RECOMMENDATION_ERROR: {
            const { recommendationId } = action;
            const newState = clone(state);

            if (!newState[recommendationId]) {
                newState[recommendationId] = clone(initialState.deactivateRecommendation.recommendationId);
            }
            const recommendationData = newState[recommendationId];
            recommendationData.saving = false;
            recommendationData.saved = false;
            recommendationData.error = action.err;

            return newState;
        }
        default: {
            return state;
        }
    }
}