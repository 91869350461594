import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ADD_ENTITY_ACCESS_STARTING,
    ADD_ENTITY_ACCESS_SUCCESS,
    ADD_ENTITY_ACCESS_ERROR,
} from '../../constants/actionTypes';

export default function addEntityAccessReducer(state = initialState.addEntityAccess, action) {
    switch (action.type) {
        case ADD_ENTITY_ACCESS_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }
        case ADD_ENTITY_ACCESS_SUCCESS: {
            const { data } = action;
            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newPlace = data.place;
            return newState;
        }

        case ADD_ENTITY_ACCESS_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}