import { cloneDeep } from 'lodash';
import {
    GET_BUTT_JOINT_CONNECTIONS_STARTING,
    GET_BUTT_JOINT_CONNECTIONS_SUCCESS,
    GET_BUTT_JOINT_CONNECTIONS_ERROR,
    ADD_BUTT_JOINT_CONNECTION_SUCCESS,
    UPDATE_BUTT_JOINT_CONNECTION_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function buttJointsReducer(state = initialState.buttJoints, action) {
    switch (action.type) {
        case GET_BUTT_JOINT_CONNECTIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_BUTT_JOINT_CONNECTIONS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.buttJoints.forEach((buttJoint) => {
                const buttJointId = buttJoint.id;
                ids.push(buttJointId);
                map[buttJointId] = buttJoint;
            });

            newState.ids = ids;
            newState.map = map;


            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_BUTT_JOINT_CONNECTIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve butt joint connection plate & bolt.';
            return newState;
        }

        case ADD_BUTT_JOINT_CONNECTION_SUCCESS: {
            const newState = cloneDeep(state);
            const { buttJoint } = action.data;
            const buttJointId = buttJoint.id;


            newState.map[buttJointId] = buttJoint;
            newState.ids.push(buttJointId);

            newState.ids.sort((butt1Id, butt2Id) => {
                const butt1 = newState.map[butt1Id];
                const butt2 = newState.map[butt2Id];
                return butt1.id - butt2.id;
            });

            return newState;
        }

        case UPDATE_BUTT_JOINT_CONNECTION_SUCCESS: {
            const newState = cloneDeep(state);
            const { buttJoint } = action.data;
            const buttJointId = buttJoint.id;
            newState.map[buttJointId] = buttJoint;
            return newState;
        }

        default: {
            return state;
        }
    }
}