import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  DEACTIVATE_BUTT_JOINT_CONNECTION_STARTING,
  DEACTIVATE_BUTT_JOINT_CONNECTION_SUCCESS,
  DEACTIVATE_BUTT_JOINT_CONNECTION_ERROR,
} from '../../../constants/actionTypes';

export default function deactivateButtJointReducer(state = initialState.deactivateButtJoint, action) {
  switch (action.type) {
    case DEACTIVATE_BUTT_JOINT_CONNECTION_STARTING: {
      const { buttJointId } = action;
      const newState = clone(state);

      if (!newState[buttJointId]) {
        newState[buttJointId] = clone(initialState.deactivateButtJoint.buttJointId);
      }

      const buttJointData = newState[buttJointId];
      buttJointData.saving = true;
      buttJointData.saved = false;
      buttJointData.error = null;

      return newState;
    }

    case DEACTIVATE_BUTT_JOINT_CONNECTION_SUCCESS: {
      const { buttJointId } = action;
      const newState = clone(state);

      if (!newState[buttJointId]) {
        newState[buttJointId] = clone(initialState.deactivateButtJoint.buttJointId);
      }

      const buttJointData = newState[buttJointId];
      buttJointData.saving = false;
      buttJointData.saved = true;
      buttJointData.error = null;
      return newState;
    }

    case DEACTIVATE_BUTT_JOINT_CONNECTION_ERROR: {
      const { buttJointId } = action;
      const newState = clone(state);

      if (!newState[buttJointId]) {
        newState[buttJointId] = clone(initialState.deactivateButtJoint.buttJointId);
      }

      const buttJointData = newState[buttJointId];
      buttJointData.saving = false;
      buttJointData.saved = false;
      buttJointData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
