import { cloneDeep } from "lodash";
import { UPDATE_PEDESTAL_DESIGN_ERROR, 
    UPDATE_PEDESTAL_DESIGN_STARTING, 
    UPDATE_PEDESTAL_DESIGN_SUCCESS 
    } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function updatePedestalDesignReducer (state = initialState.updatePedestalDetail,action){
    
    switch(action.type){
        case UPDATE_PEDESTAL_DESIGN_STARTING : {
            const {pedestalId} = action;
            const newState = cloneDeep(state);

            if(!newState[pedestalId]){
                newState[pedestalId] = cloneDeep(initialState.updatePedestalDetail.pedestalId)
            }

            const updateData = newState[pedestalId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
            
        }

        case UPDATE_PEDESTAL_DESIGN_SUCCESS: {
            const {pedestalId} = action;

            const newState = cloneDeep(state);
            if(!newState[pedestalId]){
                newState[pedestalId] = cloneDeep(initialState.updatePedestalDetail.pedestalId)
            }

            const updateData = newState[pedestalId];

            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;

            return newState;
        }

        case UPDATE_PEDESTAL_DESIGN_ERROR: {
            const {pedestalId} = action;

            const newState = cloneDeep(state);
            if(!newState[pedestalId]){
                newState[pedestalId] = cloneDeep(initialState.updatePedestalDetail.pedestalId)
            }

            const updateData = newState[pedestalId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.error ? action.error : "Failed to update pedestal design data"
        }

        default:{
            return state;
        }
    }
}