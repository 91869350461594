import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_REGIONS_ERROR,
    GET_REGIONS_STARTING,
    GET_REGIONS_SUCCESS, REGION_TREE_STATE_CHANGED,
    REGIONS_COMPANY_CHANGED,
    ADD_TOWER_COMPANY_SUCCESS, GET_REGION_TOWERS_SUCCESS,
} from '../../constants/actionTypes';

export default function regionsReducer(state = initialState.regions, action) {
    switch (action.type) {
        case GET_REGIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_REGIONS_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;

            newState.ids = action.data.placeIds;
            newState.map = action.data.places;
            newState.userAddress = action.data.userAddress;
            newState.hierarchy = action.data.mapping;
            newState.finalHierarchy = newState.hierarchy;
            newState.towerCounts = action.data.towerCounts;

            return newState;
        }

        // case REGIONS_COMPANY_CHANGED: {
        //     const newState = cloneDeep(state);
        //     newState.companyId = action.companyId;
        //
        //     const [newHierarchy, towerCounts] = getFinalHierarchy(newState);
        //     newState.towerCounts = towerCounts;
        //     newState.finalHierarchy = newHierarchy;
        //
        //     return newState;
        // }

        case REGION_TREE_STATE_CHANGED: {
            const newState = cloneDeep(state);
            const { placeId, open } = action;
            newState.treeState[placeId] = open;
            return newState;
        }

        case GET_REGIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve regions.';
            return newState;
        }

        case GET_REGION_TOWERS_SUCCESS: {
            const { placeId, data } = action;
            console.log('Inside reducer');
            console.log(placeId);
            console.log(data);
            const newState = cloneDeep(state);
            newState.towersMap[placeId] = data.towerIds;
            data.towerIds.forEach((towerId) => {
                newState.towersData[towerId] = data.towersMap[towerId];
            });
            return newState;
        }

        case ADD_TOWER_COMPANY_SUCCESS: {
            const newState = cloneDeep(state);
            const {mobileTowerId, companyId} = action;
            if (newState.towersData[mobileTowerId]) {
                newState.towersData[mobileTowerId].companyId = companyId;
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}
