import React from "react";
import initialState from "../../../store/initialState";
import {
  ADD_SERVICE_REQUEST_ERROR,
  ADD_SERVICE_REQUEST_STARTING,
  ADD_SERVICE_REQUEST_SUCCESS,
} from "../../../constants/actionTypes";
import { cloneDeep } from "lodash";

export default function addServiceRequestReducer(
  state = initialState.addServiceRequest,
  action
) {
  switch (action.type) {
    case ADD_SERVICE_REQUEST_STARTING: {
      const newState = cloneDeep(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }
    case ADD_SERVICE_REQUEST_SUCCESS: {
      const { data } = action;
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;
      newState.newServiceRequest = data.serviceRequest;

      return newState;
    }
    case ADD_SERVICE_REQUEST_ERROR: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err;
      return newState;
    }
    default: {
      return state;
    }
  }
}
