import axios from "axios";
import {
    GET_COMPANIES_STARTING,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_ERROR,

    GET_COMPANY_BY_ID_STARTING,
    GET_COMPANY_BY_ID_SUCCESS,
    GET_COMPANY_BY_ID_ERROR,

    ADD_COMPANY_STARTING,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_ERROR,

    UPDATE_COMPANY_STARTING,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_ERROR,

    ACTIVATE_COMPANY_STARTING,
    ACTIVATE_COMPANY_SUCCESS,
    ACTIVATE_COMPANY_ERROR,

    DEACTIVATE_COMPANY_STARTING,
    DEACTIVATE_COMPANY_SUCCESS,
    DEACTIVATE_COMPANY_ERROR,

    GET_INACTIVE_COMPANIES_STARTING,
    GET_INACTIVE_COMPANIES_SUCCESS,
    GET_INACTIVE_COMPANIES_ERROR,
} from "../constants/actionTypes";

import {
    GET_COMPANIES,
    GET_COMPANY_BY_ID,
    ADD_COMPANY,
    UPDATE_COMPANY,
    ACTIVATE_COMPANY,
    DEACTIVATE_COMPANY,
    GET_INACTIVE_COMPANIES,
} from '../constants/apiUrls';

export function getCompaniesStarting() {
    return { type: GET_COMPANIES_STARTING }
}

export function getCompaniesSuccess(data) {
    return { type: GET_COMPANIES_SUCCESS, data }
}

export function getCompaniesError(err) {
    return { type: GET_COMPANIES_ERROR, err }
}

export function getCompanies() {
    return (dispatch) => {
        dispatch(getCompaniesStarting());

        axios.get(GET_COMPANIES, {
            params: {
                page: 1,
                limit: 200,
            },
        }).then((response) => {
            if (response.status === 200 && response.data.success) {
                console.log('Got companies response');
                dispatch(getCompaniesSuccess(response.data));
                return;
            }
            const err = (response && response.data && response.data.error) ? response.data.error : 'Failed to Get Companies';
            dispatch(getCompaniesError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getCompaniesError(error));
        });
    };
}

export function getCompanyByIdStarting(companyId) {
    return { type: GET_COMPANY_BY_ID_STARTING, companyId };
}

export function getCompanyByIdSuccess(companyId, data) {
    return { type: GET_COMPANY_BY_ID_SUCCESS, companyId, data };
}

export function getCompanyByIdError(companyId, err) {
    return { type: GET_COMPANY_BY_ID_ERROR, companyId, err };
}

export function getCompanyById(companyId) {
    return (dispatch) => {
        dispatch(getCompanyByIdStarting(companyId));

        const url = GET_COMPANY_BY_ID.replace('{companyId}', companyId);
        axios.get(url).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getCompanyByIdSuccess(companyId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get companyId';
            dispatch(getCompanyByIdError(companyId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getCompanyByIdError(companyId, error));
        });
    };
}

export function addCompanyStarting() {
    return { type: ADD_COMPANY_STARTING };
}

export function addCompanySuccess(data) {
    return { type: ADD_COMPANY_SUCCESS, data };
}

export function addCompanyError(err) {
    return { type: ADD_COMPANY_ERROR, err }
}

export function addCompany(companyData) {
    return (dispatch) => {
        dispatch(addCompanyStarting())

        axios.post(ADD_COMPANY, companyData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addCompanySuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to add  company';
            dispatch(addCompanyError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addCompanyError(error));
        });
    };
}

export function updateCompanyStarting(companyId) {
    return { type: UPDATE_COMPANY_STARTING, companyId };
}

export function updateCompanySuccess(companyId, data) {
    return { type: UPDATE_COMPANY_SUCCESS, companyId, data };
}

export function updateCompanyError(companyId, err) {
    return { type: UPDATE_COMPANY_ERROR, companyId, err };
}

export function updateCompany(companyId, companyData) {
    return (dispatch) => {
        dispatch(updateCompanyStarting(companyId));

        const url = UPDATE_COMPANY.replace('{companyId}', companyId);
        axios.put(url, companyData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(updateCompanySuccess(companyId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to update company';
            dispatch(updateCompanyError(companyId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(updateCompanyError(companyId, error));
        });
    };
}

export function activateCompanyStarting(companyId) {
    return { type: ACTIVATE_COMPANY_STARTING, companyId };
}

export function activateCompanySuccess(companyId, data) {
    return { type: ACTIVATE_COMPANY_SUCCESS, companyId, data };
}

export function activateCompanyError(companyId, err) {
    return { type: ACTIVATE_COMPANY_ERROR, companyId, err };
}

export function activateCompany(companyId, updateData) {
    return (dispatch) => {
        dispatch(activateCompanyStarting(companyId));

        const url = ACTIVATE_COMPANY.replace('{companyId}', companyId);
        axios.patch(url, updateData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(activateCompanySuccess(companyId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to activate company';
            dispatch(activateCompanyError(companyId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(activateCompanyError(companyId, error));
        });
    };
}

export function deactivateCompanyStarting(companyId) {
    return { type: DEACTIVATE_COMPANY_STARTING, companyId };
}

export function deactivateCompanySuccess(companyId, data) {
    return { type: DEACTIVATE_COMPANY_SUCCESS, companyId, data };
}

export function deactivateCompanyError(companyId, err) {
    return { type: DEACTIVATE_COMPANY_ERROR, companyId, err };
}

export function deactivateCompany(companyId, updateData) {
    return (dispatch) => {
        dispatch(deactivateCompanyStarting(companyId));

        const url = DEACTIVATE_COMPANY.replace('{companyId}', companyId);
        axios.delete(url, { data: updateData }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(deactivateCompanySuccess(companyId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to deactivate company';
            dispatch(deactivateCompanyError(companyId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(deactivateCompanyError(companyId, error));
        });
    };
}

export function getInactiveCompaniesStarting() {
    return { type: GET_INACTIVE_COMPANIES_STARTING };
}


export function getInactiveCompaniesSuccess(data) {
    return { type: GET_INACTIVE_COMPANIES_SUCCESS, data };
}

export function getInactiveCompaniesError(err) {
    return { type: GET_INACTIVE_COMPANIES_ERROR, err };
}

export function getInactiveCompanies() {
    return (dispatch) => {
        dispatch(getInactiveCompaniesStarting());
        axios.get(GET_INACTIVE_COMPANIES).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getInactiveCompaniesSuccess(response.data));
                return;
            }
            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get companies';
            dispatch(getInactiveCompaniesError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getInactiveCompaniesError(error));
        });
    };
}
