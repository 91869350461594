import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  UPDATE_BUTT_JOINT_CONNECTION_STARTING,
  UPDATE_BUTT_JOINT_CONNECTION_SUCCESS,
  UPDATE_BUTT_JOINT_CONNECTION_ERROR,
} from '../../../constants/actionTypes';

export default function updateButtJointReducer(state = initialState.updateButtJoint, action) {
  switch (action.type) {
    case UPDATE_BUTT_JOINT_CONNECTION_STARTING: {

      const { buttJointId } = action;
      const newState = clone(state);

      if (!newState[buttJointId]) {
        newState[buttJointId] = clone(initialState.updateButtJoint.buttJointId);
      }

      const updateData = newState[buttJointId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_BUTT_JOINT_CONNECTION_SUCCESS: {
      const { buttJointId } = action;

      const newState = clone(state);

      if (!newState[buttJointId]) {
        newState[buttJointId] = clone(initialState.updateButtJoint.buttJointId);
      }
      const updateData = newState[buttJointId];

      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;

      return newState;
    }

    case UPDATE_BUTT_JOINT_CONNECTION_ERROR: {
      const { buttJointId } = action;

      const newState = clone(state);

      if (!newState[buttJointId]) {
        newState[buttJointId] = clone(initialState.updateButtJoint.buttJointId);
      }
      const updateData = newState[buttJointId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
