import { clone } from "lodash";
import { DEACTIVATE_POLE_MEMBER_ERROR, DEACTIVATE_POLE_MEMBER_STARTING, DEACTIVATE_POLE_MEMBER_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function deactivatePoleMemberReducer(state = initialState.deactivatePoleMember, action){
    switch(action.type) {
        case DEACTIVATE_POLE_MEMBER_STARTING:{
            const { memberId } = action;
            const newState = clone(state);
      
            if (!newState[memberId]) {
              newState[memberId] = clone(initialState.deactivatePoleMember.memberId);
            }
      
            const memberData = newState[memberId];
            memberData.saving = true;
            memberData.saved = false;
            memberData.error = null;

            return newState;
        }

        case DEACTIVATE_POLE_MEMBER_SUCCESS:{
            const { memberId } = action;
            const newState = clone(state)

            if (!newState[memberId]) {
              newState[memberId] = clone(initialState.deactivatePoleMember.memberId);
            }
      
            const memberData = newState[memberId];
            memberData.saving = false;
            memberData.saved = true;
            memberData.error = null;
            return newState;
        }

        case DEACTIVATE_POLE_MEMBER_ERROR: {
            const { memberId } = action;
            const newState = clone(state);
      
            if (!newState[memberId]) {
              newState[memberId] = clone(initialState.deactivatePoleMember.memberId);
            }
      
            const memberData = newState[memberId];
            memberData.saving = false;
            memberData.saved = false;
            memberData.error = action.err;
            return newState;
        }

        default: {
            return state;
          }
    }
}