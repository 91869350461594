import { clone } from 'lodash';
import initialState from '../store/initialState';
import {
    FORGOT_PASSWORD_STARTING,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
} from "../constants/actionTypes";

export default function forgotPasswordReducer(state = initialState.forgotPassword, action) {
    switch (action.type) {
        case FORGOT_PASSWORD_STARTING: {
            const newState = clone(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case FORGOT_PASSWORD_SUCCESS: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = '';
            return newState;
        }

        case FORGOT_PASSWORD_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;

            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}