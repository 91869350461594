import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  GET_RTP_SECTIONS_ERROR,
  GET_RTP_SECTIONS_STARTING,
  GET_RTP_SECTIONS_SUCCESS,
} from '../../../constants/actionTypes';

export default function poleSectionsReducer(state = initialState.poleSections, action) {
  switch (action.type) {
    case GET_RTP_SECTIONS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_RTP_SECTIONS_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.poleSections.forEach((section) => {
        const sectionId = section.id;
        ids.push(sectionId);
        map[sectionId] = section;
      });

      newState.ids = ids;
      newState.map = map;
      //newState.page = action.data.page;
      //newState.pages = action.data.pages;
      //newState.limit = action.data.limit;
      //newState.total = action.data.total;
      //newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_RTP_SECTIONS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve sections.';
      return newState;
    }

    default: {
      return state;
    }
  }
}
