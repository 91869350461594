import {useNavigate} from "react-router-dom";
import {goToPlacePage, goToTowerPage} from "../../utils/navigation";
import {FaTowerCell} from "react-icons/fa6";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import React from "react";
import {bindActionCreators} from "redux";
import * as regionsActions from "../../actions/mobileTowers/regionsActions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {forEach} from "lodash";
import {getRegionTowers} from "../../actions/mobileTowers/regionsActions";
import {FaSpinner} from "react-icons/fa";
import LoadingIcon from "../Common/Utilities/LoadingIcon";
import IndiaFlag from "../Common/Icons/Flags/India";

function RegionLeaf({actions, placeId, regions, regionTowers, level}) {
    const navigate = useNavigate();

    if (!regions) {
        return null;
    }

    const place = regions.map[placeId];
    const childPlaces = regions.finalHierarchy[placeId];
    const towerIds = regions.towersMap[placeId];
    const towerCount = regions.towerCounts[placeId];

    let isLeafOpen = false;
    if (level === 0) {
        isLeafOpen = true;
    } else {
        isLeafOpen = !!regions.treeState[placeId];
    }

    if (!place) {
        return null;
    }

    const children = [];

    if (isLeafOpen) {
        if (childPlaces && childPlaces.length > 0) {
            childPlaces.forEach((placeId) => {
                const towerCount = regions.towerCounts[placeId];
                if (towerCount) {
                    children.push(
                        <div
                            key={`place-${placeId}`}
                        >
                            <ConnectedRegionLeaf
                                placeId={placeId}
                                level={level + 1}
                            />
                        </div>
                    );
                }
            });
        }

        if (towerIds && towerIds.length > 0) {
            towerIds.forEach((towerId) => {
                const mobileTower = regions.towersData[towerId];
                children.push(
                    <div
                        key={`tower-${towerId}`}
                        className="px-1 pt-1 pb-2 ml-6 flex flex-row text-black cursor-pointer hover:bg-gray-200 rounded-md"
                        onClick={() => {
                            goToTowerPage(navigate, mobileTower);
                        }}
                    >
                        <div>
                            <FaTowerCell className="w-4 h-4 inline mr-2"/>
                        </div>
                        <div className="mt-1.5 text-sm">{mobileTower.siteIdStr}</div>
                    </div>
                );
            });
        }
    }

    let childrenPlaces;
    let btnMore = null;
    let leafIcon = null;

    if (level !== 0 && children.length > 6) {
        childrenPlaces = children.slice(0, 6);
        btnMore = (
            <div
                className=" ml-6 px-2 cursor-pointer rounded-md hover:bg-gray-200"
                onClick={() => {
                    goToPlacePage(navigate, place);
                }}
            >
                <p>... more</p>
            </div>
        );
    } else {
        childrenPlaces = children;
    }
    const isChildrenLoading = (regionTowers[placeId]) ? regionTowers[placeId].loading : false;
    if (isChildrenLoading) {
        leafIcon = (
            <LoadingIcon className="inline mr-2" color="gray-500" size={5}/>
        );
    } else if (place.level === 'country') {
        if (place.name === 'India') {
            leafIcon = (
                <IndiaFlag width={20} height={20} className="rounded-lg mr-2" />
            );
        }
    } else if (isLeafOpen) {
        leafIcon = (
            <IoIosArrowDown className="w-4 h-4 inline mr-2"/>
        );
    } else {
        leafIcon = (
            <IoIosArrowForward className="w-4 h-4 inline mr-2"/>
        );
    }

    return (
        <div style={{marginLeft: `${level * 2 * 3}px`}}>
            <div
                className="region-main px-1 py-1 flex flex-row cursor-pointer items-center hover:bg-gray-200 rounded-md"
                onClick={() => {
                    actions.regionTreeStateChanged(placeId, !isLeafOpen);

                    // If towers not fetched
                    if (!isLeafOpen) {
                        const alreadyFetched = (regionTowers[placeId]) ? regionTowers[placeId].loaded : false;
                        if (!alreadyFetched) {
                            actions.getRegionTowers(placeId, 1, 10);
                        }
                    }
                }}
            >
                <div>
                    {leafIcon}
                </div>

                <div className="flex justify-between gap-2 grow">
                    <div className="">{place.name}</div>
                </div>

                {towerCount > 0 && (
                    <div className="bg-brand rounded-full w-auto h-6 text-white text-center text-sm"
                         style={{ padding : "2px 4px", minWidth: '1.5rem'}}>
                        <div>{towerCount}</div>
                    </div>
                )}
            </div>

            <div className="region-children">
                {childrenPlaces}
                {btnMore}
            </div>
        </div>
    );
}

RegionLeaf.defaultProps = {
    actions: {},
    regions: {},
};

RegionLeaf.propTypes = {
    actions: PropTypes.object,
    regions: PropTypes.object,
};

const mapStateToProps = (state) => ({
    regions: state.regions,
    regionTowers: state.regionTowers,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Object.assign({}, regionsActions), dispatch),
});

const ConnectedRegionLeaf = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegionLeaf);

export default ConnectedRegionLeaf;
