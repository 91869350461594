import initialState from "../../../store/initialState";
import {
    ADD_POLE_CABLE_LADDER_ERROR,
    ADD_POLE_CABLE_LADDER_STARTING,
    ADD_POLE_CABLE_LADDER_SUCCESS
} from "../../../constants/actionTypes";
import cloneDeep from "lodash/cloneDeep";

export default function addPoleCableLadderReducer(state = initialState.addPoleCableLadder, action) {
    switch(action.type) {
        case ADD_POLE_CABLE_LADDER_STARTING:{
            const newState = cloneDeep(state);
            newState.saving = true;
            newState.saved = false;
            return newState;
        }

        case ADD_POLE_CABLE_LADDER_SUCCESS:{
            const { data } = action;

            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.poleCable = data.poleCable;
            return newState;
        }
        case ADD_POLE_CABLE_LADDER_ERROR:{
            const newState = cloneDeep(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.error;
            return newState;
        }
        default:{
            return state;
        }
    }

}