import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {brandName, companyName, copyrightYear} from "../../constants/appConfig";
// import * as actionsActions from '../actions/actions';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data: clientInfoData } = this.props.clientInfo;

    return (
      <div className="footer shadow-lg bg-neutral">
          <div className="container mx-auto">
            <div className="py-2 flex flex-row w-full justify-between">
              <div className="basis-1/2 flex justify-start">
                <img alt={`${brandName} Logo`} src="/img/logo.png" className="w-6 h-6 mr-2"/>

                <div className="text-center text-white flex items-center">
                  {brandName}
                </div>
              </div>

              <div className="basis-1/2 text-right text-white">
                {clientInfoData.name}, {clientInfoData.location}.
              </div>
            </div>
        </div>
      </div>
    );
  }
}

Footer.defaultProps = {
  actions: {},
  clientInfo: {},
};

Footer.propTypes = {
  actions: PropTypes.object,
  clientInfo: PropTypes.object,
};

const mapStateToProps = state => ({
  clientInfo: state.clientInfo,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
