import {cloneDeep } from "lodash";
import { ADD_FOUNDATION_FORCE_POLE_DETAILS_ERROR, 
        ADD_FOUNDATION_FORCE_POLE_DETAILS_STARTING, 
        ADD_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS 
    } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function addFoundationForcePoleReducer(state = initialState.addFoundationForcePoleDetail,action){
    switch(action.type){
        case ADD_FOUNDATION_FORCE_POLE_DETAILS_STARTING: {
            const newState = cloneDeep(state);

            newState.saving = true
            newState.saved = false
            newState.error = null;
            return newState;
        }
        case ADD_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            newState.newForcePole = action.data
            newState.saving = false
            newState.saved = true
            newState.error = null;

            return newState;
        }
        case ADD_FOUNDATION_FORCE_POLE_DETAILS_ERROR:  {
            const newState = cloneDeep(state)
            newState.saving = false
            newState.saved = false
            newState.error = action.error ? action.error : "Failed to Add Foundation data";

            return newState;
        }
        default: {
            return state;
        }
    }
}