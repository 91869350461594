import React, { useState } from "react";
import PACSAppLayout from "../../components/Common/PACSAppLayout";
import ProtectedRoute from "../../components/Common/ProtectedRoute";
import { bingMapsApiKey } from "../../constants/appConfig";
import BingMapsReact from "bingmaps-react";
import * as regionsActions from "../../actions/mobileTowers/regionsActions";
import EmptyLoader from "../../components/Common/EmptyLoader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useMatch } from "react-router";


function TowerMapView({regions}){
    let mapLat,mapLon;
    let latlong = []
    let defaultZoom = 5;
    const match = useMatch('/towers-map-view');

    console.log("match",match)
    
    const onMapLoaded = ({ map }) => {
        const { Microsoft } = window;
        console.log("map",map);
        console.log("window",window.Microsoft);
        
        // After 1 second
        setTimeout(() => {
            // Form the array of lat longs for markers
            const towers = Object.values(regions.towersData)

            towers.forEach((tower, index)=>{
                if(tower.lat !== null && tower.lon !== null){
                    mapLat = tower.lat;
                    mapLon = tower.lon;
                    latlong.push(new Microsoft.Maps.Location(mapLat, mapLon));
                }
            });

            console.log('latlon', latlong);

            // For each marker
            // add it to the map

            latlong.forEach((location) => {
                const marker1 = new Microsoft.Maps.Pushpin(location, {
                    icon: "/img/map-marker.png",
                    title: ""
                });
                
                map.current.entities.push(marker1);
                console.log("marker1",marker1)    
            });

            // map.current.setView({
            //     mapTypeId: Microsoft.Maps.MapTypeId.aerial,
            // });
        }, 1 * 1000);
    };



    const pushPins = [];
    const mapCenter = {
        latitude: 21.1610659,
        longitude: 78.9901083,
    };

    let BingMap=(
        <BingMapsReact
                onMapReady={onMapLoaded}
                bingMapsKey={bingMapsApiKey}
                pushPins={pushPins}
                viewOptions={{
                    center: mapCenter,
                    zoom: defaultZoom,
                    mapTypeId: "aerial",
                }}
    />
    )
    return(
        <ProtectedRoute>
      <PACSAppLayout
        isTowerPage={false}
        mobileTower={null}
        pageTitle={"Map View"}
        className="p-4"
      > 
      {BingMap}
      </PACSAppLayout>
    </ProtectedRoute>
    )

}

const mapStateToProps = (state) => ({
    regions: state.regions,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Object.assign({}, regionsActions),dispatch),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps) (TowerMapView)