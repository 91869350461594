import React, {useEffect, useState} from "react";
import TextInput from "../Common/Utilities/Forms/TextInput";
import { bindActionCreators } from "redux";
import * as mobileTowersActions from "../../actions/mobileTowers/mobileTowersActions";
import * as regionsActions from "../../actions/mobileTowers/regionsActions";
import { connect } from "react-redux";
import Modal from "../Common/Utilities/Modal";
import PropTypes from 'prop-types';
import AuthPasswordModal from "../Common/AuthPasswordModal";
import PlaceSelection from "../Common/Selection/PlaceSelection";
import toast from "react-hot-toast";
import {goToTowerPage} from "../../utils/navigation";
import {useNavigate} from "react-router-dom";

function CreateMobileTowerModal({ actions, addMobileTower, toggleModal }) {
    const navigate = useNavigate();
    const [siteIdStr, setSiteIdStr] = useState('');
    const [siteName, setSiteName] = useState('');
    const [placeId, setPlaceId] = useState(0);
    const [showAuthPassword, setShowAuthPassword] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const getRegionsData = () => {
        return {
            siteIdStr,
            siteName,
            placeId,
        };
    };

    const toggleAuthPasswordModal = () => {
        setShowAuthPassword(!showAuthPassword);
    };

    const buttonsConfig = [];
    let modalTitle;
    modalTitle = 'Add Tower';

    buttonsConfig.push({
        label: 'Save',
        className: 'btn-primary px-8',
        onClick: () => {
            setShowAuthPassword(true);
        },
    });

    const onSubmit = (authPassword, authComments) => {
        if (!siteIdStr) {
            toast.dismiss();
            toast.error('Tower Site ID is required.');
            toggleAuthPasswordModal();
            return;
        }

        if (!placeId) {
            toast.dismiss();
            toast.error('Region of Tower is required.');
            toggleAuthPasswordModal();
            return;
        }

        const newRegionData = getRegionsData();
        newRegionData.authPassword = authPassword;
        newRegionData.authComments = authComments;
        console.log('add new mobile tower action firing');
        actions.addMobileTower(newRegionData);
        setSaving(true);
    };

    let authPasswordModal = null;

    if (showAuthPassword) {
        let btnSaving = false;
        if (addMobileTower.saving) {
            btnSaving = true;
        }

        authPasswordModal = (
            <AuthPasswordModal
                saving={btnSaving}
                onSubmit={onSubmit}
                toggleModal={toggleAuthPasswordModal}
            />
        );
    }

    useEffect(() => {
        if (isSaving) {
            if (!addMobileTower.saving && addMobileTower.saved) {
                actions.getRegions();
                toast.dismiss();
                toast.success('New mobile tower created successfully.');
                setSaving(false);
                setShowAuthPassword(false);
                toggleModal();

                goToTowerPage(navigate, addMobileTower.newMobileTower);
                return;
            }

            if (!addMobileTower.saving && !addMobileTower.saved) {
                toast.dismiss();
                toast.error(addMobileTower.error);
                setSaving(false);
                setShowAuthPassword(false);
            }
        }
    }, [addMobileTower]);

    return (
        <Modal
            buttons={buttonsConfig}
            className="modal-lg"
            title={modalTitle}
            toggleModal={toggleModal}
            showCancel={!addMobileTower.saving}
            showCloseInHeader={!addMobileTower.saving}>
            <div className="grid grid-cols-2 gap-4">
                <TextInput
                    label="Site ID"
                    helperText="Enter Site ID of this Mobile Tower."
                    placeholder=""
                    value={siteIdStr}
                    onChange={(e) => setSiteIdStr(e.target.value)}
                />

                <TextInput
                    label="Site Name"
                    helperText="Enter the name of this Mobile Tower."
                    placeholder=''
                    value={siteName}
                    onChange={(e) => {
                        setSiteName(e.target.value);
                    }}
                />

                <div>
                    <p className="text-sm">Select Region</p>

                    <PlaceSelection
                        value={placeId}
                        level={'state'}
                        onChange={(placeId) => {
                            setPlaceId(placeId);
                        }}
                    />

                    <p className="mt-0 text-xs">Tower will be visible in the selected region in the left-side navigation tree.</p>
                </div>
            </div>
            {authPasswordModal}
        </Modal>

    )
}

CreateMobileTowerModal.defaultProps = {
    actions: {},
    addMobileTower: {},
    mobileTowerData: null,
};

CreateMobileTowerModal.propTypes = {
    actions: PropTypes.object,
    addMobileTower: PropTypes.object,
    mobileTowerData: PropTypes.object,
    toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    addMobileTower: state.addMobileTower,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, mobileTowersActions, regionsActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMobileTowerModal);
