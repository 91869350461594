import { cloneDeep } from "lodash";
import { UPDATE_UTILIZATION_ERROR, UPDATE_UTILIZATION_STARTING, UPDATE_UTILIZATION_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function updateUtilizationReducer(state= initialState.updateUtilizationDetail,action){
    switch(action.type){
        case UPDATE_UTILIZATION_STARTING:{
            const {utilizationId} = action;
            const newState = cloneDeep(state);

            if(!newState[utilizationId]){
                newState[utilizationId] = cloneDeep(initialState.updateUtilizationDetail.utilizationId)
            }

            const updateData = newState[utilizationId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }

        case UPDATE_UTILIZATION_SUCCESS : {
            const {utilizationId} = action;
            const newState = cloneDeep(state);

            if(!newState[utilizationId]){
                newState[utilizationId] = cloneDeep(initialState.updateUtilizationDetail.utilizationId)
            }

            const updateData = newState[utilizationId];

            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;

            return newState;
        }

        case UPDATE_UTILIZATION_ERROR: {
            const {utilizationId} = action;
            const newState = cloneDeep(state);

            if(!newState[utilizationId]){
                newState[utilizationId] = cloneDeep(initialState.updateUtilizationDetail.utilizationId)
            }

            const updateData = newState[utilizationId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.error ? action.error : "Failed to update Comparison Utilization data";

            return newState;
        }

        default:{
            return state;
        }
    }

}