import {
    GET_MOBILE_TOWER_BY_ID_STARTING,
    GET_MOBILE_TOWER_BY_ID_SUCCESS,
    GET_MOBILE_TOWER_BY_ID_ERROR,

    GET_MOBILE_TOWERS_STARTING,
    GET_MOBILE_TOWERS_SUCCESS,
    GET_MOBILE_TOWERS_ERROR,

    GET_INACTIVE_MOBILE_TOWERS_STARTING,
    GET_INACTIVE_MOBILE_TOWERS_SUCCESS,
    GET_INACTIVE_MOBILE_TOWERS_ERROR,

    ADD_MOBILE_TOWER_STARTING,
    ADD_MOBILE_TOWER_SUCCESS,
    ADD_MOBILE_TOWER_ERROR,

    UPDATE_MOBILE_TOWER_STARTING,
    UPDATE_MOBILE_TOWER_SUCCESS,
    UPDATE_MOBILE_TOWER_ERROR,

    DEACTIVATE_MOBILE_TOWER_STARTING,
    DEACTIVATE_MOBILE_TOWER_SUCCESS,
    DEACTIVATE_MOBILE_TOWER_ERROR,

    ACTIVATE_MOBILE_TOWER_STARTING,
    ACTIVATE_MOBILE_TOWER_SUCCESS,
    ACTIVATE_MOBILE_TOWER_ERROR,

    GET_TOWERS_SITENAME_STARTING,
    GET_TOWERS_SITENAME_SUCCESS,
    GET_TOWERS_SITENAME_ERROR,

    ADD_TOWER_SITENAME_STARTING,
    ADD_TOWER_SITENAME_SUCCESS,
    ADD_TOWER_SITENAME_ERROR
} from '../../constants/actionTypes';
import axios from '../../axios';
import {
    GET_MOBILE_TOWER_BY_ID,
    GET_ACTIVE_MOBILE_TOWERS,
    GET_INACTIVE_MOBILE_TOWERS,
    ADD_MOBILE_TOWER,
    UPDATE_MOBILE_TOWER,
    DEACTIVATE_MOBILE_TOWER,
    ACTIVATE_MOBILE_TOWER,
    GET_TOWER_SITENAMES,
    ADD_TOWER_SITENAME,
} from '../../constants/apiUrls';

export function getMobileTowerByIdStarting(mobileTowerId) {
    return { type: GET_MOBILE_TOWER_BY_ID_STARTING, mobileTowerId };
}

export function getMobileTowerByIdSuccess(mobileTowerId, data) {
    return { type: GET_MOBILE_TOWER_BY_ID_SUCCESS, mobileTowerId, data };
}

export function getMobileTowerByIdError(mobileTowerId, err) {
    return { type: GET_MOBILE_TOWER_BY_ID_ERROR, mobileTowerId, err };
}

export function getMobileTowerById(mobileTowerId) {
    return (dispatch) => {
        dispatch(getMobileTowerByIdStarting(mobileTowerId));

        const url = GET_MOBILE_TOWER_BY_ID.replace('{towerId}', mobileTowerId);
        axios.get(url).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getMobileTowerByIdSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get mobile tower details';
            dispatch(getMobileTowerByIdError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getMobileTowerByIdError(mobileTowerId, error));
        });
    };
}

export function getMobileTowersStarting() {
    return { type: GET_MOBILE_TOWERS_STARTING };
}

export function getMobileTowersSuccess(data) {
    return { type: GET_MOBILE_TOWERS_SUCCESS, data };
}

export function getMobileTowersError(err) {
    return { type: GET_MOBILE_TOWERS_ERROR, err };
}

export function getMobileTowers() {
    return (dispatch) => {
        dispatch(getMobileTowersStarting());

        axios.get(GET_ACTIVE_MOBILE_TOWERS).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getMobileTowersSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get mobileTowers';
            dispatch(getMobileTowersError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getMobileTowersError(error));
        });
    };
}

export function getInactiveMobileTowersStarting() {
    return { type: GET_INACTIVE_MOBILE_TOWERS_STARTING };
}

export function getInactiveMobileTowersSuccess(data) {
    return { type: GET_INACTIVE_MOBILE_TOWERS_SUCCESS, data };
}

export function getInactiveMobileTowersError(err) {
    return { type: GET_INACTIVE_MOBILE_TOWERS_ERROR, err };
}

export function getInactiveMobileTowers() {
    return (dispatch) => {
        dispatch(getInactiveMobileTowersStarting());

        axios.get(GET_INACTIVE_MOBILE_TOWERS).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getInactiveMobileTowersSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get mobileTowers';
            dispatch(getInactiveMobileTowersError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getInactiveMobileTowersError(error));
        });
    };
}

export function addMobileTowerStarting() {
    return { type: ADD_MOBILE_TOWER_STARTING };
}

export function addMobileTowerSuccess(data) {
    return { type: ADD_MOBILE_TOWER_SUCCESS, data };
}

export function addMobileTowerError(err) {
    return { type: ADD_MOBILE_TOWER_ERROR, err };
}

export function addMobileTower(mobileTowerData) {
    return (dispatch) => {
        dispatch(addMobileTowerStarting());

        axios.post(ADD_MOBILE_TOWER, mobileTowerData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addMobileTowerSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to create mobileTower';
            dispatch(addMobileTowerError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addMobileTowerError(error));
        });
    };
}

export function updateMobileTowerStarting(mobileTowerId) {
    return { type: UPDATE_MOBILE_TOWER_STARTING, mobileTowerId };
}

export function updateMobileTowerSuccess(mobileTowerId, data) {
    return { type: UPDATE_MOBILE_TOWER_SUCCESS, mobileTowerId, data };
}

export function updateMobileTowerError(mobileTowerId, err) {
    return { type: UPDATE_MOBILE_TOWER_ERROR, mobileTowerId, err };
}

export function updateMobileTower(mobileTowerId, mobileTowerData) {
    return (dispatch) => {
        dispatch(updateMobileTowerStarting(mobileTowerId));

        const url = UPDATE_MOBILE_TOWER.replace('{towerId}', mobileTowerId);
        axios.put(url, mobileTowerData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(updateMobileTowerSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to update mobileTower';
            dispatch(updateMobileTowerError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(updateMobileTowerError(mobileTowerId, error));
        });
    };
}

export function deactivateMobileTowerStarting(mobileTowerId) {
    return { type: DEACTIVATE_MOBILE_TOWER_STARTING, mobileTowerId };
}

export function deactivateMobileTowerSuccess(mobileTowerId, data) {
    return { type: DEACTIVATE_MOBILE_TOWER_SUCCESS, mobileTowerId, data };
}

export function deactivateMobileTowerError(mobileTowerId, err) {
    return { type: DEACTIVATE_MOBILE_TOWER_ERROR, mobileTowerId, err };
}

export function deactivateMobileTower(mobileTowerId, updateData) {
    return (dispatch) => {
        dispatch(deactivateMobileTowerStarting(mobileTowerId));

        const url = DEACTIVATE_MOBILE_TOWER.replace('{towerId}', mobileTowerId);
        axios.delete(url, { data: updateData }).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(deactivateMobileTowerSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to deactivate mobileTower';
            dispatch(deactivateMobileTowerError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(deactivateMobileTowerError(mobileTowerId, error));
        });
    };
}

export function activateMobileTowerStarting(mobileTowerId) {
    return { type: ACTIVATE_MOBILE_TOWER_STARTING, mobileTowerId };
}

export function activateMobileTowerSuccess(mobileTowerId, data) {
    return { type: ACTIVATE_MOBILE_TOWER_SUCCESS, mobileTowerId, data };
}

export function activateMobileTowerError(mobileTowerId, err) {
    return { type: ACTIVATE_MOBILE_TOWER_ERROR, mobileTowerId, err };
}

export function activateMobileTower(mobileTowerId, updateData) {
    return (dispatch) => {
        dispatch(activateMobileTowerStarting(mobileTowerId));

        const url = ACTIVATE_MOBILE_TOWER.replace('{towerId}', mobileTowerId);
        axios.patch(url, updateData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(activateMobileTowerSuccess(mobileTowerId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to activate mobileTower';
            dispatch(activateMobileTowerError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(activateMobileTowerError(mobileTowerId, error));
        });
    };
}

//search by query

export function getRegionsSuccessByQuery(query, data) {
    return { type: GET_TOWERS_SITENAME_SUCCESS, query, data };
}
export function getRegionsErrorByQuery(query, err) {
    return { type: GET_TOWERS_SITENAME_ERROR, query, err };
}
export function getSearchSuggestionsByQuery(searchQuery) {
    return dispatch => (
        axios.get(GET_TOWER_SITENAMES, {
            params: {
                q: searchQuery,
            }
        }).then((response) => {
            if (response && response.status === 200) {
                //   dispatch(getRegionsSuccessByQuery(searchQuery, response.data));
                const namesArray = Object.values(response.data.placeNames).map(obj => obj.name);
                dispatch(getRegionsSuccessByQuery(searchQuery, namesArray));
                // // Logging the names array
                console.log("Names searched:", namesArray);
                console.log("searchQuery", searchQuery)
                console.log("response", response.data)

                return;
            }
            // if (response && response.status === 200) {
            //     dispatch(getRegionsSuccessByQuery(searchQuery, response.data));

            //     // // Extracting names from the nested objects inside the 'places' property
            //     // const namesArray = Object.values(response.data.places).map(obj => obj.name);
            //     // // Dispatching names array to the store or wherever it needs to be used
            //     // dispatch(getRegionsSuccessByQuery(searchQuery, namesArray));
            //     // // Logging the names array
            //     // console.log("Names searched:", namesArray);
            //     console.log("searchQuery",searchQuery)
            //     console.log("response",response.data)

            //     return;
            // }

            // Handle error condition if necessary



            const err = (response && response.data && response.data.error) ?
                new Error(response.data.error) : new Error('Failed to fetch suggestions for search query');
            dispatch(getRegionsErrorByQuery(searchQuery, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            dispatch(getRegionsErrorByQuery(searchQuery, error));
        }));
}
