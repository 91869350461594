import { cloneDeep } from 'lodash';
import {
    GET_GUSSET_END_PLATES_STARTING,
    GET_GUSSET_END_PLATES_SUCCESS,
    GET_GUSSET_END_PLATES_ERROR,
    ADD_GUSSET_END_PLATE_SUCCESS,
    UPDATE_GUSSET_END_PLATE_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function gussetEndPlatesReducer(state = initialState.gussetEndPlates, action) {
    switch (action.type) {
        case GET_GUSSET_END_PLATES_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_GUSSET_END_PLATES_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.gussetEndPlates.forEach((gussetEndPlate) => {
                const gussetEndPlateId = gussetEndPlate.id;
                ids.push(gussetEndPlateId);
                map[gussetEndPlateId] = gussetEndPlate;
            });

            newState.ids = ids;
            newState.map = map;


            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_GUSSET_END_PLATES_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve gussetEndPlates.';
            return newState;
        }

        case ADD_GUSSET_END_PLATE_SUCCESS: {
            const newState = cloneDeep(state);
            const { gussetEndPlate } = action.data;
            const gussetEndPlateId = gussetEndPlate.id;


            newState.map[gussetEndPlateId] = gussetEndPlate;
            newState.ids.push(gussetEndPlateId);

            newState.ids.sort((ten1Id, ten2Id) => {
                const ten1 = newState.map[ten1Id];
                const ten2 = newState.map[ten2Id];
                return ten1.id - ten2.id;
            });

            return newState;
        }

        case UPDATE_GUSSET_END_PLATE_SUCCESS: {
            const newState = cloneDeep(state);
            const { gussetEndPlate } = action.data;
            const gussetEndPlateId = gussetEndPlate.id;
            newState.map[gussetEndPlateId] = gussetEndPlate;
            return newState;
        }

        default: {
            return state;
        }
    }
}