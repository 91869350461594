import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  GET_USERS_ERROR,
  GET_USERS_STARTING,
  GET_USERS_SUCCESS,

  ADD_USER_SUCCESS,
  UPDATE_USER_DETAILS_SUCCESS,
} from '../../constants/actionTypes';

export default function usersReducer(state = initialState.users, action) {
  switch (action.type) {
    case GET_USERS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_USERS_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];
      const map = {};

      action.data.users.forEach((user) => {
        const userId = user.id;
        ids.push(userId);
        map[userId] = user;
      });

      newState.ids = ids;
      newState.map = map;
      newState.page = action.data.page;
      newState.pages = action.data.pages;
      newState.limit = action.data.limit;
      newState.total = action.data.total;
      newState.isLastPage = newState.page >= newState.pages;
      newState.loading = false;
      newState.loaded = true;

      return newState;
    }

    case GET_USERS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err : 'Failed to retrieve users.';
      return newState;
    }

    case ADD_USER_SUCCESS: {
      const newState = cloneDeep(state);
      const { user } = action.data;
      const userId = user.id;

      // Push new location data
      newState.map[userId] = user;
      newState.ids.push(userId);

      // Sort by display order
      newState.ids.sort((loc1Id, loc2Id) => {
        const loc1 = newState.map[loc1Id];
        const loc2 = newState.map[loc2Id];
        return loc1.id - loc2.id;
      });

      return newState;
    }

    case UPDATE_USER_DETAILS_SUCCESS: {
      const newState = cloneDeep(state);
      const { user } = action.data;
      const userId = user.id;
      newState.map[userId] = user;
      return newState;
    }

    default: {
      return state;
    }
  }
}
