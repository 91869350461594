import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_TOWER_DESIGN_FILES_STARTING,
    GET_TOWER_DESIGN_FILES_SUCCESS,
    GET_TOWER_DESIGN_FILES_ERROR, DESIGN_FILES_TREE_STATE_CHANGED,
} from '../../constants/actionTypes';

export default function towerDesignFilesReducer(state = initialState.towerDesignFiles, action) {
    switch (action.type) {
        case GET_TOWER_DESIGN_FILES_STARTING: {
            const { designId } = action;
            const newState = clone(state);
            if (!newState[designId]) {
                newState[designId] = clone(initialState.towerDesignFiles.designId);
            }

            const designData = newState[designId];
            designData.loading = true;
            designData.loaded = false;
            designData.error = null;

            return newState;
        }

        case GET_TOWER_DESIGN_FILES_SUCCESS: {
            const { designId } = action;
            const newState = clone(state);
            if (!newState[designId]) {
                newState[designId] = clone(initialState.towerDesignFiles.designId);
            }

            const designData = newState[designId];
            designData.files = action.data.files;
            designData.loading = false;
            designData.loaded = true;
            designData.error = null;
            return newState;
        }

        case GET_TOWER_DESIGN_FILES_ERROR: {
            const { designId } = action;
            const newState = clone(state);
            if (!newState[designId]) {
                newState[designId] = clone(initialState.towerDesignFiles.designId);
            }

            const designData = newState[designId];
            designData.loading = false;
            designData.loaded = false;
            designData.error = action.err;
            return newState;
        }

        case DESIGN_FILES_TREE_STATE_CHANGED: {
            const { open, designId, filePath } = action;
            const newState = clone(state);
            if (!newState[designId]) {
                newState[designId] = clone(initialState.towerDesignFiles.designId);
            }

            const designData = newState[designId];
            designData.treeState[filePath] = open;
            return newState;
        }

        default: {
            return state;
        }
    }
}
