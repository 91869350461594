import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
    UPDATE_MATERIAL_SPECIFICATION_STARTING,
    UPDATE_MATERIAL_SPECIFICATION_SUCCESS,
    UPDATE_MATERIAL_SPECIFICATION_ERROR,
} from '../../../constants/actionTypes';

export default function updateMaterialSpecificationReducer(state = initialState.updateMaterialSpecification, action) {
    switch (action.type) {
        case UPDATE_MATERIAL_SPECIFICATION_STARTING: {

            const { materialSpecificationId } = action;
            const newState = clone(state);

            if (!newState[materialSpecificationId]) {
                newState[materialSpecificationId] = clone(initialState.updateMaterialSpecification.materialSpecificationId);
            }

            const updateData = newState[materialSpecificationId];
            updateData.saving = true;
            updateData.saved = false;
            updateData.error = null;

            return newState;
        }

        case UPDATE_MATERIAL_SPECIFICATION_SUCCESS: {
            const { materialSpecificationId } = action;

            const newState = clone(state);

            if (!newState[materialSpecificationId]) {
                newState[materialSpecificationId] = clone(initialState.updateMaterialSpecification.materialSpecificationId);
            }
            const updateData = newState[materialSpecificationId];

            updateData.saving = false;
            updateData.saved = true;
            updateData.error = null;

            return newState;
        }

        case UPDATE_MATERIAL_SPECIFICATION_ERROR: {
            const { materialSpecificationId } = action;

            const newState = clone(state);

            if (!newState[materialSpecificationId]) {
                newState[materialSpecificationId] = clone(initialState.updateMaterialSpecification.materialSpecificationId);
            }
            const updateData = newState[materialSpecificationId];
            updateData.saving = false;
            updateData.saved = false;
            updateData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
