import clone from 'lodash/cloneDeep';
import {
  ACTIVATE_GUSSET_END_PLATE_STARTING,
  ACTIVATE_GUSSET_END_PLATE_SUCCESS,
  ACTIVATE_GUSSET_END_PLATE_ERROR,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function activateGussetEndPlateReducer(state = initialState.activateGussetEndPlate, action) {
  switch (action.type) {
    case ACTIVATE_GUSSET_END_PLATE_STARTING: {
      const { gussetEndPlateId } = action;
      const newState = clone(state);

      if (!newState[gussetEndPlateId]) {
        newState[gussetEndPlateId] = clone(initialState.activateGussetEndPlate.gussetEndPlateId);
      }

      const gussetEndPlateData = newState[gussetEndPlateId];
      gussetEndPlateData.saving = true;
      gussetEndPlateData.saved = false;
      gussetEndPlateData.error = null;

      return newState;
    }

    case ACTIVATE_GUSSET_END_PLATE_SUCCESS: {
      const { gussetEndPlateId } = action;
      const newState = clone(state);

      if (!newState[gussetEndPlateId]) {
        newState[gussetEndPlateId] = clone(initialState.activateGussetEndPlate.gussetEndPlateId);
      }

      const gussetEndPlateData = newState[gussetEndPlateId];
      gussetEndPlateData.saving = false;
      gussetEndPlateData.saved = true;
      gussetEndPlateData.error = null;
      return newState;
    }

    case ACTIVATE_GUSSET_END_PLATE_ERROR: {
      const { gussetEndPlateId } = action;
      const newState = clone(state);

      if (!newState[gussetEndPlateId]) {
        newState[gussetEndPlateId] = clone(initialState.activateGussetEndPlate.gussetEndPlateId);
      }

      const gussetEndPlateData = newState[gussetEndPlateId];
      gussetEndPlateData.saving = false;
      gussetEndPlateData.saved = false;
      gussetEndPlateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
