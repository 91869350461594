import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    UPDATE_PLACE_STARTING,
    UPDATE_PLACE_SUCCESS,
    UPDATE_PLACE_ERROR,
} from '../../constants/actionTypes';

export default function updatePlaceReducer(state=initialState.updatePlace,action){
switch(action.type){
    case UPDATE_PLACE_STARTING:{
        const {placeId}=action;
        const newState=clone(state);
        if(!newState[placeId]){
            newState[placeId]=clone(initialState.updatePlace.placeId);

        }
        const updateData=newState[placeId];
        updateData.saving=true;
        updateData.saved=false;
        updateData.error=null;

        return newState;
    }
    case UPDATE_PLACE_SUCCESS:{
        const {placeId} = action;
        const newState=clone(state);
        if(!newState[placeId]){
            newState[placeId]=clone(initialState.updatePlace.placeId);

        }

        const updateData=newState[placeId];
        updateData.saving=false;
        updateData.saved=true;
        updateData.error=null;
        return newState;
    }

    case UPDATE_PLACE_ERROR:{
        const {placeId}= action;
        const newState=clone(state);

        if(!newState[placeId]){
            newState[placeId]=clone(initialState.updatePlace.placeId);

        }

        const updateData=newState[placeId];
        updateData.saving=false;
        updateData.saved=false;
        updateData.error=action.err;
        return newState;
    }
    default:{
        return state;
    }
}
}