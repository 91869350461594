import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ACTIVATE_COMPANY_STARTING,
    ACTIVATE_COMPANY_SUCCESS,
    ACTIVATE_COMPANY_ERROR,
} from '../../constants/actionTypes';

export default function activateCompanyReducer(state = initialState.activateCompany, action) {
    switch (action.type) {
        case ACTIVATE_COMPANY_STARTING: {
            const { companyId } = action;
            const newState = clone(state);

            if (!newState[companyId]) {
                newState[companyId] = clone(initialState.activateCompany.companyId);
            }

            const companyData = newState[companyId];
            companyData.saving = true;
            companyData.saved = false;
            companyData.error = null;

            return newState;
        }

        case ACTIVATE_COMPANY_SUCCESS: {
            const { companyId } = action;
            const newState = clone(state);

            if (!newState[companyId]) {
                newState[companyId] = clone(initialState.activateCompany.companyId);
            }

            const companyData = newState[companyId];
            companyData.saving = false;
            companyData.saved = true;
            companyData.error = null;
            return newState;
        }

        case ACTIVATE_COMPANY_ERROR: {
            const { companyId } = action;
            const newState = clone(state);

            if (!newState[companyId]) {
                newState[companyId] = clone(initialState.activateCompany.companyId);
            }

            const companyData = newState[companyId];
            companyData.saving = false;
            companyData.saved = false;
            companyData.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}
