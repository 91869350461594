import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_REQUEST_NOTIFICATIONS_SUCCESS,
} from '../../constants/actionTypes';

export default function toastNotificationReducer(state = initialState.toastNotification, action) {
    switch (action.type) {
        case GET_REQUEST_NOTIFICATIONS_SUCCESS: {
            if (!action.data.notifications || !action.data.notifications.length) {
                return state;
            }

            const notification = action.data.notifications[0];

            if (notification.id === state.id) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.id = notification.id;
            newState.data = notification;
            return newState;
        }

        default: {
            return state;
        }
    }
}
