import React, {useState, useEffect} from 'react';
import PropTypes, {oneOfType} from 'prop-types';

function SelectBox({
                       className, inputClassName,
                       label, labelTopRight, helperText, hasError,
                       value, onChange, disabled,
                       options, ...moreProps
                   }) {
    const classes = `form-control w-full ${className}`;
    const inputClasses = `select select-bordered w-full ${inputClassName}`;

    let labelTopRight1 = null, helperLabel = null;
    if (labelTopRight) {
        labelTopRight1 = (
            <span className="label-text-alt">{labelTopRight}</span>
        );
    }

    if (helperText) {
        helperLabel = (
            <label className="label">
                <span className="label-text-alt">{helperText}</span>
            </label>
        );
    }

    let optionsArr = [];
    options.forEach((option, index) => {
        optionsArr.push((
            <option value={option.value} disabled={!!option.disabled} key={index}>
                {option.label}
            </option>
        ));
    });

    let inputField = null;

    inputField = (
        <select
            disabled={disabled}
            className={inputClasses}
            value={value}
            onChange={onChange}
            {...moreProps}
        >
            {optionsArr}
        </select>
    );

    let topLabel = null;
    if (label || labelTopRight1) {
        topLabel = (
            <label className="label">
                <span className="label-text">{label}</span>
                {labelTopRight1}
            </label>
        );
    }

    return (
        <div className={classes}>
            {topLabel}

            {inputField}

            {helperLabel}
        </div>
    );
}

SelectBox.defaultProps = {
    className: '',
    disabled: false,
    helperText: '',
    hasError: false,
    inputClassName: '',
    label: 'Label',
    labelTopRight: '',
    options: [],
    type: 'text',
    value: '',
};

SelectBox.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    hasError: PropTypes.bool,
    inputClassName: PropTypes.string,
    label: PropTypes.string,
    labelTopRight: PropTypes.string,
    options: PropTypes.array,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
};

export default SelectBox;
