import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    ADD_PLACE_STARTING,
    ADD_PLACE_SUCCESS,
    ADD_PLACE_ERROR,
} from '../../constants/actionTypes';

export default function addPlaceReducer(state=initialState.addPlace,action){
    switch(action.type){
        case ADD_PLACE_STARTING:{
            const newState=clone(state);
            newState.saving=true;
            newState.saved=false;
            return newState;
        }
        case ADD_PLACE_SUCCESS:{
            const {data} =action;
            const newState=clone(state);
            newState.saving = false;
            newState.saved = true;
            newState.error = null;

            newState.newPlace = data.place;
            return newState;
        }

        case ADD_PLACE_ERROR: {
            const newState = clone(state);
            newState.saving = false;
            newState.saved = false;
            newState.error = action.err;
            return newState;
        }

        default: {
            return state;
        }
    }
}