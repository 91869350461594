import React, { useEffect, lazy, Suspense } from "react";
import PropTypes from 'prop-types';
import { Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import AOS from "aos";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as usersActions from './actions/usersActions';
import * as clientInfoActions from './actions/clientInfoActions';
import ProtectedRoute from './components/Common/ProtectedRoute';
import EmptyLoader from "./components/Common/EmptyLoader";
import { pdfjs } from 'react-pdf';
import TowerMapView from "./pages/MobileTowers/TowerMapView";

const LoginPage = lazy(() => import('./pages/Auth/LoginPage'));
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));
const ManageUsersPage = lazy(() => import('./pages/Admin/UsersPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const AuditTrailLogReport = lazy(() => import("./pages/Reports/AuditTrailLog"));
const ManageRolesPage = lazy(() => import("./pages/Admin/RolesPage"));
const FileUploadPage = lazy(() => import("./pages/FileUploadPage"));
const PortfolioAnalysisPage = lazy(() => import("./pages/MobileTowers/PortfolioAnalysisPage"));
const ManageClientInfoPage = lazy(() => import("./pages/Admin/ClientInfoPage"));
const ForgotPasswordPage = lazy(() => import('./pages/Auth/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import("./pages/Auth/ResetPasswordPage"));
const TowerPage = lazy(() => import("./pages/MobileTowers/TowerPage"));
const TenancyAnalysisPage = lazy(() => import("./pages/MobileTowers/TenancyAnalysisPage"));
const ChartsPage = lazy(() => import("./pages/ChartsPage"));
const ManagePlacesPage = lazy(() => import("./pages/ManagePlacesPage"));
const ManageCompaniesPage = lazy(() => import("./pages/ManageCompaniesPage"));
const ManageDesignPage = lazy(() => import ("./pages/ManageDesignsPage") );
const BulkImportPage = lazy(() => import ("./pages/MobileTowers/BulkImportPage") );
const ServiceRequestsPage = lazy (() => import ("./pages/ServiceRequests/ServiceRequestsPage"))
const ServiceRequestsSummaryPage = lazy (() => import ("./pages/ServiceRequests/ServiceRequestsSummaryPage"))

const TowersDataReport = lazy(() => import ("./pages/Reports/TowersDataReport"));
const PlacePage = lazy(() => import ("./pages/MobileTowers/PlacePage"))
const MapViewPage = lazy(() =>import ("./pages/MobileTowers/TowerMapView"))

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

class App extends React.Component {
  componentDidMount() {
    this.readLocalStorage();

    const { clientInfo } = this.props;
    if (!clientInfo.loaded) {
      this.props.actions.getClientInfo();
    }

    AOS.init({
      duration: 200,
    });
  }

  readLocalStorage() {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    let tokenJson = null;
    let userJson = null;

    if (token) {
      try {
        tokenJson = JSON.parse(token);
      } catch (err) {
        console.error(err);
      }
    }

    if (user) {
      try {
        userJson = JSON.parse(user);
      } catch (err) {
        console.error(err);
      }
    }

    if (userJson && tokenJson) {
      this.props.actions.setUserData(userJson, tokenJson);
      this.props.actions.getUserInfo();
    } else {
      this.props.actions.setUserDataUnavailable();
    }
  }

  render() {
    return (
      <div className="app-container p-0">
        <div>
          <Toaster
            containerClassName="toast-container"
            position="bottom-center"
            reverseOrder={false}
            containerStyle={{
              bottom: 100,
            }}
            toastOptions={{
              className: '',
              duration: 5000,
              style: {
                maxWidth: '840px',
              },
            }}
          />
        </div>

        <Suspense fallback={<EmptyLoader />}>
          <Routes>
            <Route exact path="/" element={<ServiceRequestsPage/>}/>

            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/login/auto/:userId" element={<LoginPage />} />
            <Route exact path="/portfolio/analysis" element={<PortfolioAnalysisPage />} />
            <Route exact path="/reports" element={<ReportsPage />} />
            <Route exact path="/reports/pdf/audit_trail_log" element={<AuditTrailLogReport />} />

            <Route exact path="/manage/roles" element={<ManageRolesPage />} />
            <Route exact path="/manage/users" element={<ManageUsersPage />} />
            <Route excat path="/manage/designs" element={<ManageDesignPage/>} />
            <Route exact path="/manage/client-info" element={<ManageClientInfoPage />} />
            <Route exact path="/files/upload" element={<FileUploadPage />} />
            <Route exact path="/profile" element={<UserProfilePage />} />
            <Route exact path="/towers/:towerId/:siteIdStr" element={<TowerPage />} />
            <Route exact path="/towers/:towerId/:siteIdStr/:showTab" element={<TowerPage />} />
            <Route exact path="/towers/bulk_import" element={<BulkImportPage />} />
            <Route exact path="/service-requests" element={<ServiceRequestsPage/>}/>
            <Route exact path="/service-requests/circle-wise" element={<ServiceRequestsSummaryPage/>}/>

            <Route exact path="/page/mobiletowersportfoliopage" element={<PortfolioAnalysisPage />} />
            <Route exact path="/page/mobiletowerstenancypage" element={<TenancyAnalysisPage />} />

            <Route exact path="/forgot_password" element={<ForgotPasswordPage />} />
            <Route exact path="/reset_password" element={<ResetPasswordPage />} />
            <Route exact path="/charts" element={<ChartsPage />} />
            <Route exact path="/manage/places" element={<ManagePlacesPage />} />
            <Route exact path="/manage/customers" element={<ManageCompaniesPage />} />
            <Route exact path="/reports/pdf/tower_data" element={<TowersDataReport />} />
            <Route exact path="/places/:placeId/:name" element={<PlacePage/>}/>
            <Route exact path="/towers-map-view" element={<TowerMapView/>}/>

            <Route default component={NotFoundPage} />
          </Routes>
        </Suspense>
      </div>
    );
  }
}

App.defaultProps = {
  actions: {},
  clientInfo: {},
  currentUser: {},
  socket: {},
};

App.propTypes = {
  actions: PropTypes.object,
  clientInfo: PropTypes.object,
  currentUser: PropTypes.object,
  socket: PropTypes.object,
};

const mapStateToProps = state => ({
  clientInfo: state.clientInfo,
  currentUser: state.currentUser,
  socket: state.socket,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, usersActions, clientInfoActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
