import { cloneDeep } from "lodash";
import {
  ADD_CONNECTION_BOLTS_ERROR,
  ADD_CONNECTION_BOLTS_STARTING,
  ADD_CONNECTION_BOLTS_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function addconnectionReducer(
  state = initialState.addConnectionBolts,
  action
) {
  switch (action.type) {
    case ADD_CONNECTION_BOLTS_STARTING: {
      const newState = cloneDeep(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case ADD_CONNECTION_BOLTS_SUCCESS: {
      const { data } = action;
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      newState.newConnectionBolts = data.connectionBolts;
      return newState;
    }

    case ADD_CONNECTION_BOLTS_ERROR: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
