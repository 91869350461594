import clone from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
  UPDATE_USER_DETAILS_STARTING,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_ERROR,
} from '../../constants/actionTypes';

export default function updateUserReducer(state = initialState.updateUser, action) {
  switch (action.type) {
    case UPDATE_USER_DETAILS_STARTING: {
      const {userId} = action;
      const newState = clone(state);

      if (!newState[userId]) {
        newState[userId] = clone(initialState.updateUser.userId);
      }

      const updateData = newState[userId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_USER_DETAILS_SUCCESS: {
      const {userId} = action;
      const newState = clone(state);

      if (!newState[userId]) {
        newState[userId] = clone(initialState.updateUser.userId);
      }

      const updateData = newState[userId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_USER_DETAILS_ERROR: {
      const {userId} = action;
      const newState = clone(state);

      if (!newState[userId]) {
        newState[userId] = clone(initialState.updateUser.userId);
      }

      const updateData = newState[userId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
