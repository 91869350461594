import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Footer from './Footer';
import PACSNavigation from "./Header/PACSNavigation";
import PACSHeader from "./Header/PACSHeader";
import classNames from "classnames";
import CreateMobileTowerModal from "../MobileTowers/CreateMobileTowerModal";

function PACSAppLayout({
                           children,
                           className,
                           pageTitle,
                           isLongPage,
                           isTowerPage,
                           mobileTower,
                           toggleSideNavVisibility
                       }) {
    const [showSideNav, setShowSideNav] = useState(true);
    const [showCreateModal, setCreateModal] = useState(false);

    const toggleSideNav = () => {
        setShowSideNav(!showSideNav);
    };

    const toggleCreateModal = () => {
        setCreateModal(!showCreateModal);
    };

    let createModal = null;

    if (showCreateModal) {
        createModal = (
            <CreateMobileTowerModal
                toggleModal={toggleCreateModal}
            />
        );
    }

    const sideNavigation = useMemo(() => {
        if (showSideNav) {
            return (
                <PACSNavigation
                    toggleCreateModal={toggleCreateModal}
                />
            );
        }

        return null;
    }, [showSideNav]);

    return (
        <div className={
            classNames({
                "flex flex-col bg-gray-200 w-screen h-auto pacs-app-layout": true,
                'side-nav-open': showSideNav,
            })
        }>
            <div className="flex flex-row">
                <div className={classNames({
                    'side-navigation': true,
                    'transition-all duration-500 ease-in-out transform': true,
                })}>
                    {sideNavigation}
                </div>


                <div className="app-container transition-all duration-500 ease-in-out transform">
                    <PACSHeader
                        pageTitle={pageTitle}
                        isTowerPage={isTowerPage}
                        mobileTower={mobileTower}
                        toggleSideNav={toggleSideNav}
                    />

                    <div className={`app-content ${className}`}>
                        {children}
                    </div>
                </div>
            </div>

            {createModal}

            {/*<Footer/>*/}
        </div>
    );

}

PACSAppLayout.defaultProps = {
    centerAlign: true,
    children: null,
    className: '',
    isLongPage: false,
    pageTitle: '',
};

PACSAppLayout.propTypes = {
    centerAlign: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
    isLongPage: PropTypes.bool,
    pageTitle: PropTypes.string,
};

export default PACSAppLayout;
