import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Autosuggest from "react-autosuggest";
import {GET_PLACES} from "../../../constants/apiUrls";
import axios from "../../../axios";

function PlaceSelection({ className, label, level, onChange, searchQueryInitial, countryId, stateId, value }) {
    const [searchQuery, setSearchQuery] = useState(searchQueryInitial);
    const [suggestions, setSuggestions] = useState([]);

    const onSuggestionsFetchRequested = async (data) => {
        try {
            const response = await axios.get(GET_PLACES, {
                params: {
                    searchQuery: data.value,
                    level,
                    page: 1,
                    limit: 10,
                    countryId,
                    stateId,
                },
            });

            if (response.status === 200 && response.data.success) {
                const { searchQuery, places } = response.data;
                // console.log("places",places);
                if (searchQuery === data.value) {
                    // console.log('suggestions set!!!');
                    setSuggestions(places);
                }
            }
        } catch (err) {

        }
    };

    useEffect(() => {
        setSearchQuery(searchQueryInitial);
    }, [searchQueryInitial]);

    const onSuggestionsClearRequested = () => {
        // onChange(0, null);
        // setSearchQuery('');
    };
    
    const getSuggestionValue = (suggestion) => suggestion;
    const renderSuggestion = (place) => {
        return (
            <div>
                {place.name}
            </div>
        )
    };

    let placeholder;
    if (level === 'country') {
        placeholder =  'Search Countries';
    } else if (level === 'state') {
        placeholder =  'Search States';
    } else if (level === 'city') {
        placeholder =  'Search Cities';
    } else {
        placeholder =  'Search Places';
    }

    const inputProps = {
        value: searchQuery,
        placeholder,
        onChange: (e) => {
            // console.log('Changed', e);
            if (e.target && e.target.value !== undefined) {
                setSearchQuery(e.target.value);
            }
        },
    };

    const onSuggestionSelected = (event, { suggestionValue }) => {
        // console.log('Suggestion selected', suggestionValue);
        onChange(suggestionValue.id, suggestionValue);
        setSearchQuery(suggestionValue.name);
    };

    return (
        <div className={`text-black flex-row ${className}`}>
            <Autosuggest
                id="searchBox"
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
            />
        </div>
    );
}

PlaceSelection.defaultProps = {
    className: 'py-2 w-72',
    countryId: 0,
    searchQueryInitial: '',
    stateId: 0,
};

PlaceSelection.propTypes = {
    className: PropTypes.string,
    countryId: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    searchQueryInitial: PropTypes.string,
    stateId: PropTypes.number,
    value: PropTypes.number,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceSelection);
