import { clone } from "lodash";
import {
  UPDATE_POLE_MEMBER_ERROR,
  UPDATE_POLE_MEMBER_STARTING,
  UPDATE_POLE_MEMBER_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function updatePoleMemberReducer(
  state = initialState.updatePoleMember,
  action
) {
  switch (action.type) {
    case UPDATE_POLE_MEMBER_STARTING: {
      const { memberId } = action;
      const newState = clone(state);

      if (!newState[memberId]) {
        newState[memberId] = clone(initialState.updatePoleMember.memberId);
      }

      const updateData = newState[memberId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_POLE_MEMBER_SUCCESS: {
      const { memberId } = action;

      const newState = clone(state);

      if (!newState[memberId]) {
        newState[memberId] = clone(initialState.updatePoleMember.memberId);
      }
      const updateData = newState[memberId];

      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;

      return newState;
    }

    case UPDATE_POLE_MEMBER_ERROR: {
      const { memberId } = action;

      const newState = clone(state);

      if (!newState[memberId]) {
        newState[memberId] = clone(initialState.updatePoleMember.memberId);
      }
      const updateData = newState[memberId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }
    default: {
      return state;
    }
  }
}
