import {cloneDeep} from "lodash";
import {
    ADD_POLE_MEMBER_SUCCESS,
    DEACTIVATE_POLE_MEMBER_SUCCESS,
    GET_POLE_MEMBERS_ERROR,
    GET_POLE_MEMBERS_STARTING,
    GET_POLE_MEMBERS_SUCCESS,
    UPDATE_POLE_MEMBER_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

const sortByOrder = (newState) => {
}

export default function poleMembersReducer(
    state = initialState.poleMembers,
    action
) {
    switch (action.type) {
        case GET_POLE_MEMBERS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_POLE_MEMBERS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};
            const poleIds = [];
            const polesMap = {};
            const poleBasedMembers = {};

            action.data.poleMembers.forEach((poleMember) => {
                const {id: memberId, memberType, poleId} = poleMember;

                if (!polesMap[poleId]) {
                    poleIds.push(poleId);
                    polesMap[poleId] = poleMember.pole;
                }

                if (!poleBasedMembers[poleId]) {
                    poleBasedMembers[poleId] = {
                        Pole: [],
                        Strut: [],
                        Horizontal: [],
                    };
                }

                poleBasedMembers[poleId][memberType].push(memberId);

                ids.push(memberId);
                map[memberId] = poleMember;
            });

            newState.ids = ids;
            newState.map = map;
            newState.poleIds = poleIds;
            newState.polesMap = polesMap;
            newState.poleBasedMembers = poleBasedMembers;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_POLE_MEMBERS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err
                ? action.err
                : "Failed to  retrieve pole members.";
            return newState;
        }

        case ADD_POLE_MEMBER_SUCCESS: {
            const newState = cloneDeep(state);
            const {poleMember} = action.data;
            const memberId = poleMember.id;

            newState.map[memberId] = poleMember;
            newState.ids.push(memberId);

            newState.ids.sort((pol1Id, pol2Id) => {
                const pol1 = newState.map[pol1Id];
                const pol2 = newState.map[pol2Id];
                return pol1.id - pol2.id;
            });

            return newState;
        }

        case UPDATE_POLE_MEMBER_SUCCESS: {
            const newState = cloneDeep(state);
            const {poleMember} = action.data;
            const memberId = poleMember.id;
            newState.map[memberId] = poleMember;
            return newState;
        }

        case DEACTIVATE_POLE_MEMBER_SUCCESS: {
            const {memberId} = action;
            const newState = cloneDeep(state);
            newState.inactiveIds.push(memberId);

            newState.ids = newState.ids.filter((mbid) => {
                return memberId !== mbid;
            });

            if (newState.map[memberId]) {
                newState.map[memberId].isActive = 0;
            }
            sortByOrder(newState)
            return newState;
        }

        default: {
            return state;
        }
    }
}
