import { cloneDeep } from 'lodash';
import {
    GET_FLANGE_JOINT_CONNECTIONS_STARTING,
    GET_FLANGE_JOINT_CONNECTIONS_SUCCESS,
    GET_FLANGE_JOINT_CONNECTIONS_ERROR,
    ADD_FLANGE_JOINT_CONNECTION_SUCCESS,
    UPDATE_FLANGE_JOINT_CONNECTION_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';

export default function flangeJointsReducer(state = initialState.flangeJoints, action) {
    switch (action.type) {
        case GET_FLANGE_JOINT_CONNECTIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_FLANGE_JOINT_CONNECTIONS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];
            const map = {};

            action.data.flangeJoints.forEach((flangeJoint) => {
                const flangeJointId = flangeJoint.id;
                ids.push(flangeJointId);
                map[flangeJointId] = flangeJoint;
            });

            newState.ids = ids;
            newState.map = map;


            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_FLANGE_JOINT_CONNECTIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve flange joint connection plate & bolt.';
            return newState;
        }

        case ADD_FLANGE_JOINT_CONNECTION_SUCCESS: {
            const newState = cloneDeep(state);
            const { flangeJoint } = action.data;
            const flangeJointId = flangeJoint.id;


            newState.map[flangeJointId] = flangeJoint;
            newState.ids.push(flangeJointId);

            newState.ids.sort((flange1Id, flange2Id) => {
                const flange1 = newState.map[flange1Id];
                const flange2 = newState.map[flange2Id];
                return flange1.id - flange2.id;
            });

            return newState;
        }

        case UPDATE_FLANGE_JOINT_CONNECTION_SUCCESS: {
            const newState = cloneDeep(state);
            const { flangeJoint } = action.data;
            const flangeJointId = flangeJoint.id;
            newState.map[flangeJointId] = flangeJoint;
            return newState;
        }

        default: {
            return state;
        }
    }
}