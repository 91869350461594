import {
    GET_CLIENT_INFO_STARTING,
    GET_CLIENT_INFO_SUCCESS,
    GET_CLIENT_INFO_ERROR,

    UPDATE_CLIENT_INFO_STARTING,
    UPDATE_CLIENT_INFO_SUCCESS,
    UPDATE_CLIENT_INFO_ERROR,

} from '../constants/actionTypes';
import axios from '../axios';
import {
    GET_CLIENT_INFO,
    UPDATE_CLIENT_INFO,
} from '../constants/apiUrls';

export function getClientInfoStarting() {
    return {type: GET_CLIENT_INFO_STARTING};
}

export function getClientInfoSuccess(data) {
    // console.log('Got data', data);
    return {type: GET_CLIENT_INFO_SUCCESS, data};
}

export function getClientInfoError(err) {
    return {type: GET_CLIENT_INFO_ERROR, err};
}

export function getClientInfo() {
    // console.log('Starting');
    return (dispatch) => {
        dispatch(getClientInfoStarting());

        axios.get(GET_CLIENT_INFO).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getClientInfoSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get client info';
            dispatch(getClientInfoError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getClientInfoError(error));
        });
    };
}

export function updateClientInfoStarting() {
    return {type: UPDATE_CLIENT_INFO_STARTING};
}

export function updateClientInfoSuccess(data) {
    // console.log('Got data', data);
    return {type: UPDATE_CLIENT_INFO_SUCCESS, data};
}

export function updateClientInfoError(err) {
    return {type: UPDATE_CLIENT_INFO_ERROR, err};
}

export function updateClientInfo(postData) {
    // console.log('Starting');
    return (dispatch) => {
        dispatch(updateClientInfoStarting());

        axios.post(UPDATE_CLIENT_INFO, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(updateClientInfoSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to update client info';
            dispatch(updateClientInfoError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(updateClientInfoError(error));
        });
    };
}
