import clone from 'lodash/cloneDeep';
import initialState from '../../../store/initialState';
import {
  UPDATE_PANEL_DETAILS_STARTING,
  UPDATE_PANEL_DETAILS_SUCCESS,
  UPDATE_PANEL_DETAILS_ERROR,
} from '../../../constants/actionTypes';

export default function updatePanelDetailReducer(state = initialState.updatePanelDetail, action) {
  switch (action.type) {
    case UPDATE_PANEL_DETAILS_STARTING: {
      const {panelId} = action;
      const newState = clone(state);

      if (!newState[panelId]) {
        newState[panelId] = clone(initialState.updatePanelDetail.panelId);
      }

      const updateData = newState[panelId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_PANEL_DETAILS_SUCCESS: {
      const {panelId} = action;
      const newState = clone(state);

      if (!newState[panelId]) {
        newState[panelId] = clone(initialState.updatePanelDetail.panelId);
      }

      const updateData = newState[panelId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;
      return newState;
    }

    case UPDATE_PANEL_DETAILS_ERROR: {
      const {panelId} = action;
      const newState = clone(state);

      if (!newState[panelId]) {
        newState[panelId] = clone(initialState.updatePanelDetail.panelId);
      }

      const updateData = newState[panelId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }

    default: {
      return state;
    }
  }
}
