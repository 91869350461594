import React from "react";
import {
    GET_CONNECTION_BOLTS_STARTING,
    GET_CONNECTION_BOLTS_SUCCESS,
    GET_CONNECTION_BOLTS_ERROR,
    ADD_CONNECTION_BOLTS_SUCCESS,
    UPDATE_CONNECTION_BOLTS_SUCCESS,
    DEACTIVATE_CONNECTION_BOLTS_SUCCESS
} from '../../../constants/actionTypes'
import { cloneDeep } from "lodash";
import initialState from "../../../store/initialState";
import { DEACTIVATE_AUDIT_DATA } from "../../../constants/apiUrls";

export default function connectionReducer (state = initialState.connectionBolts, action){
    const sortByOrder = (newState) => { }  
    switch(action.type){
        case GET_CONNECTION_BOLTS_STARTING : {
        const newState = cloneDeep(state)
        newState.loading = true;
        newState.loaded = false;
        return newState;

        }
    case GET_CONNECTION_BOLTS_SUCCESS : {
        const newState = cloneDeep(state)
        const ids = []
        action.data.connectionBoltData.forEach((connectionBolt)=>{
            const ConnectionId = connectionBolt.id;
            ids.push(ConnectionId)
            newState.map[ConnectionId] = connectionBolt
        })
        newState.ids = ids;
        newState.loading = false;
        newState.loaded = true;
        return newState;

    }

    case GET_CONNECTION_BOLTS_ERROR : {
        const newState = cloneDeep(state)
        newState.loading = false;
        newState.loaded = true;
        return newState;
    }

    case ADD_CONNECTION_BOLTS_SUCCESS: {
        const { connectionBolt: connection } = action.data;
        const newState = cloneDeep(state);
        const connectionId = connection.id;

        newState.map[connectionId] = connection;
        newState.ids.push(connectionId);

        sortByOrder(newState);

        return newState;
      }

      case UPDATE_CONNECTION_BOLTS_SUCCESS: {
        const { connectionBolt: connection } = action.data;
        const newState = cloneDeep(state);
        const connectionId = connection.id;
        newState.map[connectionId] = connection;

        sortByOrder(newState);
        return newState;
      }

      case DEACTIVATE_CONNECTION_BOLTS_SUCCESS: {
        const newState = cloneDeep(state);
        const { connectionId } = action;
        newState.inactiveIds.push(connectionId);

        newState.ids = newState.ids.filter((dgId) => {
            return connectionId !== dgId;
        });

        if (newState.map[connectionId]) {
            newState.map[connectionId].isActive = 0;
        }

        sortByOrder(newState);
        return newState;
    }
  

    default : {
        return state;
    }
}
}
