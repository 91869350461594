import {
  UPDATE_SERVICE_REQUEST_ERROR,
  UPDATE_SERVICE_REQUEST_STARTING,
  UPDATE_SERVICE_REQUEST_SUCCESS,
} from "../../../constants/actionTypes";
import { cloneDeep } from "lodash";
import initialState from "../../../store/initialState";

export default function updateServiceRequestReducer(state = initialState.updateServiceRequest,action) {
  switch (action.type) {
    case UPDATE_SERVICE_REQUEST_STARTING: {
      const { serviceId } = action;
      const newState = cloneDeep(state);

      if (!newState[serviceId]) {
        newState[serviceId] = cloneDeep(initialState.updateServiceRequest.serviceRequestId);
      }

      const updateData = newState[serviceId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;

      return newState;
    }

    case UPDATE_SERVICE_REQUEST_SUCCESS: {
      const { serviceId } = action;
      const newState = cloneDeep(state);

      if (!newState[serviceId]) {
        newState[serviceId] = cloneDeep(initialState.updateServiceRequest.serviceRequestId);
      }

      const updateData = newState[serviceId];
      updateData.saving = false;
      updateData.saved = true;
      updateData.error = null;

      return newState;
    }

    case UPDATE_SERVICE_REQUEST_ERROR: {
      const { serviceId } = action;
      const newState = cloneDeep(state);

      if (!newState[serviceId]) {
        newState[serviceId] = cloneDeep(initialState.updateServiceRequest.serviceRequestId);
      }

      const updateData = newState[serviceId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err;
      return newState;
    }
    default: {
      return state;
    }
  }
}
