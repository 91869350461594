import initialState from "../../../store/initialState";
import {
    DEACTIVATE_POLE_CABLE_LADDER_ERROR,
    DEACTIVATE_POLE_CABLE_LADDER_STARTING,
    DEACTIVATE_POLE_CABLE_LADDER_SUCCESS
} from "../../../constants/actionTypes";
import cloneDeep from "lodash/cloneDeep";

export default function deactivatePoleCableLadderReducer(state = initialState.deactivatePoleCableLadder, action) {
    switch (action.type) {
        case DEACTIVATE_POLE_CABLE_LADDER_STARTING:{
            const {poleCableId} = action;
            const newState = cloneDeep(state)

            if(!newState[poleCableId]){
                newState[poleCableId] = cloneDeep(initialState.deactivatePoleCableLadder.poleCableId);
            }

            const poleCableData = newState[poleCableId];
            poleCableData.saving = true;
            poleCableData.saved = false;
            poleCableData.error = null;
            return newState;
        }

        case DEACTIVATE_POLE_CABLE_LADDER_SUCCESS:{
            const {poleCableId} = action;
            const newState = cloneDeep(state)

            if(!newState[poleCableId]){
                newState[poleCableId] = cloneDeep(initialState.deactivatePoleCableLadder.poleCableId);
            }

            const poleCableData = newState[poleCableId];
            poleCableData.saving = false;
            poleCableData.saved = true;
            poleCableData.error = null;

            return newState;
        }

        case DEACTIVATE_POLE_CABLE_LADDER_ERROR:{
            const {poleCableId} = action;
            const newState = cloneDeep(state)

            if(!newState[poleCableId]){
                newState[poleCableId] = cloneDeep(initialState.deactivatePoleCableLadder.poleCableId);
            }
            const poleCableData = newState[poleCableId];
            poleCableData.saving = false;
            poleCableData.saved = false;
            poleCableData.error = action.error;
            return newState;
        }
        default:{
            return state;
        }
    }
}