import axios from 'axios';

export const auth = {
  token: '',
};

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const insertIdToken = (config, tryCount = 1) => {
  return new Promise((resolve, reject) => {
    if (!config || tryCount > 50) {
      return reject(new Error('Invalid request config'));
    }

    // console.log(config.url);
    //If any URLs need to be allowed without Token auth
    if (
        config.url &&
        (
            config.url.indexOf('/login/auto') !== -1 ||
            config.url.indexOf('/login/check') !== -1 ||
            config.url.indexOf('/client_info') !== -1 ||
            config.url.indexOf('/login/signup') !== -1 ||
            config.url.indexOf('/login/forgot') !== -1 ||
            config.url.indexOf('/login/reset') !== -1
        )
    ) {
      return resolve(config);
    }

    if (!auth.token) {
      console.log(`throttling request for 200ms due to nonavailability of tokens, URL: ${config.url}`);
      setTimeout(() => {
        insertIdToken(config, tryCount + 1).then(resolve, reject);
      }, 200);

      return null;
    }

    config.headers['Authorization'] = `Bearer ${auth.token}`;
    return resolve(config);
  });
};

axios.interceptors.request.use(insertIdToken, err => Promise.reject(err));

axios.interceptors.response.use(response => response, (error) => {
  if (error && error.response && error.response.status) {
    if (error.response.data.unauthorized) {
      // const errMsg = 'You were logged out of App as' +
      //  ' your session has expired. You will need to login again.';

      // TODO Show toast
      // console.log(errMsg);
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});

export function updateAuthKeys() {
  const token = localStorage.getItem('token');
  let tokenJson = null;
  if (token) {
    try {
      tokenJson = JSON.parse(token);
    } catch (err) {
      console.error(err);
    }
  }

  if (tokenJson) {
    auth.token = tokenJson.token;
  }
}

export function clearAuthKeys() {
  auth.token = '';
}

updateAuthKeys();

export default axios;
