import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from "./Utilities/Modal";
import TextInput from "./Utilities/Forms/TextInput";
import EmptyLoader from "./EmptyLoader";
import {appFeatures} from "../../constants/appConfig";

function AuthPasswordModal({buttonLabel, onSubmit, saving, title, toggleModal}) {
    const [authPassword, setAuthPassword] = useState('');
    const [authComments, setAuthComments] = useState('');

    useEffect(() => {
        if (!appFeatures.requireAuthorization) {
            onSubmit(authPassword, authComments);
        }
    }, []);

    if (!appFeatures.requireAuthorization) {
        return (
            <div></div>
        );
    }

    const buttonsConfig = [];
    let content;

    if (appFeatures.requireAuthorization) {
        if (saving) {
            buttonsConfig.push({
                label: 'Please wait',
                className: 'btn-primary',
                disabled: true,
                loading: true,
                onClick: () => {
                },
            });
        } else {
            buttonsConfig.push({
                label: buttonLabel,
                className: 'btn-primary px-8',
                onClick: () => {
                    // Show auth password dialog
                    onSubmit(authPassword, authComments);
                },
            });
        }

        content = (
            <div>
                <div className="grid grid-cols-2 gap-4">
                    <TextInput
                        autoFocus={true}
                        type="password"
                        label="Authorization Password"
                        helperText=""
                        placeholder=""
                        value={authPassword}
                        onChange={(e) => setAuthPassword(e.target.value)}
                    />
                </div>

                <div className="mt-4">
                    <TextInput
                        inputClassName="h-24"
                        type="textarea"
                        label="Comments"
                        helperText=""
                        placeholder=""
                        value={authComments}
                        onChange={(e) => setAuthComments(e.target.value)}
                    />
                </div>
            </div>
        );
    } else {
        content = (
            <EmptyLoader />
        );
    }

    return (
        <Modal
            title={title}
            buttons={buttonsConfig}
            toggleModal={toggleModal}
            className={"modal-lg"}
            showCancel={!saving}
            showCloseInHeader={!saving}
            closeOnEscapeKey={false}
        >
            {content}
        </Modal>
    )
}

AuthPasswordModal.defaultProps = {
    buttonLabel: 'Submit',
    saving: false,
    title: 'Authorization Required',
};

AuthPasswordModal.propTypes = {
    buttonLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    title: PropTypes.string,
    toggleModal: PropTypes.func.isRequired,
};

export default AuthPasswordModal;
