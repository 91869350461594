import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TowersMenuCommon from "./TowersMenuCommon";
import RegionsTreeView from "../../MobileTowers/RegionsTreeView";
import AdminMenu from "./AdminMenu";
import UserDetails from "./UserDetails";
import { isAdminUser, isCircleLeadUser } from "../../../utils/roles";

function PACSNavigation({ currentUser, toggleCreateModal }) {
    if (!currentUser.loggedIn) {
        return null;
    }

    const isAdmin = isAdminUser(currentUser.userData.role);
    // const isCircleLead = isCircleLeadUser(currentUser.userData.role);
    // const isDesigner = (currentUser.userData.role && (currentUser.userData.role.shortCode === 'junior_designer' || currentUser.userData.role.shortCode === 'senior_designer' || currentUser.userData.role.shortCode === 'designer'))

    return (
        <div className="bg-white h-screen flex flex-col max-h-screen">
            <div className="p-4">
                <img
                    src="/img/newMobile-towers/TrekkScan-logo.svg"
                    alt={"Application Logo"}
                    style={{ maxWidth: '150px' }}
                />
            </div>

            <div className="m-0 divider divider-primary" />

            <div className="side-nav-contents">
                <UserDetails />

                <div className="m-0 divider divider-primary h-2"></div>

                <div>
                    <TowersMenuCommon
                        userRole={currentUser.userData.role}
                    />

                    <div className="m-0 divider divider-primary h-2"></div>
                </div>

                {(isAdmin) && (
                    <div>
                        <AdminMenu />
                        <div className="m-0 divider divider-primary"></div>
                    </div>
                )}

                <div className="">
                    <div className="p-2 ml-2">
                        <RegionsTreeView toggleCreateModal={toggleCreateModal} currentUser={currentUser} />
                    </div>

                    <div className="m-0 divider divider-primary"></div>
                </div>
            </div>
        </div>
    );
}

PACSNavigation.defaultProps = {
    actions: {},
    currentUser: {},
};

PACSNavigation.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PACSNavigation);

